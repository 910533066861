import ReactDOM from "react-dom/client";
import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import { isEmptyObj } from "./pages/main/helpers/utility";

import { initializeApp } from "firebase/app";
import { Diversity1Outlined } from "@mui/icons-material";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";
import { getAnalytics } from "firebase/analytics";

import { Paper, Container } from "@mui/material";
import LoadingLogo from "./pages/main/components/LoadingLogo";

import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Popover from "@mui/material/Popover";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { mainListItems, secondaryListItems } from "./pages/main/listItems";
import Avatar from "@mui/material/Avatar";
import SvgIcon from "@mui/material/SvgIcon";
import CopilotDrawer from "./pages/main/CopilotDrawer";
import "./App.css";
import FullLogo from "./pages/main/assets/fulllogo.png";
import LogoutButton from "./pages/Logout";
import Home from "./pages/main/Home";
import Copilot from "./pages/main/Copilot";
import Reach from "./pages/main/Reach";
import Roasts from "./pages/main/Roasts/Roasts";
import Admin from "./pages/main/Admin";
import Shop from "./pages/main/Shop/Shop";
import Feedback from "./pages/main/Feedback";
import UserSettingsDialog from "./pages/main/components/UserSettingsDialog";
import BackgroundLetterAvatars from "./pages/main/components/BackgroundLetterAvatars";
import TutorialButton from "./pages/main/components/TutorialButton";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  getDoc,
  updateDoc,
  onSnapshot,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import BackgroundSVG from "./pages/main/assets/bg_bird.svg";
import RoasteryInfoPage from "./pages/main/RoasteryInfoPage";

const drawerWidth = 150;

const GOOGLE_MAPS_API_KEY = "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc";
function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const analytics = getAnalytics(app);

const functions = getFunctions();
//connectFunctionsEmulator(functions, "localhost", 5001);

const GetUserPersonalization = httpsCallable(
  functions,
  "GetUserPersonalization"
);

const auth = getAuth();
var user = {};
var sub = "";

const redirect = window.location.origin + "/roastery";

function LogoIcon(props) {
  return (
    <SvgIcon viewBox="0 0 980 980" {...props}>
      {/*  <path  d="M172.98,0H40.7C18.22,0,0,18.22,0,40.7V172.98c0,22.48,18.22,40.7,40.7,40.7H172.98c22.48,0,40.7-18.22,40.7-40.7V40.7c0-22.48-18.22-40.7-40.7-40.7Zm9.72,182.97c-1.56,1.24-3.43,1.85-5.28,1.85-2.5,0-4.98-1.1-6.66-3.21L101.82,94.88l-.77-.79c-1.18-1.32-2.69-1.37-3.72-.98-1.9,.72-3.04,3.23-3.04,6.7v60.29c0,9.41-4.03,17.14-11.06,21.19-6.26,3.61-14.03,3.61-20.29,0-7.03-4.06-11.06-11.78-11.06-21.19V45c0-4.69,3.81-8.5,8.5-8.5s8.5,3.81,8.5,8.5v115.11c0,3.17,.93,5.53,2.56,6.47,1.02,.59,2.28,.59,3.29,0,1.63-.94,2.56-3.3,2.56-6.47v-60.29c0-10.65,5.36-19.3,13.99-22.59,7.86-2.99,16.58-.89,22.28,5.36l.97,.97,69.54,87.47c2.92,3.67,2.31,9.02-1.37,11.94Z"/> */}
      {/*   <path  d="M164.11,0H50.56C22.63,0,0,22.63,0,50.56v113.55c0,27.92,22.63,50.55,50.56,50.55h113.55c27.92,0,50.56-22.63,50.56-50.55V50.56c0-27.92-22.63-50.56-50.56-50.56Zm17.03,177.59c-6.41,4.32-15.12,2.63-19.45-3.79l-51.84-76.94-1.04-.83c-1.65-1.12-4.37-1.26-6.29-.3-.73,.37-2.26,1.14-2.26,4.74v52.34h-.14c-1.54,17.38-15.22,30.1-33.02,30.1s-31.48-12.73-33.02-30.1h-.14V50.43c0-7.74,6.27-14.01,14.01-14.01s14.01,6.27,14.01,14.01v99.09c0,1.33,0,5.38,5.15,5.38s5.15-4.05,5.15-5.38v-49.04c0-13.06,6.61-24.19,17.67-29.77,11.5-5.79,25.51-4.65,35.7,2.9l.39,.3,4.67,3.72,54.25,80.51c4.32,6.42,2.63,15.12-3.79,19.45Z" /> */}
      <path d="M942.85,253.66c-10.73-135.31-109.73-233.31-244.68-244.68-136.94-12.8-286.65-11.1-444.63,0C116.11,19.35,24.11,115.35,8.85,253.66c-11.95,146.2-11.64,294.46,0,444.63,11.27,127.05,113.27,230.05,244.68,244.68,149.42,12.07,297.65,12.27,444.63,0,136.95-10.63,224.95-111.63,244.68-244.68,19.1-148.21,19.1-296.42,0-444.63Zm-176.44,491.72c-10.37,8.97-23.14,13.36-35.84,13.36-15.37,0-30.65-6.42-41.49-18.96l-243.6-281.59c-.55-.61-1.1-1.24-1.63-1.88l-.8-.93c-1.16-1.34-2.24-2.72-3.25-4.14-1.78-1.69-5.02-3.82-10.09-3.82-7.14,0-13.16,4.75-13.16,10.36,0,1.94-.1,3.86-.3,5.75,0,.4,.01,.81,.01,1.21v167.24c.27,2.21,.41,4.46,.41,6.75,0,66.18-55.09,120.01-122.81,120.01s-122.81-53.84-122.81-120.01c0-1.34,.05-2.68,.14-4-.09-1.32-.14-2.65-.14-4V247.5c0-30.28,24.55-54.83,54.83-54.83s54.83,24.55,54.83,54.83v383.24c0,1.34-.05,2.68-.14,4,.09,1.32,.14,2.65,.14,4,0,5.62,6.03,10.36,13.16,10.36,6.1,0,11.39-3.47,12.8-7.99-.03-.76-.05-1.52-.05-2.29v-174.07c0-1.94,.1-3.86,.3-5.75,0-.4-.01-.81-.01-1.21,0-66.18,55.09-120.01,122.81-120.01,39.32,0,76.18,18.38,99.27,49.34l243.03,280.94c19.81,22.9,17.3,57.52-5.59,77.33Z" />
    </SvgIcon>
  );
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: "theme.transitions.duration.leavingScreen",
      }),
      width: theme.spacing(6),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(8),
      },
    }),
  },
}));

const LogoutAccountButton = styled(Button)(({ theme }) => ({
  color: "rgba(104,10,239,0.8)",
  border: "1px solid rgba(104,10,239,0.4)",
  fontWeight: 800,
  borderRadius: "3px",
  fontSize: "11px",
  backgroundColor: "rgba(104,10,239,0.1)",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,1)",
    border: "1px solid rgba(104,10,239,0.8)",
    color: "white",
  },
}));

const ManageAccountButton = styled(Button)(({ theme }) => ({
  color: "rgba(104,10,239,0.8)",
  border: "1px solid rgba(104,10,239,0.4)",
  fontWeight: 800,
  borderRadius: "30px",
  fontSize: "11px",
  width: "100%",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,1)",
    border: "1px solid rgba(104,10,239,0.8)",
    color: "white",
  },
}));

const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#2c3e50",
    },
  },

  typography: {
    allVariants: {
      fontFamily: "'Montserrat','Roboto','Helvetica Neue','Arial','sans-serif'",
      fontSpacing: "0",
    },
  },
});

export default function App() {
  const [loadingUserAuth, setLoadingUserAuth] = useState(true);

  onAuthStateChanged(auth, (userObj) => {
    if (userObj) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      //const uid = user.uid;
      //  sub = "auth0|6327373e77cc78cacd7f5616";
      // //console.log("userobj", userObj);
      sub = userObj.uid;

      user.nickname = userObj.displayName;
      user.email = userObj.email;
      //user.sub = userObj.uid;
      user.sub = sub;
      user.picture =
        "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/Eo_circle_green_blank.svg/2048px-Eo_circle_green_blank.svg.png";

      //    getRoastery(user);
      setLoadingUserAuth(false);
      ////console.log("auth state", user, userObj);
      // ...
    } else {
      // User is signed out
      // ...
      //console.log("auth state signed out");
      window.location.href = window.location.origin;
    }
  });

  const UserControl = React.memo((props) => {
    const [anchorElAccount, setAnchorElAccount] = React.useState(null);
    const accountopen = Boolean(anchorElAccount);

    const [userDialogOpen, setUserDialogOpen] = React.useState(false);

    const handleAccountClick = (event) => {
      setAnchorElAccount(event.currentTarget);
    };
    const handleAccountClose = () => {
      setAnchorElAccount(null);
    };

    const handleManageClick = () => {
      setUserDialogOpen(true);
      handleAccountClose();
    };

    const handleUserDialogClose = (val) => {
      props.roastery.name = val.roastery_name;
      user = { ...val.user };
      //console.log(user);
      //  user.default_temp = val.user.default_temp;
      //  user.default_smooth = val.user.default_smooth;
      //  user.default_deltaBT_offset = val.user.default_deltaBT_offset;
      setUserDialogOpen(false);
    };

    return (
      <Stack direction="row" sx={{ order: 1 }}>
        <IconButton
          id="stack-account-menu"
          sx={{ padding: 0 }}
          onClick={handleAccountClick}
        >
          <BackgroundLetterAvatars
            name={props.user.nickname}
            sx={{
              fontSize: 14,
              width: 32,
              height: 32,
              border: "1.5px white solid",
            }}
          />
        </IconButton>

        <Box sx={{ pl: 1.5 }}>
          <Stack>
            <Typography fontSize="13px">{props.user.nickname}</Typography>
            <Typography variant="body2" fontSize="13px" fontWeight="500">
              {props.roastery.name}
            </Typography>
          </Stack>
        </Box>
        <Popover
          id="basic-menu2"
          anchorEl={anchorElAccount}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          open={Boolean(anchorElAccount)}
          onClose={handleAccountClose}
        >
          <Box sx={{ width: "300px" }}>
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "center",
                p: 2,
              }}
            >
              <BackgroundLetterAvatars
                name={props.user.nickname}
                sx={{
                  fontSize: 30,
                  width: 80,
                  height: 80,
                  border: "1.5px white solid",
                  mt: 2,
                }}
              />
              <Typography fontSize="13px" sx={{ mt: 3 }}>
                {props.user.nickname}
              </Typography>
              <Typography
                variant="body2"
                fontSize="13px"
                fontWeight="500"
                color="text.secondary"
              >
                {props.user.email}
              </Typography>
              <Typography
                variant="body2"
                fontSize="13px"
                fontWeight="500"
                color="text.secondary"
              >
                {props.roastery.name}
              </Typography>
              <ManageAccountButton
                variant="outlined"
                sx={{ mt: 3 }}
                onClick={handleManageClick}
              >
                Manage your haste Account
              </ManageAccountButton>
              <Divider variant="middle" sx={{ mt: 3, mb: 3, width: "80%" }} />
              <LogoutAccountButton
                onClick={props.handleLogout}
                size="small"
                fullWidth
              >
                Sign out of account
              </LogoutAccountButton>
            </Stack>
          </Box>
        </Popover>
        <UserSettingsDialog
          user={props.user}
          roastery={props.roastery}
          onClose={handleUserDialogClose}
          open={userDialogOpen}
        />
      </Stack>
    );
  });

  const root = ReactDOM.createRoot(document.getElementById("root"));

  let navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [roastery, setRoastery] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
    //console.log("script loaded?", loaded.current);
  }

  const toggleDrawer = () => {
    setOpen(!open);
  };

  async function getRoastery(userInfo) {
    var data = {};
    //console.log("get roastery", userInfo);
    const querySnapshot = await getDocs(
      query(
        collection(db, "Roasters"),
        where("users", "array-contains", userInfo.sub)
      )
    );
    //console.log(querySnapshot);
    querySnapshot.forEach((doc) => {
      data = doc.data();
      data.id = doc.id;
      setRoastery(data);
      //console.log(data);
    });
  }

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);
        //const data = await fetchData();
        //console.log("getting roastery in use effect");
        await getRoastery(user);
        await GetUserPersonalization({ user_id: user.sub }).then((response) => {
          //console.log(response);
          if (response && response.data) {
            var pers = response.data;
            user.default_temp = pers.default_temp ? pers.default_temp : "F";
            user.default_smooth = pers.default_smooth
              ? parseInt(pers.default_smooth)
              : 0;
            user.default_deltaBT_offset = pers.default_deltaBT_offset
              ? parseInt(pers.default_deltaBT_offset)
              : 5;
          }

          //console.log(user);
          setLoading(false);
        });
        //  //console.log(user);

        //   //console.log("roastery", roastery);
      } catch (error) {
        // add error handling here
        setLoading(false);
        //console.log(error);
      }
    };
    if (loadingUserAuth) return;
    queryAsync();
  }, [loadingUserAuth]);

  if (loadingUserAuth || loading) {
    return (
      <React.Fragment>
        {" "}
        <Box
          sx={{
            weight: "100%",
            height: "100vh",
            p: 0,
            backgroundColor: "rgba(52,31,151,1)",
          }}
        >
          <LoadingLogo message="Brewing..." color="#ecf0f1" />
        </Box>
      </React.Fragment>
    );
  }

  if (loading) {
    return (
      <React.Fragment>
        <Typography>Loading roaster info</Typography>
      </React.Fragment>
    );
  }

  if (isEmptyObj(roastery)) {
    return root.render(
      <Router>
        <ThemeProvider theme={mdTheme}>
          <Box component="main">
            <RoasteryInfoPage userInfo={user} />
          </Box>
        </ThemeProvider>
      </Router>
    );
  }

  return root.render(
    <Router>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ backgroundColor: "rgba(52,31,151,1)" }}>
          <Fade in={true} timeout={{ enter: 1000 }}>
            <Box sx={{ backgroundColor: "white" }}>
              <AppBar
                position="static"
                style={{
                  backgroundImage:
                    "linear-gradient(to right, rgba(95, 39, 205,1.0), rgba(95, 39, 205,1.0), rgba(95, 39, 205,1.0) , #0984e3)",

                  backgroundSize: "100%",
                }}
              >
                <Toolbar
                  style={{
                    minHeight: "45px",
                    paddingLeft: "15px",
                  }}
                  sx={{
                    pr: "0px", // keep right padding when drawer closed
                    justifyContent: "space-between",
                    boxShadow: 2,
                  }}
                >
                  <img src={FullLogo} style={{ width: "95px", order: -1 }} />
                  <UserControl
                    user={user}
                    roastery={roastery}
                    handleLogout={() => {
                      auth
                        .signOut()
                        .then(function () {
                          // Sign-out successful.
                          window.location.href = window.location.origin;
                        })
                        .catch(function (error) {
                          // An error happened
                        });
                    }}
                  />
                </Toolbar>
              </AppBar>
              <Box
                sx={{
                  display: "flex",
                  backgroundColor: "rgba(250, 248, 254,1)",
                  height: "94vh",
                  background: `linear-gradient(rgba(250,248,254,0.9), rgba(250,248,254,0.9)), url(${BackgroundSVG}) no-repeat`,
                  backgroundAttachment: "fixed",
                  backgroundPosition: "-50% 125%",
                }}
              >
                <CssBaseline />

                <Drawer
                  variant="permanent"
                  open={open}
                  PaperProps={{
                    elevation: 1,
                    sx: {
                      width: "60px !important",
                    },
                  }}
                >
                  <List
                    component="nav"
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      pt: 2,
                    }}
                  >
                    {mainListItems}
                    <Divider sx={{ my: 1 }} />
                    {secondaryListItems(sub)}
                    <TutorialButton />
                  </List>
                </Drawer>

                <Box
                  component="main"
                  sx={{
                    flexGrow: 1,
                    maxHeight: "94vh",
                    overflow: "hidden",
                    borderRadius: "10px",
                    ml: -4,
                    pl: 5,
                    mr: 1,
                    boxShadow: 0,
                  }}
                >
                  <Routes>
                    <Route
                      path="/roastery"
                      element={<Home userData={user} roaster={roastery} />}
                    />
                    <Route
                      path="/roastery/reach"
                      element={<Reach userData={user} roaster={roastery} />}
                    />
                    <Route path="/roastery/copilot/">
                      <Route
                        path=":batchID"
                        element={<Copilot userData={user} roaster={roastery} />}
                      />
                      <Route
                        path=""
                        element={<Copilot userData={user} roaster={roastery} />}
                      />
                    </Route>

                    <Route
                      path="/roastery/finder"
                      element={<Shop userData={user} roaster={roastery} />}
                    />
                    <Route
                      path="/roastery/feedback"
                      element={<Feedback userData={user} roaster={roastery} />}
                    />
                    <Route path="/roastery/roasts/">
                      <Route
                        path=":roastID"
                        element={<Roasts userData={user} roaster={roastery} />}
                      />
                      <Route
                        path=""
                        element={<Roasts userData={user} roaster={roastery} />}
                      />
                    </Route>
                    <Route
                      path="/roastery/admin"
                      element={<Admin userData={user} roaster={roastery} />}
                    />
                  </Routes>
                </Box>

                <CopilotDrawer userData={user} roaster={roastery} />
              </Box>
            </Box>
          </Fade>
        </Box>
      </ThemeProvider>
    </Router>
  );
}
