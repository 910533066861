import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import {
  Box,
  Typography,
  Grid,
  IconButton,
  SvgIcon,
  Snackbar,
  Link,
  Stack,
  Button,
  Dialog,
  DialogTitle,
  TextField,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

import StackedBadge from "./components/StackedBadge";
import NotesChip from "./components/NotesChip";
import History from "./components/History";
import ProfileLineGraph from "../main/Roasting/ProfileLineGraph";

import LoadingLogo from "../main/components/LoadingLogo";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import VerifiedIcon from "@mui/icons-material/Verified";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SendIcon from "@mui/icons-material/Send";
import Grow from "@mui/material/Grow";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import {
  styled,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material/styles";
import TerrainIcon from "@mui/icons-material/Terrain";
import WbSunnyIcon from "@mui/icons-material/WbSunny";

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

import "./Consumer.css";

const functions = getFunctions();
const saveRoasterFeedback = httpsCallable(functions, "SaveRoastersFeedback");
//connectFunctionsEmulator(functions, "localhost", 5001);

const heroBg = {
  backgroundImage: `url(
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%23ffffff' fill-opacity='0.07' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E"
  )`,
};

var styles = {
  BackGround: "#341f97",
  FGBackGround: "rgba(86, 64, 191, .4)",
  IconColor: "white",

  get StackedBadgeColor() {
    return this.FGBackGround;
  },
  get Paper() {
    var paperStyle = {
      backgroundColor: this.FGBackGround,
      padding: 3,
      paddingLeft: 4,
      paddingBottom: 2,
      backgroundImage: `url(
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%23ffffff' fill-opacity='0.04' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E"
  )`,
    };
    return paperStyle;
  },
};

const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#7B1FA2",
      secondary: "#5640BF",
    },
  },

  typography: {
    allVariants: {
      fontFamily: "'Montserrat','Roboto','Helvetica Neue','Arial','sans-serif'",
      fontSpacing: "0",
      color: "white",
    },
    h5: {
      fontWeight: 600,
      marginBottom: 13,
      marginTop: 10,
    },
    h6: {
      fontWeight: 400,
    },
    body1: {
      fontSize: 18,
      opacity: 0.9,
    },
    body2: {
      marginTop: 13,
      fontWeight: 400,
      fontSize: 16,
      opacity: 0.9,
      lineHeight: 1.3,
    },
    subtitle1: {
      fontWeight: 300,
    },
  },
});

function ElevationScroll(props) {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 150,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    style: {
      transition: trigger ? "0.5s" : "0.3s",
      borderRadius: trigger ? "0px" : "20px",
      transform: trigger
        ? "translate3d(0px, 0%, 0px) scale3d(1, 1, 1)"
        : "translate3d(0px, 0%, 0px) scale3d(0.85, 0.85, 1)",
      //   marginLeft: trigger ? 0 : "4vw",
      //   marginRight: trigger ? 0 : "4vw",
    },
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

function FeedbackDialog(props) {
  const { onClose, open, roaster_id, batchId, roast_id, roast_name } = props;
  const [feedbackText, setFeedbackText] = useState("");
  var theme = useTheme();

  const handleClose = (feedbackObj) => {
    setFeedbackText("");
    onClose(feedbackObj);
  };

  const handleInputChange = (e) => {
    setFeedbackText(e.target.value);
  };

  const handleSubmit = () => {
    if (feedbackText != "") {
      //console.log(roaster_id, roast_id, batchId, feedbackText);
      saveRoasterFeedback({
        roaster_id,
        roast_id,
        batchId,
        feedbackText,
        roast_name,
      });
    }

    handleClose({ feedbackSubmitted: true });
  };

  return (
    <Dialog fullWidth onClose={handleClose} open={open} sx={{}}>
      <DialogTitle sx={{ color: "rgba(19, 15, 64,1.0)" }}>
        Give Feedback
        <Typography sx={{ fontSize: 12, color: "text.secondary" }}>
          How'd you like your coffee?
        </Typography>
      </DialogTitle>

      <Box sx={{ p: 2, pt: 0.5 }}>
        <Stack spacing={2}>
          <TextField
            id="feedback_details"
            label="Let us know!"
            multiline
            fullWidth
            rows={3}
            onChange={handleInputChange}
            value={feedbackText}
            inputProps={{ style: { fontSize: 16 } }}
            InputLabelProps={{
              style: {
                fontSize: 16,
                color: theme.palette.primary.main,
              },
              shrink: true,
            }}
          />
          <Button
            variant="contained"
            fullWidth
            sx={{ borderRadius: "30px" }}
            style={{}}
            onClick={() => handleSubmit()}
            endIcon={<SendIcon />}
          >
            Send
          </Button>
        </Stack>
      </Box>
    </Dialog>
  );
}

//localStorage.removeItem("HasteInfo");

var user_info_string = localStorage.getItem("HasteInfo");
var user_info = {};
//console.log(user_info_string);

if (true) {
  if (user_info_string) {
    user_info = JSON.parse(user_info_string);
    if (typeof user_info.history === "undefined") {
      user_info.history = [];
    }
  } else {
    var newUser = {
      liked_roasts: [],
      history: [],
    };
    localStorage.setItem("HasteInfo", JSON.stringify(newUser));
    user_info = newUser;
  }
  //console.log(user_info);
}

export default function ConsumerBatchSummary({}) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [liked, setLiked] = useState(false);
  const [totalLikes, setTotalLikes] = useState(0);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const zoom = 9;
  const [data, setData] = useState({});
  const [lineData, setLineData] = useState({});
  const [isManual, setIsManual] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [eventLines, setEventLines] = useState([{}]); //{time:1, label:"test", color:"rgba(95, 39, 205,1)"}
  const { batchID } = useParams();
  const [feedbackDialog, setFeedbackDialog] = useState(false);

  const matches = useMediaQuery("(min-width:900px)");

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 500,
  });

  const triggerProfile = useScrollTrigger({
    disableHysteresis: true,
    threshold: 175,
  });
  function likeHandler() {
    setLiked(!liked);
  }

  const handleToastClose = () => {
    setToastOpen(false);
  };

  const handleFeedbackClick = () => {
    setFeedbackDialog(true);
  };

  const handleFeedbackClose = (feedbackObj) => {
    // console.log(feedbackObj);
    if (feedbackObj && feedbackObj.feedbackSubmitted) setToastOpen(true);
    setFeedbackDialog(false);
  };

  const updateUserHistory = (roast_info) => {
    var { roast_name, roaster, date_roasted, roast_id } = roast_info;
    var array = user_info.history;
    //let obj = user_info.history.find((o) => o.roast_id === roast_id);
    let obj = array.find((x) => x.batchID === batchID);
    let index = array.indexOf(obj);
    if (index > -1) {
      // update
      array.fill((obj = roast_info), index, index++);
    } else {
      // add
      array.push({ roast_name, roaster, date_roasted, roast_id, batchID });
    }
    user_info.history = array;
    saveLocalStorage();
  };

  const saveLocalStorage = () => {
    localStorage.setItem("HasteInfo", JSON.stringify(user_info));
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);

        const getData = httpsCallable(functions, "GetConsumerDataWithBatchId");
        getData(batchID).then((response) => {
          //     console.log(response);
          var dataObj = response.data;
          setData(dataObj);
          //   console.log(dataObj);
          setMapCenter({ lat: dataObj.origin_lat, lng: dataObj.origin_long });

          const incrementRoastView = httpsCallable(
            functions,
            "IncrementRoastView"
          );
          incrementRoastView({
            roasterId: dataObj.roaster_id,
            roastId: dataObj.roast_id,
          });
          setLiked(user_info.liked_roasts.indexOf(dataObj.roast_id) > -1);
          setTotalLikes(dataObj.total_roast_likes);
          updateUserHistory(dataObj);

          setLoading(false);
        });

        const getProfileData = httpsCallable(functions, "GetBatchProfileData");
        getProfileData({ batch_id: batchID, smooth: false }).then(
          (response) => {
            //   console.log(response);
            setIsManual(response.data.isManual);
            setLineData(response.data.profile_data);
            setEventLines(response.data.event_data);
          }
        );

        // switch loading to false after fetch is complete
      } catch (error) {
        // add error handling here
        setLoading(false);
        setError(true);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  useEffect(() => {
    const queryAsync = async () => {
      if (liked) {
        if (user_info.liked_roasts.indexOf(data.roast_id) == -1) {
          user_info.liked_roasts.push(data.roast_id);

          //setToastOpen(true);

          const incrementLikes = httpsCallable(functions, "IncrementLikes");
          incrementLikes({
            roasterId: data.roaster_id,
            batchId: batchID,
            roastId: data.roast_id,
          });
          setTotalLikes(totalLikes + 1);
        }
      } else {
        var idx = user_info.liked_roasts.indexOf(data.roast_id);
        if (idx > -1) {
          user_info.liked_roasts.splice(idx, 1);
          setTotalLikes(totalLikes - 1);
        }
      }
      saveLocalStorage();
    };

    queryAsync();
  }, [liked]);

  const maprender = (status) => {
    if (status === Status.FAILURE)
      return (
        <React.Fragment>
          <span>ERROR LOADING MAP</span>
        </React.Fragment>
      );
    return <LoadingLogo message="Loading map..." />;
  };

  // return a Spinner when loading is true
  if (loading)
    return (
      <React.Fragment>
        <Box
          sx={{
            weight: "100%",
            height: "100vh",
            p: 0,
            backgroundColor: "rgba(52,31,151,1)",
          }}
        >
          <LoadingLogo message="Brewing..." color="#ecf0f1" />
        </Box>
      </React.Fragment>
    );

  // data will be null when fetch call fails
  if (error)
    return (
      <React.Fragment>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={7}>
            <Typography sx={{ fontWeight: 600, color: "#5f27cd" }}>
              Whoops, looks like something went wrong. Sorry!
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ backgroundColor: styles.BackGround }}>
          <Box
            sx={matches ? { display: "flex", justifyContent: "center" } : {}}
          >
            <Box sx={matches ? { width: "30%" } : {}}>
              <Fade in={true} timeout={{ enter: 1000 }}>
                <Box>
                  <Box
                    sx={{
                      backgroundColor: styles.BackGround,
                      width: "100%",
                      height: "100vh",
                      position: "fixed",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      zIndex: -1,
                    }}
                  ></Box>
                  <Box sx={{}} style={{}}>
                    <Box sx={{ zIndex: 1 }}>
                      <Box sx={{ p: 3, pb: 1 }}>
                        <Box>
                          <Typography variant="h5" sx={{ mt: 4, mb: 0 }}>
                            {data.roast_name}
                            <Button
                              size="small"
                              sx={{
                                color: styles.IconColor,
                                transform: "scale(0.8)",
                                float: "right",
                                mb: 1,
                                borderRadius: 7,
                                borderWidth: 2,
                                p: 1.25,
                                pl: 1.75,
                                pr: 1.75,
                              }}
                              variant={liked ? "contained" : "outlined"}
                              onClick={() => likeHandler()}
                            >
                              {liked ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}
                              <Typography sx={{ ml: 1, fontSize: 15 }}>
                                {totalLikes}
                              </Typography>
                            </Button>
                          </Typography>
                          <Typography>
                            {data.roaster}{" "}
                            {data.roaster_website && (
                              <Link
                                onClick={() => {
                                  window.open(
                                    data.roaster_website,
                                    "_blank",
                                    "noopener,noreferrer"
                                  );
                                }}
                                sx={{
                                  color: "white",
                                  ml: 0.5,
                                  opacity: 0.8,
                                }}
                              >
                                <OpenInNewIcon
                                  sx={{
                                    verticalAlign: "middle",
                                    transform: "scale(0.9)",
                                    color: styles.IconColor,
                                  }}
                                />
                              </Link>
                            )}
                          </Typography>
                        </Box>

                        <Box sx={{ mt: 5.25, mb: 5 }}>
                          <Grid
                            container
                            spacing={2.5}
                            sx={{
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <StackedBadge
                                icon={<BeanIcon sx={{ color: "white" }} />}
                                text={data.roast_degree}
                                color={styles.StackedBadgeColor}
                                tooltip="Roast Degree"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <StackedBadge
                                icon={<WhatshotIcon sx={{ color: "white" }} />}
                                text={data.date_roasted}
                                color={styles.StackedBadgeColor}
                                tooltip="Roast Date"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <StackedBadge
                                icon={<VerifiedIcon sx={{ color: "white" }} />}
                                text={data.best_by_date}
                                color={styles.StackedBadgeColor}
                                tooltip="Best During"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <StackedBadge
                                icon={<TerrainIcon sx={{ color: "white" }} />}
                                text={data.origin_display}
                                color={styles.StackedBadgeColor}
                                tooltip="Origin"
                              />
                            </Grid>
                            <Grid
                              item
                              xs={4}
                              sx={{ display: "flex", justifyContent: "center" }}
                            >
                              <StackedBadge
                                icon={<WbSunnyIcon sx={{ color: "white" }} />}
                                text={data.processing}
                                color={styles.StackedBadgeColor}
                                tooltip="Processing"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box sx={{ mb: 1, mt: 1 }}>
                          <Typography sx={{ mb: 2 }}>Notes</Typography>
                          <Stack direction="row" spacing={1}>
                            {data.taste_notes.map((note, idx) => (
                              <NotesChip key={idx} label={note} />
                            ))}{" "}
                          </Stack>
                          <Box sx={{ mt: 2, mb: 4 }}>
                            <Typography variant="body2" sx={{}}>
                              {data.description}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                      <ElevationScroll>
                        <Box sx={styles.Paper}>
                          {data.brew_tips && (
                            <Grow in={true} timeout={{ enter: 700 }}>
                              <Box>
                                <Typography variant="h6">Brew Tips</Typography>
                                <Typography variant="body2">
                                  {data.brew_tips}
                                </Typography>
                              </Box>
                            </Grow>
                          )}
                          <Grow in={triggerProfile} timeout={{ enter: 1250 }}>
                            <Box sx={{ mt: data.brew_tips ? 4 : 0, mb: 1 }}>
                              <Typography variant="h6">Profile</Typography>
                              <Box
                                sx={{
                                  height: "250px",
                                  backgroundColor: "white",
                                  p: 2,
                                  pt: 3,
                                  borderRadius: "40px 0 40px 0",
                                  ml: -3,
                                  mr: -2,
                                  mt: 2,
                                }}
                                boxShadow={2}
                              >
                                {isManual && (
                                  <ProfileLineGraph data={lineData.bt_trend} />
                                )}
                                {!isManual && (
                                  <ProfileLineGraph
                                    smooth={true}
                                    data={lineData}
                                    consumerDisplay
                                  />
                                )}
                              </Box>
                            </Box>
                          </Grow>
                          {/*}
                      <Grow in={trigger} timeout={{ enter: 1250 }}>
                        <Box sx={{ mt: 6 }}>
                          <Typography variant="h5">
                            Origin
                          </Typography>
                          <Typography variant="body2" sx={{ mt: -1 }}>
                            {data.origin_display}
                          </Typography>
                          <Box height="400px" sx={{ ml: -4, mr: -3, mt: 3 }}>
                            <Wrapper
                              apiKey="AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc"
                              render={maprender}
                            >
                              <GreenMap center={mapCenter} zoom={zoom} />
                            </Wrapper>
                          </Box>
                        </Box>
                      </Grow>
                            {*/}
                        </Box>
                      </ElevationScroll>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          mt: 3,
                          opacity: "1",
                          color: "white",
                        }}
                      >
                        <Button
                          variant="contained"
                          fullWidth
                          sx={{
                            mb: 2,
                            textTransform: "initial",
                            borderRadius: "30px",
                          }}
                          onClick={handleFeedbackClick}
                        >
                          Give Feedback
                        </Button>
                        <LogoIcon sx={{ transform: "scale(1.3)", mt: 2 }} />
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: 600,
                            mt: 0.7,
                            color: "white",
                          }}
                        >
                          {" "}
                          haste{" "}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <FeedbackDialog
                    open={feedbackDialog}
                    onClose={handleFeedbackClose}
                    roaster_id={data.roaster_id}
                    batchId={batchID}
                    roast_id={data.roast_id}
                    roast_name={data.roast_name}
                  />
                </Box>
              </Fade>

              <History history_data={user_info.history} />
            </Box>
          </Box>
        </Box>
      </ThemeProvider>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toastOpen}
        onClose={handleToastClose}
        message="Thanks for the feedback!"
        key={"bottom" + "center"}
        autoHideDuration={2000}
        sx={{ width: "91%" }}
        ContentProps={{
          sx: {
            display: "block",
            textAlign: "center",
            backgroundColor: "rgba(83, 82, 237,1.0)",
            fontSize: "19px",
            fontWeight: 600,
            letterSpacing: 1,
          },
        }}
      />
    </React.Fragment>
  );
}
/*
<Link
                sx={{
                  fontSize: "0.8rem",
                  color: "white",
                  fontFamily:
                    "'Montserrat','Roboto','Helvetica Neue','Arial','sans-serif'",
                  fontWeight: 600,
                  mt: 2,
                  textTransform: "uppercase",
                  letterSpacing: "1.3px",
                }}
                href={`mailto:support@haste.coffee`}
              >
                {" "}
                Email Feedback{" "}
              </Link>
*/

function BeanIcon(props) {
  return (
    <SvgIcon viewBox="-50 -100 650 650" {...props}>
      <path d="M20.25,404.81c-.87,.05-1.73,.15-2.58,.28-48.99-101.79,6.54-246.18,133.99-335.48C277.85-18.82,430.75-22.54,510.39,55.86c-60.45,19.68-115.14,49.54-166.75,91-48.17,38.7-87.71,82.75-122.59,121.61-65.96,73.49-118.06,131.54-200.79,136.35ZM544.04,102.79c-1.1,.49-2.23,.91-3.41,1.26-59.31,17.44-112.48,45.46-162.55,85.68-44.68,35.89-80.99,76.35-116.1,115.47-61.37,68.38-119.7,133.36-206.97,150.53,80.56,74.89,230.85,70.01,355.21-17.14,127.58-89.4,183.09-233.99,133.82-335.81Z" />
    </SvgIcon>
  );
}

function GreenMap(props) {
  const ref = useRef();
  const center = props.center;
  const zoom = props.zoom;

  useEffect(() => {
    new window.google.maps.Map(ref.current, {
      center,
      zoom,
      disableDefaultUI: true,
      zoomControl: true,
    });
  });

  return (
    <div
      ref={ref}
      id="map"
      style={{
        width: "100%",
        height: "90%",
        borderRadius: "15px",
        marginTop: "10px",
      }}
    />
  );
}

function LogoIcon(props) {
  return (
    <SvgIcon viewBox="0 0 980 980" {...props}>
      {/*  <path  d="M172.98,0H40.7C18.22,0,0,18.22,0,40.7V172.98c0,22.48,18.22,40.7,40.7,40.7H172.98c22.48,0,40.7-18.22,40.7-40.7V40.7c0-22.48-18.22-40.7-40.7-40.7Zm9.72,182.97c-1.56,1.24-3.43,1.85-5.28,1.85-2.5,0-4.98-1.1-6.66-3.21L101.82,94.88l-.77-.79c-1.18-1.32-2.69-1.37-3.72-.98-1.9,.72-3.04,3.23-3.04,6.7v60.29c0,9.41-4.03,17.14-11.06,21.19-6.26,3.61-14.03,3.61-20.29,0-7.03-4.06-11.06-11.78-11.06-21.19V45c0-4.69,3.81-8.5,8.5-8.5s8.5,3.81,8.5,8.5v115.11c0,3.17,.93,5.53,2.56,6.47,1.02,.59,2.28,.59,3.29,0,1.63-.94,2.56-3.3,2.56-6.47v-60.29c0-10.65,5.36-19.3,13.99-22.59,7.86-2.99,16.58-.89,22.28,5.36l.97,.97,69.54,87.47c2.92,3.67,2.31,9.02-1.37,11.94Z"/> */}
      {/*   <path  d="M164.11,0H50.56C22.63,0,0,22.63,0,50.56v113.55c0,27.92,22.63,50.55,50.56,50.55h113.55c27.92,0,50.56-22.63,50.56-50.55V50.56c0-27.92-22.63-50.56-50.56-50.56Zm17.03,177.59c-6.41,4.32-15.12,2.63-19.45-3.79l-51.84-76.94-1.04-.83c-1.65-1.12-4.37-1.26-6.29-.3-.73,.37-2.26,1.14-2.26,4.74v52.34h-.14c-1.54,17.38-15.22,30.1-33.02,30.1s-31.48-12.73-33.02-30.1h-.14V50.43c0-7.74,6.27-14.01,14.01-14.01s14.01,6.27,14.01,14.01v99.09c0,1.33,0,5.38,5.15,5.38s5.15-4.05,5.15-5.38v-49.04c0-13.06,6.61-24.19,17.67-29.77,11.5-5.79,25.51-4.65,35.7,2.9l.39,.3,4.67,3.72,54.25,80.51c4.32,6.42,2.63,15.12-3.79,19.45Z" /> */}
      <path d="M942.85,253.66c-10.73-135.31-109.73-233.31-244.68-244.68-136.94-12.8-286.65-11.1-444.63,0C116.11,19.35,24.11,115.35,8.85,253.66c-11.95,146.2-11.64,294.46,0,444.63,11.27,127.05,113.27,230.05,244.68,244.68,149.42,12.07,297.65,12.27,444.63,0,136.95-10.63,224.95-111.63,244.68-244.68,19.1-148.21,19.1-296.42,0-444.63Zm-176.44,491.72c-10.37,8.97-23.14,13.36-35.84,13.36-15.37,0-30.65-6.42-41.49-18.96l-243.6-281.59c-.55-.61-1.1-1.24-1.63-1.88l-.8-.93c-1.16-1.34-2.24-2.72-3.25-4.14-1.78-1.69-5.02-3.82-10.09-3.82-7.14,0-13.16,4.75-13.16,10.36,0,1.94-.1,3.86-.3,5.75,0,.4,.01,.81,.01,1.21v167.24c.27,2.21,.41,4.46,.41,6.75,0,66.18-55.09,120.01-122.81,120.01s-122.81-53.84-122.81-120.01c0-1.34,.05-2.68,.14-4-.09-1.32-.14-2.65-.14-4V247.5c0-30.28,24.55-54.83,54.83-54.83s54.83,24.55,54.83,54.83v383.24c0,1.34-.05,2.68-.14,4,.09,1.32,.14,2.65,.14,4,0,5.62,6.03,10.36,13.16,10.36,6.1,0,11.39-3.47,12.8-7.99-.03-.76-.05-1.52-.05-2.29v-174.07c0-1.94,.1-3.86,.3-5.75,0-.4-.01-.81-.01-1.21,0-66.18,55.09-120.01,122.81-120.01,39.32,0,76.18,18.38,99.27,49.34l243.03,280.94c19.81,22.9,17.3,57.52-5.59,77.33Z" />
    </SvgIcon>
  );
}
