import SvgIcon from "@mui/material/SvgIcon";

export function PinIcon(props) {
  return (
    <SvgIcon viewBox="-100 -100 650 650" {...props}>
      <path
        class="cls-1"
        d="M119.3,389.86c-2.73,0-5.51-.58-8.15-1.8-9.78-4.51-14.05-16.09-9.54-25.87l38.88-84.32-50.96-22.83c-9.67-4.33-23.92-10.31-37.71-16.1-14.19-5.95-28.86-12.11-39.23-16.76-7.14-3.2-11.81-9.68-12.49-17.34-1.22-13.68,10.8-22.95,18.76-29.09,7.33-5.65,16.89-12.19,26.13-18.51,9.55-6.53,23.68-16.19,28.07-20.37L117.57,37.49c3.23-7.2,13.12-24.86,35.25-33.3,15.81-6.03,33.03-5.54,48.47,1.38l133.84,59.96c31.88,14.28,46.2,51.84,31.92,83.72l-44.52,99.37c-.24,7.29,3.03,29.32,5.02,42.78,1.53,10.33,2.98,20.09,3.8,27.84,1.1,10.28,2.46,23.07-7.78,30.84-5.85,4.44-13.68,5.32-20.43,2.29-4.29-1.92-9.39-4.51-15.86-7.79-13.83-7.01-34.72-17.6-63.34-29.32l-.58-.25-47.28-21.18-39.06,84.7c-3.29,7.14-10.35,11.34-17.72,11.34Zm119.72-110.58c20.78,8.52,37.57,16.42,50.69,22.88-.26-1.74-.51-3.44-.75-5.06-5.69-38.4-7.29-52.7-2.9-62.49l45.39-101.31c5.49-12.25-.01-26.69-12.27-32.18L185.34,41.16c-5.94-2.66-12.55-2.85-18.63-.53-8.97,3.42-12.86,11.26-13.55,12.8l-45.4,101.34c-4.02,8.98-13.82,16.23-40.77,34.67-3.54,2.42-7.9,5.4-12.23,8.43,4.01,1.69,8.1,3.41,12.15,5.11,14,5.88,28.48,11.95,38.57,16.47l133.55,59.83Z"
      />
    </SvgIcon>
  );
}

export function UnPinIcon(props) {
  return (
    <SvgIcon viewBox="-100 -100 650 650" {...props}>
      <g id="Layer_4" data-name="Layer 4">
        <path
          class="cls-2"
          d="M125.24,389.86c-2.73,0-5.51-.58-8.15-1.8-9.78-4.51-14.05-16.09-9.54-25.87l38.88-84.31-50.96-22.83c-9.66-4.33-23.91-10.31-37.69-16.09-14.2-5.96-28.88-12.12-39.25-16.76-7.14-3.2-11.81-9.68-12.49-17.34-1.22-13.68,10.8-22.95,18.75-29.09,7.33-5.65,16.89-12.19,26.13-18.51,9.55-6.53,23.68-16.19,28.07-20.37L123.51,37.49c3.23-7.2,13.12-24.86,35.25-33.3,15.81-6.03,33.03-5.54,48.47,1.38l133.84,59.96c31.88,14.28,46.2,51.84,31.92,83.72l-44.52,99.37c-.24,7.29,3.02,29.32,5.02,42.77,1.53,10.33,2.98,20.09,3.8,27.84,1.1,10.28,2.46,23.07-7.78,30.84-5.86,4.44-13.68,5.32-20.43,2.29-4.29-1.92-9.39-4.51-15.85-7.78-13.83-7.01-34.72-17.6-63.34-29.32l-.58-.25-47.28-21.18-39.06,84.7c-3.29,7.14-10.35,11.34-17.72,11.34Zm119.72-110.58c20.78,8.52,37.57,16.42,50.69,22.88-.26-1.74-.51-3.44-.75-5.06-5.69-38.39-7.29-52.7-2.9-62.48l45.39-101.31c5.49-12.25-.01-26.69-12.27-32.18L191.28,41.16c-5.93-2.66-12.55-2.85-18.63-.53-8.97,3.42-12.86,11.26-13.55,12.8l-45.4,101.34c-4.02,8.98-13.82,16.23-40.77,34.67-3.54,2.42-7.9,5.4-12.23,8.43,4.02,1.69,8.11,3.41,12.17,5.11,13.99,5.87,28.46,11.94,38.55,16.46l133.55,59.83Z"
        />
      </g>
      <g id="Layer_5" data-name="Layer 5">
        <g>
          <path
            class="cls-2"
            d="M358.99,381.19c-4.99,0-9.98-1.9-13.79-5.71L11.7,41.98c-7.62-7.62-7.62-19.96,0-27.58s19.96-7.62,27.58,0L372.78,347.9c7.62,7.62,7.62,19.96,0,27.58-3.81,3.81-8.8,5.71-13.79,5.71Z"
          />
          <path
            class="cls-1"
            d="M358.99,387.19c-6.81,0-13.21-2.65-18.03-7.47L7.46,46.22C-2.49,36.28-2.49,20.1,7.46,10.16S33.58,.22,43.52,10.16L377.02,343.66c4.82,4.82,7.47,11.22,7.47,18.03s-2.65,13.22-7.47,18.03c-4.82,4.82-11.22,7.47-18.03,7.47ZM25.49,14.7c-3.46,0-6.91,1.32-9.55,3.95-5.26,5.26-5.26,13.83,0,19.09L349.44,371.23c2.55,2.55,5.94,3.95,9.55,3.95s7-1.4,9.55-3.95,3.95-5.94,3.95-9.55-1.4-7-3.95-9.55L35.03,18.64c-2.63-2.63-6.09-3.95-9.55-3.95Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
}
