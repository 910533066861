import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  sortArrayBy,
  isLikelyForC,
  sortArrayByDate,
  convertSecondsToMinutesLabel,
} from "../helpers/utility";
import ProfileDialog from "../components/ProfileDialog";
import QRDialog from "../components/QRDialog";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import TimelineIcon from "@mui/icons-material/Timeline";
import DeleteIcon from "@mui/icons-material/Delete";
import QrCodeIcon from "@mui/icons-material/QrCode";
import StarIcon from "@mui/icons-material/Star";

import { getFunctions, httpsCallable } from "firebase/functions";
import { Typography } from "@mui/material";
import TempLabel from "./TempLabel";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 11,
    fontWeight: 600,
    color: "rgba(0,0,0,0.6)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    p: 0,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {},
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "20px",
  fontSize: "9px",
  backgroundColor: "rgba(104,10,239,0.8)",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,1)",
  },
}));

const DeleteButton = styled(ActionButton)(({ theme }) => ({
  backgroundColor: "rgba(238, 82, 83,0.8)",
  "&:hover": {
    backgroundColor: "rgba(238, 82, 83,1.0)",
  },
}));

const functions = getFunctions();
const deleteBatch = httpsCallable(functions, "DeleteBatchById");

export default function BatchesTable({
  batches,
  reference_batch_id,
  rows = 5,
  deleteBtn = true,
  user,
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(rows);
  const [batchID, setBatchID] = React.useState("");
  const [batchRefId, setBatchRefId] = React.useState(reference_batch_id);

  const [qrOpen, setQROpen] = React.useState(false);
  const [qrRoast, setQRRoast] = React.useState("");
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [profileForDialog, setProfile] = React.useState({});

  const convertToC = user.default_temp == "C";
  //console.log(convertToC);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - batches.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  async function handleProfileClick(id) {
    ////console.log(profileURL)
    setBatchID(id);
    setProfileOpen(true);
  }

  const handleCloseProfile = (refToggled, isRef) => {
    if (refToggled) {
      if (isRef) setBatchRefId(batchID);
      else setBatchRefId(null);
    }

    setProfileOpen(false);
  };

  async function handleQRClick(roast_id) {
    setQRRoast(roast_id);
    setQROpen(true);
  }

  const handleCloseQR = () => {
    setQROpen(false);
  };

  const handleDeleteBatch = (batch_id) => {
    deleteBatch(batch_id);
  };

  //{row.stats.charge_temp}&nbsp;°F

  return (
    <React.Fragment>
      <TableContainer
        component={Box}
        sx={{
          border: "1px solid rgba(87, 101, 116,0.3)",
          borderRadius: "20px",
          backgroundColor: "white",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Roast Date</StyledTableCell>
              <StyledTableCell align="right">Charge</StyledTableCell>
              <StyledTableCell align="right">Turning</StyledTableCell>
              <StyledTableCell align="right">First Crack</StyledTableCell>
              <StyledTableCell align="right">Second Crack</StyledTableCell>
              <StyledTableCell align="right">Drop</StyledTableCell>
              <StyledTableCell align="right">&nbsp;</StyledTableCell>
              {deleteBtn && (
                <StyledTableCell align="right">&nbsp;</StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {batches.length < 1 && (
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ position: "relative" }}
                >
                  No batches to show
                </StyledTableCell>
              </TableRow>
            )}
            {sortArrayByDate(batches, "date_roasted", false)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow
                  key={row.batch_num}
                  className="showHiddenHovers"
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ position: "relative" }}
                  >
                    {new Date(row.date_roasted).toLocaleDateString()}{" "}
                    {new Date(row.date_roasted).toLocaleTimeString()}
                    {row.id == batchRefId ? (
                      <Tooltip title="Reference Roast" placement="top">
                        <StarIcon
                          fontSize="small"
                          sx={{
                            color: "rgba(253, 203, 110,1.0)",
                            transform: "scale(0.7)",
                            verticalAlign: "middle",
                            bottom: "35%",
                            position: "absolute",
                          }}
                        />
                      </Tooltip>
                    ) : (
                      ""
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <TempLabel sx={{ fontSize: 11 }} convertToC={convertToC}>
                      {row.stats.charge_temp}
                    </TempLabel>
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <TempLabel sx={{ fontSize: 11 }} convertToC={convertToC}>
                      {row.stats.tp_temp.temp}
                    </TempLabel>
                    {convertSecondsToMinutesLabel(row.stats.tp_temp.time)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <TempLabel sx={{ fontSize: 11 }} convertToC={convertToC}>
                      {row.stats.fc_temp.temp}
                    </TempLabel>
                    {convertSecondsToMinutesLabel(row.stats.fc_temp.time)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <TempLabel sx={{ fontSize: 11 }} convertToC={convertToC}>
                      {row.stats.sc_temp.temp}
                    </TempLabel>
                    {convertSecondsToMinutesLabel(row.stats.sc_temp.time)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <TempLabel sx={{ fontSize: 11 }} convertToC={convertToC}>
                      {row.stats.drop_temp.temp}
                    </TempLabel>
                    {convertSecondsToMinutesLabel(row.stats.drop_temp.time)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <ActionButton
                      size="small"
                      variant="contained"
                      onClick={() => {
                        handleProfileClick(row.id);
                      }}
                    >
                      <TimelineIcon fontSize="16px" />
                      &nbsp;Profile
                    </ActionButton>
                    <ActionButton
                      size="small"
                      variant="contained"
                      sx={{ ml: 0.5 }}
                      onClick={() => {
                        handleQRClick(row.id);
                      }}
                    >
                      <QrCodeIcon fontSize="13px" />
                      &nbsp;QR
                    </ActionButton>
                  </StyledTableCell>
                  {deleteBtn && (
                    <StyledTableCell align="right">
                      <DeleteButton
                        className="hideUntilHover"
                        size="small"
                        variant="contained"
                        onClick={() => {
                          handleDeleteBatch(row.id);
                        }}
                      >
                        <DeleteIcon fontSize="13px" />
                        &nbsp;Delete
                      </DeleteButton>
                    </StyledTableCell>
                  )}
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 64 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={batches.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ProfileDialog
        open={profileOpen}
        onClose={handleCloseProfile}
        batch_id={batchID}
        user={user}
      />

      <QRDialog open={qrOpen} onClose={handleCloseQR} qrRoastID={qrRoast} />
    </React.Fragment>
  );
}
