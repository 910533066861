import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import Title from "../Title";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
} from "firebase/firestore";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { initializeApp } from "firebase/app";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

const functions = getFunctions();
const QueueNewBatch = httpsCallable(functions, "QueueNewBatch");

var currentUser = "";
var currentRoast = {};
var currentRoaster = "";

const AddRoastButton = styled(Button)(({ theme }) => ({
  color: "rgba(104,10,239,0.8)",
  border: "1px solid rgba(87, 101, 116,0.3)",
  fontWeight: 600,
  borderRadius: "3px",
  fontSize: "12px",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,0.1)",
    border: "1px solid rgba(104,10,239,0.8)",
  },
}));

const IconRoastButton = styled(IconButton)(({ theme }) => ({
  color: "rgba(104,10,239,0.8)",
  border: "1px solid rgba(87, 101, 116,0.2)",
  fontWeight: 600,
  fontSize: "12px",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,0.1)",
    border: "1px solid rgba(104,10,239,0.8)",
  },
}));

export default function QueueBatchButton({
  roast,
  userData,
  roaster,
  sx,
  iconOnly,
}) {
  //console.log(roast)
  const { sub } = userData;
  currentUser = sub;
  currentRoast = roast;
  currentRoaster = roaster.id;

  const handleQueueBatchClick = (roast) => {
    //console.log({roaster:currentRoaster, user:currentUser, roast:currentRoast})
    QueueNewBatch({
      roaster: currentRoaster,
      user: currentUser,
      roast: currentRoast,
    });
  };

  return (
    <React.Fragment>
      {!iconOnly && (
        <AddRoastButton
          variant="outlined"
          sx={sx}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleQueueBatchClick(roast);
          }}
        >
          <WhatshotIcon
            fontSize="small"
            sx={{ color: "#5f27cd", transform: "scale(0.8)", mr: 0.5 }}
          />
          Add to Queue
        </AddRoastButton>
      )}
      {iconOnly && (
        <IconRoastButton
          sx={sx}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            handleQueueBatchClick(roast);
          }}
        >
          <WhatshotIcon fontSize="small" />
        </IconRoastButton>
      )}
    </React.Fragment>
  );
}
