import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  updateDoc,
  orderBy,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import FavoriteRoast from "./components/FavoriteRoast";
import BatchesTable from "./components/BatchesTable";
import moment from "moment";
import "./styles/Reach.css";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { sortArrayBy } from "./helpers/utility";

import { getFunctions, httpsCallable } from "firebase/functions";
import LoadingLogo from "./components/LoadingLogo";
const functions = getFunctions();
const GetEngagementData = httpsCallable(functions, "GetEngagementData");
const GetAllBatchesForRoastery = httpsCallable(
  functions,
  "GetAllBatchesForRoastery"
);

function preventDefault(event) {
  event.preventDefault();
}

export default function Reach({ userData, roaster }) {
  const [loading, setLoading] = useState(true);
  const [roasts, setRoasts] = useState([]);
  const [top3, setTop3] = useState([]);
  const [batchesTrendData, setBatchesTrendData] = useState([]);
  const [total_views, setTotal_views] = useState(0);
  const [total_likes, setTotal_likes] = useState(0);
  const [roasterStats, setRoasterStats] = useState({
    likes_ot: [{ likes: 0, date: new Date() }],
    views_ot: [{ views: 0, date: new Date() }],
  });
  const [batches, setBatches] = useState([]);

  const [tabSel, setTabSel] = useState(0);
  const batchesTrendDayLookback = 90;

  async function getConsumerData() {
    GetEngagementData({ roastery_id: roaster.id })
      .then((resp) => {
        //  //console.log(resp.data);
        var { roasts, stats } = resp.data;
        //console.log(stats);

        stats.likes_ot.forEach((d) => {
          //     //console.log(d)
          d.date = moment(d.date).valueOf(); // date -> epoch
        });
        stats.views_ot.forEach((d) => {
          ////console.log(d);
          d.date = moment(d.date).valueOf(); // date -> epoch
        });
        stats.likes_ot = sortArrayBy(stats.likes_ot, "date", true);
        stats.views_ot = sortArrayBy(stats.views_ot, "date", true);

        setRoasts(roasts);
        setRoasterStats(stats);
      })
      .catch((error) => {
        //console.log(error);
      });
  }
  async function getBatchesData() {
    await GetAllBatchesForRoastery({ roastery_id: roaster.id }).then((resp) => {
      // //console.log(resp.data);
      setBatches(resp.data);
    });
  }

  function getTotals() {
    var views = 0;
    var likes = 0;
    roasts.forEach((r) => {
      views += r.total_consumer_views;
      likes += r.total_consumer_likes;
    });

    setTotal_likes(likes);
    setTotal_views(views);
  }

  function getBatchesTrend() {
    var recentBatchesTrend = [];
    var trendCounts = [];

    const NUM_OF_DAYS = batchesTrendDayLookback; // get last 7 dates.

    for (let i = 0; i < NUM_OF_DAYS; i++) {
      let d = new Date();

      d.setDate(d.getDate() - i);
      trendCounts.push({ date: d, count: 0 });
    }

    ////console.log(trendCounts);

    var today = new Date();
    var priorDate = new Date(
      new Date().setDate(today.getDate() - batchesTrendDayLookback)
    );

    recentBatchesTrend = batches.filter(
      (batch) => new Date(batch.date_roasted) > priorDate
    );

    recentBatchesTrend.forEach((batch) => {
      var convertdate = moment(new Date(batch.date_roasted)).valueOf();
      var compareDateStr = new Date(batch.date_roasted).toLocaleDateString();
      var objIdx = trendCounts.findIndex((obj) => {
        //  //console.log(obj);
        return obj.date.toLocaleDateString() == compareDateStr;
      });

      if (objIdx >= 0) {
        trendCounts[objIdx].count += 1;
      } else {
        trendCounts.push({ date: convertdate, count: 1 });
      }
    });

    var todayIdx = trendCounts.findIndex(
      (obj) =>
        today.toLocaleDateString() == new Date(obj.date).toLocaleDateString()
    );
    if (todayIdx < 0)
      trendCounts.push({
        date: moment(today).valueOf(),
        count: 0,
      });

    trendCounts.forEach((obj) => (obj.date = moment(obj.date).valueOf()));

    trendCounts = sortArrayBy(trendCounts, "date", true);
    ////console.log(trendCounts);
    setBatchesTrendData(trendCounts);
  }

  function notesTotals(roastList) {
    var notes = {};
    roastList.forEach((roast) => {
      roast.taste_notes.forEach((note) => {
        notes[note] = notes[note] ? notes[note] + 1 : 1;
      });
    });
    //  //console.log(notes)
  }

  const handleTabChange = (event, newValue) => {
    setTabSel(newValue);
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);

        getConsumerData();
        await getBatchesData();

        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        //console.log(error);
      }
    };

    queryAsync();
  }, []);

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        //setLoading(true);

        getTotals();
        setTop3(
          sortArrayBy(roasts, "total_consumer_likes", false).splice(0, 3)
        );

        //   setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        //console.log(error);
      }
    };

    queryAsync();
  }, [roasts]);

  useEffect(() => {
    const queryAsync = async () => {
      try {
        getBatchesTrend();
      } catch (error) {
        // add error handling here
        setLoading(false);
        //console.log(error);
      }
    };

    queryAsync();
  }, [batches]);

  function formatDate(tick) {
    // //console.log(tick)
    return moment(tick).format("MMM Do YY");
  }

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // //console.log(payload)
      var dateString = formatDate(label);
      return (
        <div className="custom-tooltip">
          <p className="label">{`${dateString}`}</p>
          <p className="label">{`${payload[0].name} : ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      <Container className="scroller" maxWidth={false} sx={{ mt: 3, mb: 2 }}>
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={tabSel}
            onChange={handleTabChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              {...panelProps(0)}
              label="My Roastery"
              sx={{
                borderBottom: "2px solid rgba(181, 52, 113,0.3)",
                color: "rgba(99, 110, 114,0.5)",
              }}
            />
            <Tab
              {...panelProps(1)}
              label="Engagement"
              sx={{
                borderBottom: "2px solid rgba(181, 52, 113,0.3)",
                color: "rgba(99, 110, 114,0.5)",
              }}
            />
          </Tabs>
        </Box>
        <Box sx={{ mt: 0, width: "100%", pb: 0, height: "60vh" }}>
          {loading ? (
            <LoadingLogo message="Loading..." />
          ) : (
            <React.Fragment>
              <TabPanel value={tabSel} index={0}>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={1}
                      sx={{
                        mt: 0,
                        justifyContent: "middle",
                        alignItems: "middle",
                      }}
                    >
                      <Grid item xs={12}>
                        <Box sx={{ pb: 2 }}>
                          <Typography
                            variant="subititle1"
                            sx={{
                              fontSize: "18px",
                              color: "#5f27cd",
                              fontWeight: 500,
                            }}
                          >
                            Overall
                          </Typography>
                        </Box>
                        <Paper
                          sx={{
                            pl: 2,
                            pr: 2,
                            display: "flex",
                            flexDirection: "column",
                            height: 130,
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                          }}
                        >
                          <Grid container spacing={0} height="100%">
                            <Grid
                              item
                              xs={3}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Stack>
                                <Typography component="p" variant="h4">
                                  {batches.length}
                                </Typography>
                                <Typography
                                  color="text.secondary"
                                  sx={{ flex: 1 }}
                                >
                                  Total Batches
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={9} sx={{ mt: 0 }}>
                              {batchesTrendData.length > 0 && (
                                <ResponsiveContainer width="100%" height="100%">
                                  <LineChart
                                    width={700}
                                    height={400}
                                    data={batchesTrendData}
                                    margin={{
                                      top: 10,
                                      right: 5,
                                      left: 10,
                                      bottom: 5,
                                    }}
                                  >
                                    <XAxis
                                      dataKey="date"
                                      tickFormatter={formatDate}
                                      domain={[
                                        batchesTrendData[0].date,
                                        batchesTrendData[
                                          batchesTrendData.length - 1
                                        ].date,
                                      ]}
                                      scale="time"
                                      type="number"
                                      dy={0}
                                      axisLine={false}
                                      tickLine={false}
                                      tick={true}
                                      allowDecimals={false}
                                      style={{ fontSize: "0.4rem" }}
                                      angle={-30}
                                      textAnchor="end"
                                    />
                                    <YAxis
                                      yAxisId="left"
                                      type="number"
                                      orientation="left"
                                      width={25}
                                      style={{ fontSize: "0.6rem" }}
                                      allowDecimals={false}
                                      allowDataOverflow={true}
                                      axisLine={false}
                                      tickLine={false}
                                      tick={{ fill: "#8884d8", opacity: 0 }}
                                    />
                                    <Tooltip content={<CustomTooltip />} />
                                    <Line
                                      yAxisId="left"
                                      type="monotoneX"
                                      name="Count"
                                      dataKey="count"
                                      stroke="#8884d8"
                                      strokeWidth={3}
                                      dot={false}
                                    />
                                  </LineChart>
                                </ResponsiveContainer>
                              )}
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Box sx={{ width: "100%", mt: 2, pb: 5 }}>
                      <BatchesTable
                        batches={batches}
                        rows={5}
                        deleteBtn={false}
                        user={userData}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </TabPanel>

              <TabPanel value={tabSel} index={1}>
                <Grid container spacing={2} sx={{ mt: 0 }}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subititle1"
                      sx={{
                        fontSize: "18px",
                        color: "#5f27cd",
                        fontWeight: 500,
                      }}
                    >
                      Reach
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{ mb: 1 }}>Total</Typography>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 120,
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography component="p" variant="h4">
                            {total_views}
                          </Typography>
                          <Typography color="text.secondary" sx={{ flex: 1 }}>
                            Views
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} md={4} lg={3}>
                    <Typography sx={{ mb: 1 }}>
                      <br />
                    </Typography>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 120,
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography component="p" variant="h4">
                            {total_likes}
                          </Typography>
                          <Typography color="text.secondary" sx={{ flex: 1 }}>
                            Likes
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4} lg={6}>
                    <Typography sx={{ mb: 1 }}>Most Popular</Typography>
                    <Paper
                      sx={{
                        p: 1.5,
                        display: "flex",
                        flexDirection: "row",
                        height: 120,
                      }}
                    >
                      {top3.length > 0 && (
                        <Grid container spacing={0.5}>
                          {top3.map((r, idx) => (
                            <Grid key={idx} item xs={4}>
                              <FavoriteRoast roast={r} />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                      {top3.length < 1 && (
                        <Typography
                          variant="body2"
                          sx={{ fontSize: 13, color: "#829AB1" }}
                        >
                          No data to show
                        </Typography>
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Views</Typography>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 300,
                        width: "100%",
                      }}
                    >
                      {roasterStats.views_ot.length > 0 && (
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart
                            width={700}
                            height={400}
                            data={roasterStats.views_ot}
                            margin={{
                              top: 10,
                              right: 5,
                              left: 10,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="date"
                              tickFormatter={formatDate}
                              domain={[
                                roasterStats.views_ot[0].date,
                                roasterStats.views_ot[
                                  roasterStats.views_ot.length - 1
                                ].date,
                              ]}
                              scale="time"
                              type="number"
                              dy={-2}
                              axisLine={false}
                              tickLine={false}
                              tick={true}
                              allowDecimals={false}
                              style={{ fontSize: "0.45rem" }}
                              angle={-35}
                              textAnchor="end"
                            />
                            <YAxis
                              yAxisId="left"
                              type="number"
                              orientation="left"
                              width={25}
                              style={{ fontSize: "0.6rem" }}
                              allowDecimals={false}
                              allowDataOverflow={true}
                              tick={{ fill: "#8884d8" }}
                            />
                            <Tooltip content={<CustomTooltip />} />
                            <Line
                              yAxisId="left"
                              type="monotoneX"
                              name="Views"
                              dataKey="views"
                              stroke="#8884d8"
                              strokeWidth={3}
                              dot={false}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Likes</Typography>
                    <Paper
                      sx={{
                        p: 2,
                        display: "flex",
                        flexDirection: "column",
                        height: 300,
                        width: "100%",
                      }}
                    >
                      {roasterStats.likes_ot.length > 0 && (
                        <ResponsiveContainer width="100%" height="100%">
                          <LineChart
                            width={700}
                            height={400}
                            data={roasterStats.likes_ot}
                            margin={{
                              top: 10,
                              right: 5,
                              left: 10,
                              bottom: 5,
                            }}
                          >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                              dataKey="date"
                              tickFormatter={formatDate}
                              domain={[
                                roasterStats.likes_ot[0].date,
                                roasterStats.likes_ot[
                                  roasterStats.likes_ot.length - 1
                                ].date,
                              ]}
                              scale="time"
                              type="number"
                              dy={-2}
                              axisLine={false}
                              tickLine={false}
                              tick={true}
                              allowDecimals={false}
                              style={{ fontSize: "0.45rem" }}
                              angle={-35}
                              textAnchor="end"
                            />
                            <YAxis
                              yAxisId="left"
                              type="number"
                              orientation="left"
                              width={25}
                              style={{ fontSize: "0.6rem" }}
                              allowDecimals={false}
                              allowDataOverflow={true}
                              tick={{ fill: "#8884d8" }}
                            />
                            <Tooltip content={<CustomTooltip />} />
                            <Line
                              yAxisId="left"
                              type="monotone"
                              name="Likes"
                              dataKey="likes"
                              stroke="#8884d8"
                              strokeWidth={3}
                              dot={false}
                            />
                          </LineChart>
                        </ResponsiveContainer>
                      )}
                    </Paper>
                  </Grid>
                </Grid>
              </TabPanel>
            </React.Fragment>
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0.5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function panelProps(index) {
  return {
    id: `-tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
