import React, { PureComponent } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, ZAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import {isLikelyForC} from '../helpers/utility'


export default class ManualBatchScatter extends PureComponent {



  render() {
    var dataObj = this.props.data;
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          width={400}
          height={400}
          margin={{
            top:5,
            bottom: -10,
            left: -20
          }}
        >
        <CartesianGrid strokeDasharray="" vertical={false} stroke="rgba(236, 240, 241,1.0)" />
          <XAxis type="number" dataKey="x" name="Roast Time" unit="s" axisLine={false} tickLine={false} style={{fontSize: '0.6rem' }} />
          <YAxis type="number" dataKey="y" name="Temp." unit={" °F"} axisLine={false} tickLine={false} style={{fontSize: '0.6rem' }} domain={[150, 450]} />
          <ZAxis hide={false} />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} wrapperStyle={{fontSize:"11px"}} />
          <Scatter name="Charge" data={[dataObj.charge]} fill="#d35400"/>
          <Scatter name="Dry End" data={[dataObj.dry_end]} fill="#8e44ad"/>
          <Scatter name="First Crack" data={[dataObj.fc_temp]} fill="#00a8ff" />
          <Scatter name="Second Crack" data={[dataObj.sc_temp]} fill="#273c75" />
          <Scatter name="Drop" data={[dataObj.drop_temp]} fill="#27ae60" />
          <Legend align="right" layout="horizontal" width="50vh" verticalAlign="bottom" iconSize="5" wrapperStyle={{fontSize: "10px", bottom: 35,right:0}} />
        </ScatterChart>
      </ResponsiveContainer>
    );
  }
}
