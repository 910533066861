import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";

const stackedBadgeIcon = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  width: "70px",
  height: "70px",
  padding: 10,
  color: "white",
  textAlign: "center",
};

const variants = [
  {
    type: "default",
    style: { borderRadius: "100px" },
  },
  {
    type: "left",
    style: { borderRadius: "5px 50px 50px 50px" },
  },
  {
    type: "right",
    style: { borderRadius: "50px 5px 50px 50px" },
  },
];

export default function StackedBadge({
  icon,
  text,
  color = "purple",
  contentColor = "white",
  variant = "default",
  tooltip = "",
}) {
  var boxStyle = variants.find((v) => v.type == "default").style;
  var boxStyleObj = variants.find((v) => v.type == variant);
  if (boxStyleObj) boxStyle = boxStyleObj.style;

  boxStyle["backgroundColor"] = color + "";

  //console.log(text, boxStyle)
  return (
    <React.Fragment>
      <Tooltip
        disableFocusListener
        placement="top"
        arrow
        enterTouchDelay={0}
        title={tooltip}
      >
        <Stack direction="column" spacing={1} sx={{ alignItems: "center" }}>
          <Typography sx={{ fontSize: 12, fontWeight: "700" }}>
            {tooltip}
          </Typography>
          <Box style={stackedBadgeIcon} sx={boxStyle}>
            {icon}
            <Typography variant="body1" sx={{ fontSize: 12, mt: 0.7 }}>
              {text}
            </Typography>
          </Box>
        </Stack>
      </Tooltip>
    </React.Fragment>
  );
}
