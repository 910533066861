import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import CircularLoader from "../CircularLoader";

//const functions = getFunctions(getApp());
//connectFunctionsEmulator(functions, "localhost", 5001);

//const functions = getFunctions();

function isPositiveInteger(str) {
  if (typeof str !== "string") {
    return false;
  }

  const num = Number(str);

  if (Number.isInteger(num) && num >= 0) {
    return true;
  }

  return false;
}

export default function SpeedField({ onEnter, isDisabled, inputRef }) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState([]);
  const [speedVal, setSpeedVal] = useState("");

  const handleSpeedField = (event) => {
    if (isPositiveInteger(event.target.value)) {
      setSpeedVal(event.target.value);
    }
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);

        //   console.log(data);
        // switch loading to false after fetch is complete
        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        setError(true);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  // return a Spinner when loading is true
  if (loading)
    return (
      <React.Fragment>
        <CircularLoader />
      </React.Fragment>
    );

  // data will be null when fetch call fails
  if (error)
    return (
      <React.Fragment>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={7}>
            <Typography sx={{ fontWeight: 600, color: "#5f27cd" }}>
              Whoops, looks like something went wrong. Sorry!
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <TextField
        disabled={isDisabled}
        inputRef={inputRef}
        fullWidth
        onChange={handleSpeedField}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            // Do code here
            console.log(speedVal);
            onEnter(speedVal);
            setSpeedVal("");
            ev.preventDefault();
          }
        }}
        value={isDisabled ? "Start timer to begin" : speedVal}
        placeholder="Enter bean temp"
        inputProps={{
          min: 0,
          maxLength: 3,
          style: { textAlign: "center", pl: 0 },
        }}
        sx={{
          "& fieldset": {
            borderRadius: "30px",
            border: "0px dashed",
            borderColor: "rgba(87, 88, 187,0.5)",
            ":before": {
              background: "none",
              border: "2px solid",
              borderColor: "rgba(217, 172, 224,1)",
              borderRadius: "40px",
              content: '""',
              display: "block",
              position: " absolute",
              top: "0px",
              left: "3px",
              right: "4px",
              bottom: "4px",
              pointerEvents: "none",
            },
          },
          // focused color for input with variant='outlined'
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              border: "2px dashed",
              borderColor: "rgba(87, 88, 187,0.5)",
            },
          },
        }}
      />
    </React.Fragment>
  );
}
