import ReactDOM from "react-dom/client";
import React, { useState, useEffect, useRef, useCallback } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import ListItemText from "@mui/material/ListItemText";

import LoginButton from "./pages/login";
import LogoutButton from "./pages/Logout";
import "./pages/main/styles/splash.css";

import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import { getAuth } from "firebase/auth";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { Stack } from "@mui/system";
import FullLogo from "./pages/main/assets/logotext.png";
import Logo from "./pages/main/assets/logo.png";
import { Typography } from "@mui/material";

const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

var uiConfig = {
  signInSuccessUrl: window.location.origin + "/roastery",
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    //     firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    //     firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //     firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    //     firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    //     firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    //     firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: "https://www.haste.coffee/privacy-policy",
  // Privacy policy url/callback.
  privacyPolicyUrl: function () {
    window.location.assign("https://www.haste.coffee/privacy-policy");
  },
};

var ui = new firebaseui.auth.AuthUI(auth);
function preventDefault(event) {
  event.preventDefault();
}

function Log_in_out(props) {
  const isAuthed = props.authed;
  if (isAuthed) {
    return (
      <React.Fragment>
        <Link href="/roastery/">
          <ListItemText primary="Go to Console" />
        </Link>
        <LogoutButton />
      </React.Fragment>
    );
  } else {
    return <LoginButton />;
  }
}

export default function Splash() {
  const root = ReactDOM.createRoot(document.getElementById("root"));
  //const myRef = useRef(null);
  const redirect = window.location.origin + "/roastery";

  const myRef = useCallback((node) => {
    if (node !== null) {
      console.log("ref", node.id); // node = elRef.current
      ui.start("#" + node.id, uiConfig);
    }
  }, []);

  return root.render(
    <Container
      maxWidth="100%"
      sx={{
        backgroundColor: "rgba(52,31,151,0.9)",
      }}
    >
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box boxShadow={4} sx={{ borderRadius: "25px" }}>
          <Stack
            spacing={5}
            sx={{
              backgroundColor: "rgba(33, 33, 33,0.6)",
              borderRadius: "25px",
              p: 9,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src={FullLogo} style={{ width: "200px", order: -1 }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 600, color: "white" }}>
                Roaster's Platform
              </Typography>
            </Box>
            <div ref={myRef} id="firebaseui-auth-container"></div>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pt: 3,
              }}
            >
              <img src={Logo} style={{ width: "50px" }} />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Container>
  );
}
