import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { styled } from "@mui/material/styles";
import { useNavigate, NavLink } from "react-router-dom";

import {
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  TextField,
  Divider,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Fade,
  Slide,
  DialogActions,
  CircularProgress,
} from "@mui/material";

import BackgroundSVG from "./assets/bg_bird.svg";
import {
  getFirestore,
  collection,
  getDoc,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  onSnapshot,
  orderBy,
  addDoc,
  limit,
  updateDoc,
  FieldValue,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const functions = getFunctions();

function preventDefault(event) {
  event.preventDefault();
}
const ActionButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "20px",
  fontSize: "13px",
  backgroundColor: "rgba(104,10,239,0.75)",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,1)",
  },
  paddingLeft: 20,
  paddingRight: 20,
  "&:disabled": {
    backgroundColor: "rgba(0,0,0,0.1)",
  },
  textTransform: "initial",
}));

const heroBg = {
  backgroundImage: `url(
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%23ffffff' fill-opacity='0.07' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E"
  )`,
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RoasteryInfoPage({ userInfo }) {
  const [loading, setLoading] = useState(false);
  const [roasterName, setRoasterName] = useState("");
  const [submitted, setSubmitted] = useState(false);

  let navigate = useNavigate();

  var state = {
    date_added: new Date(),
    user: userInfo,
  };

  const handleRoastChange = (e) => {
    setRoasterName(e.target.value);
  };

  const handleGoClick = async () => {
    // Add a new document with a generated id.
    setLoading(true);

    const docRef = await addDoc(collection(db, "Roasters"), {
      name: roasterName,
      user_info: [{ id: userInfo.sub, role: "owner" }],
      users: [userInfo.sub],
    });

    const doc2Ref = await addDoc(collection(db, "User Settings"), {
      user_id: userInfo.sub,
    });
    setSubmitted(true);
    setLoading(false);
    setTimeout(() => {
      console.log("refreshing");
      let path = "/roastery";
      navigate(0);
    }, 2000);
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        fullScreen={false}
        open={true}
        TransitionComponent={Transition}
        sx={{
          background: `linear-gradient(rgba(250,248,254,0.9), rgba(250,248,254,0.9)), url(${BackgroundSVG}) no-repeat`,
          backgroundAttachment: "fixed",
          backgroundPosition: "-50% 125%",
        }}
      >
        <Box
          sx={{
            border: "4px solid white",
          }}
        >
          <DialogTitle
            sx={{
              backgroundColor: "rgba(104,10,239,0.8)",
              display: "flex",
              p: 8,
              pt: 4,
              pb: 4,
              borderRadius: "3px",
              textAlign: "center",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              minHeight: 250,
            }}
            style={heroBg}
          >
            {!submitted && (
              <React.Fragment>
                <Typography
                  sx={{ color: "white", fontWeight: 600, fontSize: 22 }}
                >
                  Welcome!
                </Typography>
                <Typography
                  sx={{ color: "white", fontWeight: 500, fontSize: 20 }}
                >
                  Let's name your roastery
                </Typography>
                <TextField
                  id="roastery_name"
                  variant="outlined"
                  placeholder="Enter name here!"
                  value={roasterName}
                  onChange={handleRoastChange}
                  fullWidth
                  sx={{
                    mt: 3,

                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        border: "0px",
                        borderColor: "rgba(87, 88, 187,0.9)",
                      },
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: "center",
                    },
                    maxLength: 40,
                  }}
                  InputProps={{
                    sx: {
                      backgroundColor: "white",
                      borderRadius: "40px",
                    },
                  }}
                />
                <ActionButton
                  fullWidth
                  onClick={handleGoClick}
                  disabled={roasterName == "" || loading}
                  sx={{ mt: 2, fontSize: 18 }}
                >
                  Let's go!
                </ActionButton>
              </React.Fragment>
            )}
            {submitted && (
              <React.Fragment>
                <Fade in={true}>
                  <Typography
                    sx={{ color: "white", fontWeight: 600, fontSize: 22 }}
                  >
                    Let's do this!
                  </Typography>
                </Fade>
              </React.Fragment>
            )}
          </DialogTitle>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
