import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import CircularLoader from "../CircularLoader";
import { getContrastyTextColor } from "../helpers/TasteNoteMapper";
//const functions = getFunctions(getApp());
//connectFunctionsEmulator(functions, "localhost", 5001);

//const functions = getFunctions();

export default function Badge({
  data,
  label,
  color = "rgba(162, 155, 254,0.2)",
  small = false,
}) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  //  const [data, setData] = useState([]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: color,
            color: getContrastyTextColor(color),
            borderRadius: "15px",
            padding: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Typography sx={{ fontSize: small ? 12 : 25, fontWeight: 500 }}>
            {data}
          </Typography>
          <Typography sx={{ fontSize: small ? 8 : 15, fontWeight: 300 }}>
            {label}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
