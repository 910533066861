import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  AreaChart,
  Area,
  ReferenceLine,
} from "recharts";
import RoastProfileJsonParser from "./helpers/RoastProfileJsonParser";
import { isLikelyForC, isEmptyObj } from "../helpers/utility";
import CircularLoader from "../CircularLoader";
import LoadingLogo from "../components/LoadingLogo";
import { ConstructionOutlined } from "@mui/icons-material";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    var haveBT = payload[0].name == "Bean Temp.";
    var btLabel = haveBT ? Math.round(payload[0].value) : "-";

    var haveROR = haveBT
      ? payload[1] && payload[1].name == "Rate of Temp. Rise"
      : payload[0].name == "Rate of Temp. Rise";

    var rorLabel = haveROR
      ? haveBT
        ? Math.round(payload[1].value)
        : Math.round(payload[0].value)
      : "-";
    return (
      <div className="custom-tooltip">
        <p className="label">{`Bean Temp. : ${btLabel}`}</p>
        <p className="label">{`Rate of Temp. Rise : ${Math.round(
          rorLabel
        )}`}</p>
      </div>
    );
  }

  return null;
};

export default class ProfileLineGraph extends PureComponent {
  //RoastProfileJsonParser param

  smooth(dataArray) {
    var copyOfArray = dataArray;
    copyOfArray[0] = 0;
    copyOfArray[1] = 0;
    for (var i = 2; i < dataArray.length; i++) {
      copyOfArray[i] = (dataArray[i - 2] + dataArray[i - 1] + dataArray[i]) / 3;
    }

    return copyOfArray;
  }

  smoothArray(arr, windowSize) {
    if (windowSize < 2) {
      throw new Error("Window size must be at least 2");
    }

    // console.log(arr, windowSize);

    const smoothed = [];
    let lastNonNullIndex = -1;
    let sum = 0;
    let count = 0;

    // Handle initial null values by filling them in with the first non-null value
    for (let i = 0; i < arr.length; i++) {
      if (arr[i] !== null) {
        const firstNonNullValue = arr[i];
        for (let j = 0; j < i; j++) {
          smoothed[j] = firstNonNullValue;
        }
        smoothed[i] = firstNonNullValue;
        lastNonNullIndex = i;
        break;
      }
    }

    // Smooth the remaining values
    for (let i = lastNonNullIndex + 1; i < arr.length; i++) {
      if (arr[i] !== null) {
        if (lastNonNullIndex !== -1) {
          // Calculate the average of the adjacent non-null values
          const avg = sum / count;
          for (let j = lastNonNullIndex + 1; j < i; j++) {
            smoothed[j] = avg;
          }
          sum = 0;
          count = 0;
        }
        smoothed[i] = arr[i];
        lastNonNullIndex = i;
      } else {
        // Add the value to the sum and count for averaging later
        sum += arr[lastNonNullIndex];
        count++;
      }
    }
    //  console.log(smoothed);

    const rollingSmoothed = this.rollingAverage(smoothed, windowSize);

    // console.log(rollingSmoothed);

    return rollingSmoothed;
  }

  rollingAverage(arr, windowSize) {
    if (windowSize < 2) {
      throw new Error("Window size must be at least 2");
    }

    const smoothed = [];
    let sum = 0;

    for (let i = 0; i < arr.length; i++) {
      sum += arr[i];
      if (i >= windowSize - 1) {
        // Calculate the average over the window
        const avg = sum / windowSize;
        smoothed.push(avg);
        sum -= arr[i - windowSize + 1];
      }
    }

    return smoothed;
  }

  createSmoothedData(objArray, windowSize = 10) {
    var smoothed = objArray.map((obj) => obj.ror);
    smoothed = this.smoothArray(smoothed, windowSize);

    var copy2 = objArray.map((obj, idx) => {
      return { ...obj, ror: smoothed[idx] };
    });

    return copy2;
  }

  render() {
    var data = this.props.data;
    var toSmooth = this.props.smooth;
    var showReference = this.props.showReference;
    var smoothWindowSize = Number.isInteger(this.props.smoothSize)
      ? this.props.smoothSize + 1
      : 5;
    var isConsumer = this.props.consumerDisplay;
    var eventLines = this.props.eventLines;

    //console.log("smoth", data);

    if (isEmptyObj(data) || data == null)
      return (
        <React.Fragment>
          <LoadingLogo message="Loading Roast Profile..." />
        </React.Fragment>
      );

    var rorArray = data.map((obj) => {
      if (obj.ror) return obj.ror;
      return 0;
    });

    var refrorArray = data.map((obj) => {
      if (obj.ref_ror) return obj.ref_ror;
      return 0;
    });
    //console.log(rorArray, refrorArray);
    rorArray = rorArray.concat(refrorArray);
    //console.log(rorArray);

    const maxRoR = Math.max(...rorArray);
    const minTime = data[0].time < 0 ? data[0].time : 0;
    const maxTime = data[data.length - 1].time;

    //console.log(maxRoR, minTime, maxTime);

    //  console.log(data) // {time: 1.7031437082, bt: 373.87, ror: 11.33, ror_deriv: -38.71}
    //console.log("smoothed", toSmooth);

    if (toSmooth && smoothWindowSize > 1)
      data = this.createSmoothedData(data, smoothWindowSize);

    if (isConsumer) {
      data = data.filter(function (obj) {
        return obj.time >= 0;
      });
      return (
        <React.Fragment>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={700}
              height={400}
              data={data}
              margin={{
                top: 10,
                right: 20,
                left: 10,
                bottom: 5,
              }}
              style={{
                fontFamily:
                  "'Montserrat','Roboto','Helvetica Neue','Arial','sans-serif'",
              }}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#8884d8" stopOpacity={0.6} />
                  <stop offset="13%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid
                strokeDasharray="5 5 "
                stroke="rgba(34, 47, 62,0.2)"
                vertical={false}
              />
              <XAxis
                dataKey="time"
                type="number"
                label={{
                  value: "Roast Time",
                  position: "insideBottom",
                  offset: 0,
                  fontSize: "0.7rem",
                  fill: "rgba(34, 47, 62,0.6)",
                }}
                dy={-1}
                axisLine={false}
                unit="s"
                tickLine={false}
                allowDecimals={false}
                domain={[0, Math.ceil((maxTime + 15 + 1) / 10) * 10]}
                style={{ fontSize: "0.6rem", color: "rgba(95, 39, 205,0.8)" }}
                tick={{ fill: "rgba(34, 47, 62,0.6)" }}
              />
              <YAxis
                yAxisId="right"
                type="number"
                orientation="right"
                domain={[150, 500]}
                unit={" °" + "F"}
                allowDecimals={false}
                width={35}
                tick={{ fill: "rgba(95, 39, 205,0.5)" }}
                style={{ fontSize: "0.7rem", fontWeight: 600 }}
                axisLine={false}
                tickLine={false}
                hide={false}
              />
              <YAxis
                yAxisId="left"
                type="number"
                orientation="left"
                width={25}
                style={{ fontSize: "0.7rem", fontWeight: 600 }}
                domain={[0, Math.ceil(maxRoR / 10) * 10 + 5]}
                allowDecimals={false}
                allowDataOverflow={true}
                tick={{ fill: "rgba(95, 39, 205,1)" }}
                axisLine={false}
                tickLine={false}
              />
              <Legend
                wrapperStyle={{
                  position: "relative",
                  marginTop: "-12px",
                  fontSize: "14px",
                }}
              />
              <Area
                fill="none"
                yAxisId="right"
                type="monotoneX"
                name="Bean Temp."
                dataKey="bt"
                stroke="rgba(95, 39, 205,0.4)"
                strokeWidth={3}
                dot={false}
                legendType="plainline"
                strokeDasharray="5 5"
              />
              <Area
                fill="url(#colorUv)"
                yAxisId="left"
                type="monotoneX"
                name="Rate of Temp. Rise"
                dataKey="ror"
                stroke="rgba(95, 39, 205,1.0)"
                strokeWidth={3}
                dot={false}
                legendType="plainline"
                connectNulls={true}
              />
              {eventLines &&
                eventLines.map((line, i) => (
                  <ReferenceLine
                    key={"rl" + i}
                    x={line.time}
                    label={{
                      position: "top",
                      value: line.label,
                      fill: "rgba(95, 39, 205,1)",
                      fontSize: 11,
                      fontWeight: 600,
                    }}
                    style={{ opacity: "0.3" }}
                    stroke={"rgba(95, 39, 205,1)"}
                    yAxisId="left"
                  />
                ))}
              <Tooltip position={{ x: 0, y: 0 }} content={<CustomTooltip />} />
            </AreaChart>
          </ResponsiveContainer>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={700}
              height={400}
              data={data}
              margin={{
                top: 15,
                right: 5,
                left: 10,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" style={{ opacity: "0.6" }} />
              <XAxis
                dataKey="time"
                type="number"
                label={{
                  value: "Roast Time",
                  position: "insideBottom",
                  fontSize: "0.7rem",
                }}
                dy={-1}
                axisLine={false}
                unit="s"
                tickLine={false}
                tick={true}
                allowDecimals={false}
                domain={[minTime, Math.ceil((maxTime + 15 + 1) / 10) * 10]}
                style={{ fontSize: "0.7rem" }}
              />
              <YAxis
                yAxisId="left"
                type="number"
                domain={[0, 500]}
                unit={" °" + "F"}
                allowDecimals={false}
                width={40}
                tick={{ fill: "rgba(95, 39, 205,0.4)" }}
                style={{ fontSize: "0.7rem" }}
              />
              <YAxis
                yAxisId="right"
                type="number"
                orientation="right"
                width={25}
                domain={[0, Math.ceil(maxRoR / 10) * 10 + 10]}
                allowDecimals={false}
                allowDataOverflow={true}
                tick={{ fill: "rgba(142, 68, 173,1.0)" }}
                style={{ fontSize: "0.7rem", fontWeight: 600 }}
              />
              <Tooltip />
              <Legend />

              <Line
                yAxisId="right"
                type="monotoneX"
                name="BT Rate of Rise"
                dataKey="ror"
                stroke="rgba(142, 68, 173,1.0)"
                strokeWidth={3}
                dot={false}
                legendType="plainline"
                connectNulls={true}
              />
              <Line
                yAxisId="left"
                type="monotoneX"
                name="Bean Temp"
                dataKey="bt"
                stroke="rgba(95, 39, 205,0.4)"
                strokeWidth={2}
                dot={false}
                legendType="plainline"
                strokeDasharray="5 5"
              />
              {data[0] && data[0].et && (
                <Line
                  yAxisId="left"
                  type="monotoneX"
                  name="Environment Temp"
                  dataKey="et"
                  stroke="rgba(231, 76, 60,0.6)"
                  strokeWidth={2}
                  dot={false}
                  legendType="plainline"
                  strokeDasharray="5 5"
                />
              )}
              {showReference && (
                <React.Fragment>
                  <Line
                    yAxisId="left"
                    type="monotoneX"
                    name="Bean Temp Ref"
                    dataKey="ref_bt"
                    stroke="red"
                    strokeWidth={2}
                    dot={false}
                    legendType="plainline"
                    strokeDasharray="5 5"
                  />
                  <Line
                    yAxisId="right"
                    type="monotoneX"
                    name="BT Rate of Rise Ref"
                    dataKey="ref_ror"
                    stroke="orange"
                    strokeWidth={3}
                    dot={false}
                    legendType="plainline"
                    connectNulls={true}
                  />
                </React.Fragment>
              )}
              {eventLines &&
                eventLines.map((line, i) => (
                  <ReferenceLine
                    key={"rl" + i}
                    x={line.time}
                    label={{
                      position: "top",
                      value: line.label,
                      fill: "rgba(95, 39, 205,1)",
                      fontSize: 11,
                      fontWeight: 600,
                    }}
                    style={{ opacity: "0.3" }}
                    stroke={"rgba(95, 39, 205,1)"}
                    yAxisId="left"
                  />
                ))}
            </LineChart>
          </ResponsiveContainer>
        </React.Fragment>
      );
    }
  }
}
