import React from "react";
import ReactDOM from "react-dom/client";
import { initializeApp } from "firebase/app";
import App from "./App";
import Splash from "./Splash";
import ConsumerSummary from "./ConsumerSummary";
import ConsumerBatchSummary from "./pages/consumer/ConsumerBatchSummary";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { useParams } from "react-router-dom";

import { Auth0Provider } from "@auth0/auth0-react";

import { ProtectedRoute } from "./auth/protected-route";

import { PostHogProvider } from "posthog-js/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
const redirect = window.location.origin + "/roastery";

const posthog_options = {
  api_host: "https://app.posthog.com",
};

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={"phc_bG5yBxL7KMQKlGBZc79CrRux8wfFxBO3zEJwdSXZLYq"}
      options={posthog_options}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Splash />} />
          <Route path="/tasty/:batchID" element={<ConsumerSummary />} />
          <Route path="/happy/:batchID" element={<ConsumerBatchSummary />} />
          <Route path="/discover/:batchID" element={<ConsumerBatchSummary />} />
          <Route path="/roastery" element={<App />} />
        </Routes>
      </Router>
    </PostHogProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
