import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useNavigate, NavLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import Paper from "@mui/material/Paper";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import List from "@mui/material/List";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import {
  getFirestore,
  collection,
  getDoc,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { initializeApp } from "firebase/app";
import ClearIcon from "@mui/icons-material/Clear";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { sortArrayBy } from "./helpers/utility";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import "./CopilotDrawer";
import TimelineIcon from "@mui/icons-material/Timeline";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import VerifiedIcon from "@mui/icons-material/Verified";
import { CSSTransition, TransitionGroup } from "react-transition-group"; // ES6

import Skeleton from "@mui/material/Skeleton";
import QRDialog from "./components/QRDialog";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

import { RoastProfileParser } from "./Roasting/helpers/RoastProfileParser";
import { RoastProfile } from "./Roasting/helpers/RoastProfile";
import { RoastProfileJsonParser } from "./Roasting/helpers/RoastProfileJsonParser";
import ProfileDialog from "./components/ProfileDialog";
import Stack from "@mui/material/Stack";

import BackgroundSVG from "./assets/sidebar_bg.svg";

const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const functions = getFunctions();
const GetRoastById = httpsCallable(functions, "GetRoastById");

var currentUser = "";
var currentBatch = {};

const drawerWidth = 220;

const linkStyle = {
  textDecoration: "none",
  color: "#222f3e",
  display: "inline-block",
  marginTop: "5px",
  width: "100%",
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 55,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  height: "94vh",
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  m: 0,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

//sx={{width:"14%", height: '98vh'}}

export default function CopilotDrawer({ userData, roaster }) {
  const [inProgress, setInProgress] = React.useState([]);
  const [recentBatches, setRecentBatches] = React.useState([]);

  const [openDrawer, setOpenDrawer] = React.useState(true);
  const [loading, setLoading] = useState(true);
  const [hoverRoast, setHoverRoast] = useState({});

  const { sub } = userData;
  currentUser = sub;

  let navigate = useNavigate();

  const handleAddMoreClick = () => {
    let path = "/roastery/roasts";
    navigate(path);
  };

  async function getCopilotInProgressRoastsData(user_id, roast) {
    var data = {};
    const docs = await getDoc(doc(db, "Roasts", roast.roast_id));
    data = docs.data();
    roast["roastInfo"] = data;
    return roast;
  }

  function deleteBatch(batch_id) {
    //   console.log("deleting " + batch_id)
    deleteDoc(doc(db, "Batches", batch_id));
  }

  // probably need to come back here and save reads!
  async function getCopilotInProgress(user_id) {
    var data = {};
    // console.log(roaster);
    var q = query(
      collection(db, "Batches"),
      where("roaster_id", "==", roaster.id),
      where("status", "in", [
        "Not Started",
        "In Progress",
        "In Progress - Roasted",
      ])
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      var inProgress = [];
      //  console.log(querySnapshot);
      if (querySnapshot.size == 0) {
        setInProgress(inProgress);
      } else {
        querySnapshot.forEach((doc) => {
          data = doc.data();
          data["id"] = doc.id;
          getCopilotInProgressRoastsData(user_id, data).then((val) => {
            var newData = val;
            //       console.log("getting roasts data")
            inProgress.push(newData);
            setInProgress(inProgress);
          });
          //     console.log(data);
        });
      }
    });

    return inProgress;
  }

  async function getRecentBatches(user_id) {
    var data = {};
    var today = new Date(new Date().toLocaleDateString());
    //   console.log("recents", today);
    var q = query(
      collection(db, "Batches"),
      where("roaster_id", "==", roaster.id),
      where("status", "==", "Done"),
      where("date_roasted", ">", today)
    );
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        var recents = [];
        //     console.log("recents")
        //     console.log(querySnapshot);
        if (querySnapshot.size == 0) {
          setRecentBatches(recents);
        } else {
          querySnapshot.forEach((doc) => {
            data = doc.data();
            data["id"] = doc.id;
            recents.push(data);
            setRecentBatches(recents);

            //      console.log(data);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );

    return recentBatches;
  }

  const [profileOpen, setProfileOpen] = React.useState(false);
  const [profileForDialog, setProfile] = React.useState({});
  const [batchID, setBatchID] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const recentHoverShow = Boolean(anchorEl);

  const [hoverLoading, setHoverLoading] = React.useState(false);

  async function handleProfileClick(id) {
    //console.log(profileURL)
    setBatchID(id);
    setProfileOpen(true);
  }

  const handleCloseProfile = () => {
    setProfileOpen(false);
  };

  const [qrOpen, setQROpen] = React.useState(false);
  const [qrRoast, setQRRoast] = React.useState("");

  async function handleQRClick(roast_id) {
    setQRRoast(roast_id);
    setQROpen(true);
  }

  const handleCloseQR = () => {
    setQROpen(false);
  };

  const collapseExpand = () => {
    console.log(!openDrawer ? "open" : "close");
    setOpenDrawer(!openDrawer);
  };

  const showRoastHover = (event, id) => {
    //  console.log(id, hoverRoast.id);
    if (id != hoverRoast.id) {
      setHoverLoading(true);
      GetRoastById(id).then((resp) => {
        //       console.log(resp.data);
        setHoverRoast(resp.data);
        setHoverLoading(false);
      });
    }
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function RoastHover({ roast, batch }) {
    return (
      <Stack spacing={0.5}>
        <Typography color="#8e44ad" sx={{ fontWeight: 600 }}>
          {roast.name}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {roast.roast_degree}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            fontSize: 12,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
          }}
        >
          {roast.description}
        </Typography>
      </Stack>
    );
  }

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);
        //const data = await fetchData();
        const data = await getCopilotInProgress(currentUser);
        //    console.log(data);
        setInProgress(data);

        const recent = await getRecentBatches(currentUser);
        setRecentBatches(recent);
        //   console.log(data);
        // switch loading to false after fetch is complete
        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  return (
    <React.Fragment>
      <Drawer
        anchor={"right"}
        open={openDrawer}
        variant="permanent"
        PaperProps={{
          sx: {
            m: 0,
            mt: 6.25,
            mr: 0.5,
            backgroundColor: "rgba(255,255,255,0.8) ",
            height: "92vh",
            borderRadius: "15px",
          },
          elevation: 1,
        }}
      >
        <Box
          role="presentation"
          sx={{
            height: "100%",
            background: `linear-gradient(rgba(255,255,255,0.85), rgba(255,255,255,0.85)), url(${BackgroundSVG}) no-repeat`,
            backgroundSize: "cover",
          }}
        >
          <Typography
            variant="subtitle2"
            align="center"
            sx={{
              p: 1,
              backgroundColor: "rgba(95, 39, 205,1.0) ",
              backgroundImage: "url(" + require("./assets/bgappbar.png") + ")",
              backgroundSize: "cover",
              color: "white",
              fontWeight: 900,
              height: 40,
              justifyContent: "center",
            }}
          >
            <Tooltip
              title="Toggle"
              placement="left"
              enterDelay={750}
              leaveDelay={200}
            >
              <IconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  color: "white",
                  width: openDrawer ? "" : "100%",
                  ml: openDrawer ? 0.5 : 0,
                }}
                onClick={collapseExpand}
              >
                {openDrawer ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </Tooltip>
            <WhatshotIcon
              size="small"
              sx={{
                transform: "scale(0.7)",
                verticalAlign: "middle",
                mt: -0.5,
                display: openDrawer ? "" : "none",
              }}
            />
            {openDrawer ? "Copilot" : ""}
          </Typography>
          <Box sx={{ display: openDrawer ? "" : "none" }}>
            <Box sx={{ ml: 2, mt: 2 }}>
              <Typography sx={{ fontSize: 12 }} color="text.secondary">
                Roast Queue
              </Typography>
            </Box>
            <List
              className="scroller"
              sx={{ height: "60vh", pt: 0, overflowX: "hidden" }}
            >
              <ListItem sx={{ justifyContent: "center", opacity: "0.7" }}>
                <Button
                  sx={{ fontSize: 11, height: 20 }}
                  fullWidth
                  onClick={handleAddMoreClick}
                >
                  <AddIcon fontSize="15px" />
                  Add
                </Button>
              </ListItem>
              <TransitionGroup>
                {sortArrayBy(inProgress, ["datetime_added"]).map(
                  (
                    row,
                    id // sorting data
                  ) => (
                    <CSSTransition
                      key={row.id}
                      timeout={500}
                      classNames="copilot-items"
                    >
                      <ListItem key={row.id}>
                        <Card
                          sx={{
                            p: 0,
                            mt: -1,
                            ml: 0,
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: "15px 0 15px 0 !important",
                            width: "100%",
                          }}
                        >
                          <CardContent
                            sx={{ p: 1 }}
                            className="showHiddenHovers"
                          >
                            <IconButton
                              className="hideUntilHover"
                              variant="contained"
                              fontSize="small"
                              sx={{
                                position: "absolute",
                                top: -10,
                                right: 0,
                                color: "#34495e",
                              }}
                              onClick={() => deleteBatch(row.id)}
                            >
                              <RemoveCircleIcon />
                            </IconButton>
                            <Typography
                              sx={{ fontSize: 10, float: "right", mr: 1 }}
                              color="text.secondary"
                              display="inline"
                            >
                              {new Date(
                                row.datetime_added.seconds * 1000
                              ).toLocaleTimeString()}
                            </Typography>
                            <NavLink
                              to={"/roastery/roasts/" + row.roast_id}
                              style={linkStyle}
                            >
                              <Typography
                                sx={{ fontSize: 11, ml: 0.5 }}
                                color="text.primary"
                                display="inline"
                              >
                                {row.roastInfo.name}
                              </Typography>
                            </NavLink>
                          </CardContent>
                          <CardActions sx={{ justifyContent: "center", p: 0 }}>
                            <NavLink
                              to={"/roastery/copilot/" + row.id}
                              style={linkStyle}
                            >
                              <Button
                                size="small"
                                sx={{
                                  opacity: "0.7",
                                  height: 20,
                                  color: "#6c5ce7",
                                  fontSize: 10,
                                }}
                                fullWidth
                              >
                                Roast
                              </Button>
                            </NavLink>
                          </CardActions>
                        </Card>
                      </ListItem>
                    </CSSTransition>
                  )
                )}
              </TransitionGroup>
            </List>
            <Divider />
            <Box sx={{ ml: 2, mt: 2 }}>
              <Typography sx={{ fontSize: 12 }}>Today's Roasts</Typography>
            </Box>
            <Box
              className="scroller"
              sx={{
                maxHeight: "18vh",
                overflowX: "hidden",
              }}
            >
              <List sx={{}}>
                {sortArrayBy(recentBatches, ["date_roasted"], false).map(
                  (
                    row,
                    idx // sorting data
                  ) => (
                    <ListItem
                      key={row.id}
                      sx={{
                        m: 0,
                        pt: 0.5,
                        pb: 0.5,
                        borderBottom: "1px solid rgba(212, 212, 212, 0.5)",
                      }}
                      aria-owns={
                        recentHoverShow ? "mouse-over-popover" : undefined
                      }
                      aria-haspopup="true"
                      onMouseEnter={(ev) => showRoastHover(ev, row.roast_id)}
                      onMouseLeave={handlePopoverClose}
                    >
                      <Typography sx={{ fontSize: 10 }} display="inline">
                        #{recentBatches.length - idx}&nbsp;&nbsp;&nbsp;
                        {new Date(
                          row.date_roasted.seconds * 1000
                        ).toLocaleTimeString()}
                      </Typography>
                      <Box sx={{ marginLeft: "auto" }}>
                        <IconButton
                          variant="contained"
                          size="small"
                          onClick={() => {
                            handleProfileClick(row.id);
                          }}
                        >
                          <TimelineIcon fontSize="7px" />
                        </IconButton>
                        <IconButton
                          variant="contained"
                          size="small"
                          onClick={() => {
                            handleQRClick(row.id);
                          }}
                        >
                          <QrCode2Icon fontSize="7px" />
                        </IconButton>
                      </Box>
                    </ListItem>
                  )
                )}
              </List>
            </Box>
          </Box>
          <Box
            sx={{
              display: openDrawer ? "none" : "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "85vh",
              flexDirection: "column",
            }}
            onClick={collapseExpand}
          >
            <Box>
              <Tooltip title="Roast Queue" placement="left">
                <Badge
                  badgeContent={inProgress.length}
                  color="primary"
                  sx={{
                    "& .MuiBadge-badge": {
                      color: "white",
                      backgroundColor: "rgba(84, 160, 255,1.0)",
                      fontWeight: 600,
                    },
                  }}
                >
                  <WhatshotIcon
                    color="action"
                    sx={{ color: "rgba(95, 39, 205,1.0)" }}
                  />
                </Badge>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title="Today's Roasts" placement="left">
                <Badge
                  badgeContent={recentBatches.length}
                  color="primary"
                  sx={{
                    mt: 5,
                    "& .MuiBadge-badge": {
                      color: "white",
                      backgroundColor: "rgba(16, 172, 132,1.0)",
                      fontWeight: 600,
                    },
                  }}
                >
                  <VerifiedIcon sx={{ color: "rgba(95, 39, 205,1.0)" }} />
                </Badge>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={recentHoverShow}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box sx={{ p: 2, minWidth: 200, maxWidth: 400 }}>
          {hoverLoading ? (
            <React.Fragment>
              <Skeleton sx={{ opacity: 0.5 }} />
              <Skeleton sx={{ opacity: 0.5 }} />
              <Skeleton sx={{ opacity: 0.5 }} />
            </React.Fragment>
          ) : (
            <RoastHover roast={hoverRoast} />
          )}
        </Box>
      </Popover>
      <ProfileDialog
        open={profileOpen}
        onClose={handleCloseProfile}
        batch_id={batchID}
        user={userData}
      />

      <QRDialog open={qrOpen} onClose={handleCloseQR} qrRoastID={qrRoast} />
    </React.Fragment>
  );
}
