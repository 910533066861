import React, { useState, useEffect } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  onSnapshot,
} from "firebase/firestore";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Container } from "@mui/material";

import Divider from "@mui/material/Divider";
import { initializeApp } from "firebase/app";
import GreensSidebar from "./GreensSidebar";
import RoastCardList from "./RoastCardList";
import RoastDetails from "./RoastDetails";

import AddGreenDialog from "../components/AddGreenDialog";
import AddRoastDialog from "../components/AddRoastDialog";
import DeleteGreenDialog from "../components/DeleteGreenDialog";
import DeleteRoastDialog from "../components/DeleteRoastDialog";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import EditIcon from "@mui/icons-material/Edit";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import { getFunctions, httpsCallable } from "firebase/functions";
import LoadingLogo from "../components/LoadingLogo";
import { DateRange, Edit } from "@mui/icons-material";
import CoffeePlant from "../assets/coffee_plant.svg";

const functions = getFunctions();
const GetGreensForRoaster = httpsCallable(functions, "GetGreensForRoaster");
const ToggleGreenPinned = httpsCallable(functions, "ToggleGreenPinned");
const GetBatchById = httpsCallable(functions, "GetBatchById");
const GetRoastsForGreen = httpsCallable(functions, "GetRoastsForGreen");
const GetRoastById = httpsCallable(functions, "GetRoastById");

const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6",
};

const ActionButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "20px",
  fontSize: "13px",
  height: "35px",
  backgroundColor: "rgba(104,10,239,0.7)",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,1)",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(165, 177, 194,0.3)",
  },
}));

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

var currentUser = "";

const AddRoastButton = styled(Button)(({ theme }) => ({
  color: "rgba(104,10,239,0.8)",
  border: "1px solid rgba(87, 101, 116,0.3)",
  fontWeight: 600,
  borderRadius: "3px",
  fontSize: "9px",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,0.1)",
    border: "1px solid rgba(104,10,239,0.8)",
  },
}));

const CustomMenu = styled(Menu)(({ theme }) => ({
  p: 3,
}));

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: 11,
  p: 1.4,
  pt: 0,
  pb: 0,
  "&:hover": {
    backgroundColor: "rgba(104,10,239,0.1)",
  },
}));

var styles = {
  FGBackGround: "rgba(86,64,191,0.6)",
  IconColor: "white",
};

const mdTheme = createTheme({
  palette: {
    primary: {
      main: "#2c3e50",
    },
  },

  typography: {
    allVariants: {
      fontFamily: "'Montserrat','Roboto','Helvetica Neue','Arial','sans-serif'",
      fontSpacing: "0",
    },
    h3: {
      fontSize: 30,
    },
    h4: {
      fontSize: 20,
      fontWeight: 600,
    },
    h5: {
      fontWeight: 300,
      fontSize: 20,
      paddingTop: 20,
    },
    h6: {
      fontWeight: 100,
    },
    body1: {
      fontSize: 15,
    },
    body2: {
      fontWeight: 300,
      fontSize: 15,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 12,
    },
    subtitle2: {
      fontWeight: 300,
      fontSize: 11,
    },
  },
});

export default function Roasts({ userData, roaster }) {
  const [greensData, setGreensData] = useState([]);
  const [roastsData, setRoastsData] = useState([]);
  const [batchesData, setBatchesData] = useState([]);

  const [greensLoading, setGreensLoading] = useState(false);

  const [roastOpaque, setRoastOpaque] = useState(0.6);
  const [batchOpaque, setBatchOpaque] = useState(0.6);
  const [showRoastAdd, setShowRoastAdd] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [searched, setSearched] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorRoastEl, setAnchorRoastEl] = React.useState(null);

  const openGreenMenu = Boolean(anchorEl);
  const openRoastMenu = Boolean(anchorRoastEl);

  const [currentRoast, setCurrentRoast] = useState({});
  const [currentGreen, setCurrentGreen] = useState("");
  const [currentGreenObj, setCurrentGreenObj] = useState({});

  const [loading, setLoading] = useState(true);
  const [roastLoading, setRoastLoading] = useState(false);

  const [showBatchesContent, setShowBatchesContent] = useState(false);
  const [showAddGreenDialog, setShowAddGreenDialog] = useState(false);
  const [showAddRoastDialog, setShowAddRoastDialog] = useState(false);

  const [showDeleteGreenDialog, setShowDeleteGreenDialog] = useState(false);
  const [showDeleteRoastDialog, setShowDeleteRoastDialog] = useState(false);

  const { sub } = userData;
  currentUser = sub;
  const { roastID } = useParams();

  let navigate = useNavigate();

  const handleGreenMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRoastMenuClick = (event) => {
    setAnchorRoastEl(event.currentTarget);
  };

  const handleRoastMenuClose = () => {
    setAnchorRoastEl(null);
  };

  async function getGreensData() {
    var data = {};
    var greens = [];
    setGreensLoading(true);

    GetGreensForRoaster({ roaster_id: roaster.id, user_id: currentUser }).then(
      (response) => {
        // console.log("server greens", response.data);

        setGreensData(response.data);
        setGreensLoading(false);
      }
    );
  }

  async function greenClickHandler(green) {
    clearData();
    getRoastsData(green.id);
    // console.log(roasts)
    //setRoastsData(roasts)
    setCurrentGreen(green.id);
    setCurrentGreenObj(green);
    setRoastOpaque(1);
    setShowRoastAdd(true);
  }

  function pinnedHandler(green_id) {
    //console.log(currentUser, green_id);
    ToggleGreenPinned({ user_id: currentUser, green_id: green_id });
  }

  async function roastClickHandler(roast) {
    getBatchesData(roast.id);
    // console.log(roast);
    // setBatchesData(batches)
    roast.type = "Roast";
    setCurrentRoast(roast);
    setBatchOpaque(1);
    setShowBatchesContent(true);
  }

  async function getBatchesData(roast_id) {
    var data = {};
    var batches = [];
    var q = query(
      collection(db, "Batches"),
      where("roaster_id", "==", roaster.id),
      where("roast_id", "==", roast_id),
      where("status", "==", "Done")
    );
    //   console.log(currentUser, green_id)
    const subscribe = onSnapshot(q, (querySnapshot) => {
      var batchList = [];
      if (querySnapshot.size == 0) {
        setBatchesData(batchList);
      } else {
        querySnapshot.forEach(async (doc) => {
          //   console.log(doc.data());
          data = doc.data();
          data["id"] = doc.id;

          data.date_roasted = new Date(data.date_roasted.seconds * 1000) + "";
          data.stats.sc_temp = data.stats.sc_temp ? data.stats.sc_temp : {}; // remove this one day, only a placeholder since data doesnt have this tag atm
          batchList.push(data);
          setBatchesData(batchList);
        });
      }
    });
  }

  async function getRoastsData(green_id) {
    setRoastLoading(true);
    GetRoastsForGreen({
      roaster_id: roaster.id,
      green_id: green_id,
      includeReference: true,
    }).then((response) => {
      setRoastsData(response.data);
      setRoastLoading(false);
    });
  }

  async function getAllData() {
    var allBatches = [];
    var allRoasts = [];
    //console.log(greensData);
    greensData.forEach((green) => {
      getRoastsData(green.id).then((val) => {
        val.forEach((roasts) => {
          allRoasts.push(roasts);
          setRoastsData(allRoasts);
        });
      });
    });
    //console.log(allBatches);

    return allBatches;
  }

  function handleAddGreen() {
    clearData();
    showAddGreenDialogHandler();
  }

  function clearData() {
    console.log("cleared");
    setCurrentGreenObj({});
    setCurrentRoast({});
    setRoastsData([]);
    setBatchesData([]);
    setBatchOpaque(0.6);
    setRoastOpaque(0.6);

    setShowRoastAdd(false);
    setShowBatchesContent(false);
  }

  const showAddGreenDialogHandler = () => {
    setShowAddGreenDialog(true);
  };

  const onAddGreenDialogCloseHandler = (newGreenObj) => {
    setShowAddGreenDialog(false);
    //  console.log(newGreenObj);
    //greensData.push(newGreenObj);
    getGreensData();
    clearData();
  };

  const showAddRoastDialogHandler = () => {
    //   clearData()
    setShowAddRoastDialog(true);
  };

  const onAddRoastDialogCloseHandler = (roast) => {
    // console.log("roast added?", roast, roastsData);
    if (roast) {
      var roastIdx = roastsData.findIndex((obj) => obj.id == roast.id);
      console.log(roastIdx);
      if (roastIdx < 0) {
        roastsData.push(roast);
      } else {
        console.log(currentRoast, roast);
        if (currentRoast.id == roast.id) {
          setCurrentRoast(roast);
        }
        roastsData[roastIdx] = roast;
      }
    }
    setShowAddRoastDialog(false);
    //  clearData();
  };

  const handleDeleteGreenClick = () => {
    setShowDeleteGreenDialog(true);
    handleMenuClose();
  };

  const onDeleteGreenDialogCloseHandler = (deletedObj) => {
    if (deletedObj.deleted) {
      var green_id = deletedObj.id;
      console.log(green_id, greensData);
      setGreensData(greensData.filter((obj) => obj.id !== green_id));
      clearData();
      //  getGreensData();
    }

    setShowDeleteGreenDialog(false);
  };

  const handleDeleteRoastClick = () => {
    setShowDeleteRoastDialog(true);
    handleRoastMenuClose();
  };

  const onDeleteRoastDialogCloseHandler = (deleted) => {
    if (deleted) {
      // remove currentRoast.id from roastsdata
      setRoastsData(roastsData.filter((val) => val.id != currentRoast.id));
      setBatchesData([]);
      setBatchOpaque(0.6);
      setShowBatchesContent(false);
    }
    setShowDeleteRoastDialog(false);
  };

  const goToRoast = (roast_id) => {
    GetRoastById(roast_id).then((roast) => {
      roastClickHandler(roast.data);
    });
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);
        //const data = await fetchData();
        const data = await getGreensData();
        if (roastID) {
          goToRoast(roastID);
        }
        //   console.log(data);
        //   console.log(data);
        // switch loading to false after fetch is complete
        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  // return a Spinner when loading is true
  if (greensLoading || loading)
    return (
      <React.Fragment>
        <LoadingLogo message="Loading greens..." />
      </React.Fragment>
    );

  if (greensData.length < 1) {
    return (
      <React.Fragment>
        <Wrapper apiKey="AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc">
          <Container
            maxWidth={false}
            sx={{
              mt: 4,
              mb: 4,
              ml: 15,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Box sx={{ width: "60%" }}>
              <Stack direction="column" spacing={1}>
                <Typography
                  variant="h3"
                  sx={{ color: "#4F21AB", fontWeight: 600 }}
                >
                  Add a new green
                </Typography>
                <Typography variant="h6" sx={{ pb: 2, color: "#243B53" }}>
                  Before we can get going, let's get the building blocks in
                  place.
                </Typography>
                <ActionButton
                  variant="contained"
                  sx={{ width: "150px" }}
                  onClick={handleAddGreen}
                >
                  <AddIcon fontSize="small" sx={{ transform: "scale(0.8)" }} />{" "}
                  Add Green
                </ActionButton>
              </Stack>
            </Box>
          </Container>

          <AddGreenDialog
            open={showAddGreenDialog}
            onClose={onAddGreenDialogCloseHandler}
            userName={currentUser}
            roaster={roaster}
            greenSelected={currentGreenObj}
          />
        </Wrapper>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Wrapper apiKey="AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc">
        <ThemeProvider theme={mdTheme}>
          <Grid container spacing={1} sx={{ height: "100%" }}>
            <Grid item xs={2.5} sx={{ zIndex: 100 }}>
              <Paper
                elevation={1}
                sx={{
                  p: 1,
                  pl: 1.5,
                  display: "flex",
                  flexDirection: "column",
                  height: "92vh",
                  mt: 1,
                }}
              >
                <Grid
                  container
                  alignContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h5" sx={{ pl: 1 }}>
                    Greens
                  </Typography>
                  <IconButton
                    sx={{ ml: "auto", visibility: "hidden" }}
                    id="delete-green-btn"
                    aria-controls={
                      openGreenMenu ? "delete-green-menu" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={openGreenMenu ? "true" : undefined}
                    onClick={handleGreenMenuClick}
                  >
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                  <Divider sx={{ my: 1.5, width: "100%" }} />
                  <Button
                    fullWidth
                    sx={{ fontSize: "11px", p: 0, mt: 1 }}
                    onClick={handleAddGreen}
                  >
                    <AddIcon
                      fontSize="small"
                      sx={{ transform: "scale(0.8)" }}
                    />{" "}
                    New Green
                  </Button>
                  <Box
                    className="scroller"
                    sx={{ height: "75vh", width: "100%", mt: 1 }}
                  >
                    {greensLoading ? (
                      <Stack spacing={1} sx={{ mt: 2 }}>
                        {/* For variant="text", adjust the height via font-size */}
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem" }}
                          width="50%"
                        />
                        {/* For other variants, adjust the size with `width` and `height` */}
                        <Skeleton
                          variant="rectangular"
                          width="90%"
                          height={50}
                        />
                      </Stack>
                    ) : (
                      <GreensSidebar
                        greens={greensData}
                        greenClick={greenClickHandler}
                        pinCallback={pinnedHandler}
                      />
                    )}
                  </Box>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={9.5} className="scroller">
              <Box sx={{ p: 2, pl: 3 }}>
                <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                  {showRoastAdd && (
                    <React.Fragment>
                      <Typography variant="h5">Roasts</Typography>
                      <AddRoastButton
                        size="small"
                        variant="outlined"
                        sx={{ height: 25, ml: 3, fontSize: 12, p: 1.5 }}
                        onClick={() => showAddRoastDialogHandler()}
                      >
                        {showBatchesContent ? (
                          <React.Fragment>
                            <Edit
                              fontSize="small"
                              sx={{ transform: "scale(0.7)" }}
                            />
                            <Typography
                              sx={{ fontSize: 12, fontWeight: 600, ml: 0.5 }}
                            >
                              Edit Roast
                            </Typography>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <AddIcon
                              fontSize="small"
                              sx={{ transform: "scale(0.8)" }}
                            />
                            <Typography sx={{ fontSize: 12, fontWeight: 600 }}>
                              New Roast
                            </Typography>
                          </React.Fragment>
                        )}
                      </AddRoastButton>
                      <IconButton
                        sx={{ ml: "auto" }}
                        id="delete-green-btn"
                        aria-controls={
                          openGreenMenu ? "delete-green-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openGreenMenu ? "true" : undefined}
                        onClick={handleGreenMenuClick}
                      >
                        <MoreVertIcon fontSize="small" />
                      </IconButton>
                    </React.Fragment>
                  )}
                </Box>

                <Box sx={{ pt: 3 }}>
                  {!showBatchesContent &&
                    (roastsData.length > 0 ? (
                      <RoastCardList
                        roasts={roastsData}
                        roastClick={roastClickHandler}
                        userData={userData}
                        roaster={roaster}
                      />
                    ) : roastLoading ? (
                      <Paper
                        sx={{
                          width: "30%",
                          p: 3,
                          opacity: 0.5,
                          borderRadius: "20px",
                        }}
                      >
                        <Stack spacing={1}>
                          {/* For variant="text", adjust the height via font-size */}
                          <Skeleton
                            variant="text"
                            sx={{ fontSize: "1rem" }}
                            width="50%"
                          />
                          {/* For other variants, adjust the size with `width` and `height` */}
                          <Skeleton
                            variant="rectangular"
                            width="90%"
                            height={60}
                          />
                          <Skeleton variant="rounded" width="90%" height={60} />
                        </Stack>
                      </Paper>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "70vh",
                        }}
                      >
                        <Stack
                          spacing={3}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              width: "150px",
                              height: "150px",
                              background: `url(${CoffeePlant}) no-repeat`,
                              backgroundSize: "cover",
                            }}
                          ></Box>
                          <Typography color="text.secondary">
                            {currentGreen == ""
                              ? "Select or create a green"
                              : "Add a new roast above"}
                          </Typography>
                        </Stack>
                      </Box>
                    ))}
                  {showBatchesContent && (
                    <RoastDetails
                      batches={batchesData}
                      roast={currentRoast}
                      userData={userData}
                      roaster={roaster}
                    />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>

          <AddGreenDialog
            open={showAddGreenDialog}
            onClose={onAddGreenDialogCloseHandler}
            userName={currentUser}
            roaster={roaster}
            greenSelected={currentGreenObj}
          />
          <AddRoastDialog
            open={showAddRoastDialog}
            onClose={onAddRoastDialogCloseHandler}
            userName={currentUser}
            greenId={currentGreen}
            roaster={roaster}
            roastSelected={currentRoast}
          />

          <DeleteGreenDialog
            open={showDeleteGreenDialog}
            onClose={onDeleteGreenDialogCloseHandler}
            roaster={roaster}
            green={currentGreen}
          />

          <DeleteRoastDialog
            open={showDeleteRoastDialog}
            onClose={onDeleteRoastDialogCloseHandler}
            roaster={roaster}
            roast={currentRoast.id}
          />

          <CustomMenu
            id="delete-green-menu"
            anchorEl={anchorEl}
            open={openGreenMenu}
            onClose={handleMenuClose}
            MenuListProps={{
              "aria-labelledby": "delete-green-btn",
            }}
          >
            <CustomMenuItem
              onClick={() => {
                showAddGreenDialogHandler();
                handleMenuClose();
              }}
            >
              Edit green
            </CustomMenuItem>
            <CustomMenuItem onClick={handleDeleteGreenClick}>
              Delete green
            </CustomMenuItem>
            <CustomMenuItem
              disabled={!showBatchesContent}
              sx={{ mt: 1.5 }}
              onClick={() => {
                showAddRoastDialogHandler();
                handleMenuClose();
              }}
            >
              Edit roast
            </CustomMenuItem>
            <CustomMenuItem
              disabled={!showBatchesContent}
              onClick={handleDeleteRoastClick}
            >
              Delete roast
            </CustomMenuItem>
          </CustomMenu>

          <Menu
            id="delete-roast-menu"
            anchorEl={anchorRoastEl}
            open={openRoastMenu}
            onClose={handleRoastMenuClose}
            MenuListProps={{
              "aria-labelledby": "delete-roast-btn",
            }}
          >
            <MenuItem
              sx={{ fontSize: 11, p: 1.4, pt: 0, pb: 0 }}
              onClick={() => {
                showAddRoastDialogHandler();
                handleRoastMenuClose();
              }}
            >
              Edit roast
            </MenuItem>
            <MenuItem
              sx={{ fontSize: 11, p: 1.4, pt: 0, pb: 0 }}
              onClick={handleDeleteRoastClick}
            >
              Delete roast
            </MenuItem>
          </Menu>
        </ThemeProvider>
      </Wrapper>
    </React.Fragment>
  );
}
