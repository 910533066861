import React, { useState, useEffect } from "react";
import Chip from "@mui/material/Chip";
import {
  mapRoastNotesToColors,
  mapDegreeToColor,
  getContrastyTextColor,
} from "../../main/helpers/TasteNoteMapper";

export default function NotesChip({ label, sx }) {
  //var bgColor = mapRoastNotesToColors(label);
  var bgColor = "#5f27cd";
  //var textColor = getContrastyTextColor(bgColor);
  var textColor = "#ffffffee";

  return (
    <React.Fragment>
      <Chip
        label={label}
        size="medium"
        color="primary"
        style={{
          backgroundColor: bgColor,
          color: textColor,
          fontWeight: 600,
          letterSpacing: "1px",
        }}
        sx={sx}
      />
    </React.Fragment>
  );
}
