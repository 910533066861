import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Alert from "@mui/material/Alert";
import {
  sortArrayBy,
  isLikelyForC,
  convertSecondsToMinutesLabel,
} from "../helpers/utility";
import QueueBatchButton from "../components/QueueBatchButton";
import BatchesTable from "../components/BatchesTable";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Badge from "../components/Badge";
import Tooltip from "@mui/material/Tooltip";
import TempLabel from "../components/TempLabel";

import UploadProfile from "../components/UploadProfile";
import { RoastProfile } from "../Roasting/helpers/RoastProfile";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BatchScatter from "../components/BatchScatter";
import NotesChip from "../../consumer/components/NotesChip";

import { getFunctions, httpsCallable } from "firebase/functions";
import { Add, RepeatSharp } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
const functions = getFunctions();
const SaveRoastNotes = httpsCallable(functions, "SaveRoastNotes");

var qrRoastID = "";

var currentProfile = new RoastProfile({});

const AddRoastButton = styled(Button)(({ theme }) => ({
  color: "rgba(104,10,239,0.8)",
  border: "1px solid rgba(87, 101, 116,0.3)",
  fontWeight: 600,
  borderRadius: "3px",
  fontSize: "12px",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,0.1)",
    border: "1px solid rgba(104,10,239,0.8)",
  },
}));

const IconRoastButton = styled(IconButton)(({ theme }) => ({
  color: "rgba(104,10,239,0.8)",
  border: "1px solid rgba(87, 101, 116,0.2)",
  fontWeight: 600,
  fontSize: "12px",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,0.1)",
    border: "1px solid rgba(104,10,239,0.8)",
  },
}));

export default function RoastDetails({ batches, roast, userData, roaster }) {
  const [haveBatches, setHaveBatches] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = React.useState(false);

  const [batchID, setBatchID] = React.useState("");
  const convertToC = userData.default_temp == "C";

  const newNoteText = roast.notes;

  const [notesText, setNotesText] = React.useState(newNoteText);
  const [oldNotesText, setOldNotesText] = React.useState(newNoteText);
  const [editNotes, setEditNotes] = React.useState(false);

  const RTTotal = batches.reduce((accumulator, batch) => {
    return accumulator + batch.stats.drop_temp.time;
  }, 0);
  const RTAvg = Math.round(RTTotal / batches.length);
  const RTLabel = isNaN(RTAvg) ? "-" : convertSecondsToMinutesLabel(RTAvg);

  const CTTotal = batches.reduce((accumulator, batch) => {
    return accumulator + batch.stats.charge_temp;
  }, 0);
  const CTAvg = Math.round(CTTotal / batches.length);
  const CTLabel = isNaN(CTAvg) ? "-" : CTAvg + " °" + isLikelyForC(CTAvg);

  const DTTotal = batches.reduce((accumulator, batch) => {
    return accumulator + batch.stats.drop_temp.temp;
  }, 0);
  const DTAvg = Math.round(DTTotal / batches.length);
  const DTLabel = isNaN(DTAvg) ? "-" : DTAvg + " °" + isLikelyForC(DTAvg);

  function toggleUploadProfile() {
    setUploadDialogOpen(!uploadDialogOpen);
  }

  const handleClick = (batch) => {
    console.log(batch);
  };

  const handleNotesChange = (event) => {
    setNotesText(event.target.value);
  };

  function thankYouSnack() {
    setSnackOpen(true);
  }

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const editNotesHandler = () => {
    setEditNotes(true);
  };

  function saveNotesHandler() {
    SaveRoastNotes({ roast_id: roast.id, note_text: notesText });
    thankYouSnack();
    setEditNotes(false);
  }

  function discardNotesHandler() {
    setNotesText(oldNotesText);
    setEditNotes(false);
  }
  /*
  async function saveNotesHandler() {
    const ref = doc(db, "Roasts", roast.id);

    thankYouSnack();

    await updateDoc(ref, {
      notes: notesText
    });

  }
*/
  useEffect(() => {
    if (editNotes) {
      setOldNotesText(notesText);
    }
  }, [editNotes]);

  return (
    <React.Fragment>
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "white",
          padding: 3,
          boxShadow: "0px 1px 3px rgba(0,0,0,.2)",
        }}
      >
        <Typography variant="h3" display="inline">
          {roast.name}{" "}
        </Typography>
        <AddRoastButton
          sx={{ mb: 1, ml: 2 }}
          variant="outlined"
          size="small"
          onClick={toggleUploadProfile}
        >
          <Add
            fontSize="small"
            sx={{ color: "#5f27cd", transform: "scale(0.8)", mr: 0.5 }}
          />
          Upload .alog
        </AddRoastButton>
        <QueueBatchButton
          roast={roast}
          userData={userData}
          roaster={roaster}
          sx={{ mb: 1, ml: 2 }}
        />

        <Typography variant="subtitle2" sx={{ fontSize: 17, mt: 1 }}>
          {roast.description}
        </Typography>
        <Grid container spacing={1.5} sx={{ alignItems: "baseline", mt: 1 }}>
          <Grid item xs={1.5}>
            <Typography variant="body2" color="text.secondary">
              Flavors
            </Typography>
          </Grid>
          <Grid item xs={10.5}>
            {roast.taste_notes.map((note, idx) => (
              <NotesChip
                key={idx}
                label={note}
                sx={{ m: 0.25, fontSize: 10, height: "23px" }}
              />
            ))}
          </Grid>
          <Grid item xs={1.5}>
            <Typography variant="body2" color="text.secondary">
              Degree
            </Typography>
          </Grid>
          <Grid item xs={10.5}>
            <Typography variant="body1">
              {roast.roast_degree ? roast.roast_degree : " - "}
            </Typography>
          </Grid>

          <Grid item xs={1.5}>
            <Typography variant="body2" color="text.secondary">
              Best After
            </Typography>
          </Grid>

          <Grid item xs={10.5}>
            <Typography variant="body1">
              {roast.max_suggested_date_offset ||
              roast.min_suggested_date_offset
                ? roast.min_suggested_date_offset
                : " - "}
              &nbsp;to&nbsp;
              {roast.max_suggested_date_offset
                ? roast.max_suggested_date_offset
                : " - "}
              &nbsp;days
            </Typography>
          </Grid>

          <Grid item xs={1.5}>
            <Typography variant="body2" color="text.secondary">
              Brew Tips
            </Typography>
          </Grid>

          <Grid item xs={10.5}>
            <Typography variant="body1">
              {roast.brew_tips ? roast.brew_tips : " - "}
            </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Typography variant="body2" color="text.secondary">
              My Notes
            </Typography>
          </Grid>
          <Grid item xs={10.5}>
            {editNotes ? (
              <Box>
                <TextField
                  id="notes_field"
                  label=""
                  variant="outlined"
                  size="small"
                  value={notesText}
                  onChange={handleNotesChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title="Discard Changes" placement="top">
                          <HighlightOffIcon
                            onClick={discardNotesHandler}
                            sx={{ cursor: "pointer" }}
                          />
                        </Tooltip>
                        <Tooltip title="Save" placement="top">
                          <CheckCircleOutlineIcon
                            onClick={saveNotesHandler}
                            sx={{ cursor: "pointer", ml: 1 }}
                          />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            ) : (
              <Box
                onClick={editNotesHandler}
                className="clickable"
                sx={{ borderRadius: "6px" }}
              >
                {notesText ? (
                  <React.Fragment>
                    <Typography variant="body1">{notesText}</Typography>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography
                      variant="body1"
                      color="text.secondary"
                      sx={{ fontStyle: "italic", opacity: 0.5 }}
                    >
                      Click to add notes
                    </Typography>
                  </React.Fragment>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
        <Divider width="100%" sx={{ mt: 3 }} />
        <Typography variant="h5">Batches</Typography>

        <Box sx={{ mt: 3, mb: 5 }}>
          <Grid container spacing={4}>
            <Grid item xs={3}>
              <Badge data={batches.length} label={"Total Batches"} />
            </Grid>
            <Grid item xs={3}>
              <Badge data={RTLabel} label={"Avg. Roast Time"} />
            </Grid>
            <Grid item xs={3}>
              <Badge
                data={
                  <TempLabel sx={{ fontSize: 25 }} convertToC={convertToC}>
                    {CTAvg}
                  </TempLabel>
                }
                label={"Avg. Charge Temp"}
              />
            </Grid>
            <Grid item xs={3}>
              <Badge
                data={
                  <TempLabel sx={{ fontSize: 25 }} convertToC={convertToC}>
                    {DTAvg}
                  </TempLabel>
                }
                label={"Avg. Drop Temp"}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ width: "100%", height: "50vh", mt: 2 }}>
          <BatchScatter batches={batches} convertToC={convertToC} />
        </Box>
        <Box sx={{ width: "100%", mt: 2 }}>
          <BatchesTable
            batches={batches}
            reference_batch_id={roast.reference_batch_id}
            user={userData}
          />
        </Box>
      </Box>

      <Dialog
        onClose={toggleUploadProfile}
        open={uploadDialogOpen}
        maxWidth="md"
      >
        <DialogTitle sx={{ fontWeight: 500 }}>
          Upload Artisan Profile
        </DialogTitle>
        <DialogContent>
          <UploadProfile
            roaster={roaster}
            userName={userData.sub}
            batchOrRoast={roast}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleUploadProfile}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Notes saved
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
