import React, { useState, useEffect } from "react";
import {
  Fab,
  Menu,
  MenuList,
  MenuItem,
  Grid,
  Typography,
  Box,
  Divider,
} from "@mui/material/";
import { styled, alpha, useTheme } from "@mui/material/styles";
import HistoryIcon from "@mui/icons-material/History";
import LocalCafeIcon from "@mui/icons-material/LocalCafe";

const fabStyle = {
  position: "fixed",
  bottom: 16,
  left: 16,
  borderRadius: 16,
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 10,
    minWidth: 180,
    color: theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": { paddingTop: 0, paddingBottom: 0 },
    "& .MuiMenuItem-root": {
      color: theme.palette.primary.main,
      fontSize: 12,
      marginTop: 0,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function HistoryRow({ name, roaster, date }) {
  return (
    <React.Fragment>
      <Grid container sx={{ fontSize: 11, pt: 0.25, pb: 0.25 }}>
        <Grid item xs={12} sx={{ fontWeight: 700 }}>
          {name}
        </Grid>
        <Grid item xs={7} sx={{ fontWeight: 300 }}>
          {roaster}
        </Grid>
        <Grid item xs={5} sx={{ fontWeight: 300 }}>
          {date}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default function History({ history_data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const user_history = history_data ? history_data : [];
  var theme = useTheme();

  var url = "https://hasteroastery.io/discover/";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /*
  user_history.sort(function (a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(b.date_roasted) - new Date(a.date_roasted);
  });
*/
  return (
    <React.Fragment>
      <Fab
        color="primary"
        size="large"
        aria-label="history"
        variant="extended"
        onClick={handleClick}
        aria-controls={open ? "history-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        style={fabStyle}
      >
        <LocalCafeIcon />
        &nbsp;Roasts
      </Fab>
      <StyledMenu
        id="history-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "history",
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            p: 0.75,
            pl: 2,
            zIndex: 2,
            position: "sticky",
            top: 0,

            border: "solid 1px " + theme.palette.primary.main,
          }}
        >
          <Typography
            sx={{
              fontSize: 13,
              fontWeight: 600,
            }}
          >
            My Roasts
          </Typography>
        </Box>
        <Box>
          <MenuList dense sx={{ maxHeight: 125 }}>
            {user_history.map((item, idx) => (
              <React.Fragment key={idx + "frag"}>
                <MenuItem
                  onClick={() => window.location.replace(url + item.batchID)}
                  key={idx}
                >
                  <HistoryRow
                    name={item.roast_name}
                    roaster={item.roaster}
                    date={item.date_roasted}
                  />
                </MenuItem>
                <Divider
                  variant="middle"
                  sx={{
                    opacity: 0.1,
                    borderColor: theme.palette.primary.main,
                  }}
                />
              </React.Fragment>
            ))}
          </MenuList>
        </Box>
      </StyledMenu>
    </React.Fragment>
  );
}
