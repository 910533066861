import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const steps = [
  {
    label: "Add a new green",
    description: `Head over to the Roasts activity! From here, you can add a new green in the left sidebar by clicking "New Green".`,
  },
  {
    label: "Create a new roast",
    description: `Now that you have a green coffee, select it from the left sidebar and create a new roast using the "New Roast" button.`,
  },
  {
    label: "Roast a batch",
    description: `Great! Now you are ready to roast. Queue it up using the "Add to Queue" button. Click Roast from Copilot on the right. From here you can either upload an Artisan profile, or use the manual roasting features.`,
  },
  {
    label: "View your new batch micro site",
    description: `Now that you've got your coffee roasted, you are ready to share it. Copilot shows you your recent roasts in the bottom right. Click the QR icon to view your new microsite to print/share with the world.`,
  },
  {
    label: "Browse more greens",
    description: `We've curated the latest and greatest from a range of green coffee sources. Check out the Market to browse available greens.`,
  },
];

const ActionButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "20px",
  fontSize: "11px",
  backgroundColor: "rgba(104,10,239,0.8)",
  "&:hover": {
    backgroundColor: "rgba(136, 84, 208,1.0)",
  },
}));

const BackButton = styled(Button)(({ theme }) => ({
  borderRadius: "20px",
  fontSize: "11px",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,0.1)",
  },
}));

export default function TutorialStepper({ sx, step = 0, stepChanged }) {
  const [activeStep, setActiveStep] = React.useState(step);

  const handleNext = () => {
    stepChanged(activeStep + 1);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    stepChanged(activeStep - 1);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    stepChanged(0);
    setActiveStep(0);
  };

  return (
    <Box
      sx={{
        ...sx,
        maxWidth: 400,
        backgroundColor: "white",
        p: 2,
        borderRadius: "10px",
        m: 1,
      }}
    >
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step
            key={step.label}
            sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: "rgba(9, 132, 227,1.0)", // circle color (COMPLETED)
              },
              "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: "grey.500", // Just text label (COMPLETED)
                },
              "& .MuiStepLabel-root .Mui-active": {
                color: "rgba(104,10,239,1)", // circle color (ACTIVE)
              },
              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: "common.white", // Just text label (ACTIVE)
                },
              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                fill: "white", // circle's number (ACTIVE)
              },
            }}
          >
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
              sx={{
                fontWeight: 600,
              }}
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography color="text.secondary" sx={{ fontSize: 12 }}>
                {step.description}
              </Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <ActionButton
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === steps.length - 1 ? "Finish" : "Continue"}
                  </ActionButton>
                  <BackButton
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </BackButton>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - great work!</Typography>
          <ActionButton
            onClick={handleReset}
            sx={{ mt: 1, mr: 1 }}
            variant="contained"
          >
            Restart
          </ActionButton>
        </Paper>
      )}
    </Box>
  );
}
