import { Typography } from "@mui/material";
import * as React from "react";
import { convertFtoC, roundNumberTo } from "../helpers/utility";

//server always returns in F. so based on ForC, convert appropriately.

export default function TempLabel({ children, sx, convertToC }) {
  var text = children;
  var labelText = 0;
  var decorator = "°F";
  //console.log(text);
  if (text) {
    if (!isNaN(text)) {
      // input is a number
      if (convertToC) {
        labelText = convertFtoC(text);
        decorator = "°C";
      } else {
        labelText = text;
      }
    } else {
      // input is not a number
      throw new Error("Input is not a number: ", text);
    }

    labelText = roundNumberTo(labelText, 1);

    return (
      <React.Fragment>
        <Typography sx={sx}>
          {labelText}&nbsp;{decorator}
        </Typography>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <Typography sx={sx}>-</Typography>
    </React.Fragment>
  );
}
