import React, { PureComponent } from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import { isLikelyForC, convertFtoC } from "../helpers/utility";

export default class BatchScatter extends PureComponent {
  getDataArray() {
    var batches = this.props.batches;
    var convertToC = this.props.convertToC;
    var dataList = { tp: [], fct: [], dt: [], ct: [], ForC: convertToC };

    batches.forEach((batch, index) => {
      if (convertToC) {
        batch.stats.charge_temp = convertFtoC(batch.stats.charge_temp);
        batch.stats.tp_temp.temp = convertFtoC(batch.stats.tp_temp.temp);
        batch.stats.fc_temp.temp = convertFtoC(batch.stats.fc_temp.temp);
        batch.stats.drop_temp.temp = convertFtoC(batch.stats.drop_temp.temp);
      }
      dataList.ct.push({ x: 0, y: Math.round(batch.stats.charge_temp) }); //turning point temp and time
      dataList.tp.push({
        x: batch.stats.tp_temp.time,
        y: Math.round(batch.stats.tp_temp.temp),
      }); //turning point temp and time
      dataList.fct.push({
        x: batch.stats.fc_temp.time,
        y: Math.round(batch.stats.fc_temp.temp),
      }); //fc temp and time
      dataList.dt.push({
        x: batch.stats.drop_temp.time,
        y: Math.round(batch.stats.drop_temp.temp),
      }); //drop temp and time

      //  data.push({"time": timeItem, "bt": Math.round((btSeries[index] + Number.EPSILON) * 100) / 100, "ror": Math.round((rorSeries[index] + Number.EPSILON) * 100) / 100});
    });

    return dataList;
  }

  render() {
    var dataObj = this.getDataArray();
    ////console.log(dataObj);
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          width={400}
          height={400}
          margin={{
            top: 5,
            bottom: -10,
            left: -20,
          }}
        >
          <CartesianGrid
            strokeDasharray=""
            vertical={false}
            stroke="rgba(236, 240, 241,1.0)"
          />
          <XAxis
            type="number"
            dataKey="x"
            name="Roast Time"
            unit="s"
            axisLine={false}
            tickLine={false}
            style={{ fontSize: "0.6rem" }}
          />
          <YAxis
            type="number"
            dataKey="y"
            name="Temp."
            unit={dataObj.ForC ? " °C" : " °F"}
            allowDataOverflow={true}
            axisLine={false}
            tickLine={false}
            allowDecimals={false}
            style={{ fontSize: "0.6rem" }}
            domain={this.props.convertToC ? [80, 240] : [150, 450]}
          />
          <ZAxis hide={false} />
          <Tooltip
            cursor={{ strokeDasharray: "3 3" }}
            wrapperStyle={{ fontSize: "11px" }}
          />
          <Scatter name="Charge" data={dataObj.ct} fill="#d35400" />
          <Scatter name="Turning" data={dataObj.tp} fill="#8e44ad" />
          <Scatter name="First Crack" data={dataObj.fct} fill="#2980b9" />
          <Scatter name="Drop" data={dataObj.dt} fill="#27ae60" />
          <Legend
            align="right"
            layout="horizontal"
            width="30vh"
            verticalAlign="bottom"
            iconSize="5"
            wrapperStyle={{ fontSize: "10px", bottom: 35, right: 0 }}
          />
        </ScatterChart>
      </ResponsiveContainer>
    );
  }
}
