import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Button from '@mui/material/Button';

import CircularLoader from '../CircularLoader'


import { getApp } from "firebase/app";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";



//const functions = getFunctions();
var timeout;
var timeoutList = [] 
  var counter = 0;



export default function Timer({timeCallback, onReset, onStart, onStop, startDisabled = false}) {

  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState([]);

  const [timerGo, setTimerGo] = useState(false);
  const [clockTime, setClockTime] = useState(0); // in seconds
  const [clockText, setClockText] = useState("0:00")
  const [forceStop, setForceStop] = useState(false)

  var min = ""
  var sec = ""
  var interval = 1000; // ms
  var expected = Date.now() + interval;

 
  const startHandler = () => {
      clearAllTimeouts();
    if(timerGo) { // stop timer!
      console.log("Stop!")
      console.log(timeout)
      timeCallback({status:"Stopped", sec_elapsed: counter})
      onStop();
    } else { // start timer!
      console.log("Start!")
      expected = Date.now() + interval;
      timeout = setTimeout(step, interval);
      timeoutList.push(timeout)
      onStart();
    }
    setTimerGo(!timerGo)
  }

  function step() {
      var dt = Date.now() - expected; // the drift (positive for overshooting)
      if (dt > interval) {
          // something really bad happened. Maybe the browser (tab) was inactive?
          // possibly special handling to avoid futile "catch up" run
      }
      counter++;
      setClockTime(counter)
      timeCallback({status:"Running", sec_elapsed: counter})

      expected += interval;
     // console.log("Expected", expected)
      timeout = setTimeout(step, Math.max(0, interval - dt)); // take into account drift
      timeoutList.push(timeout)
  }

  function resetHandler() {
  	  if(timerGo) {
      	startHandler()
      }
	  setClockTime(0)
      counter = 0
      onReset();
      
  }

  function componentWillUnmount() {
    console.log("unmount")
    counter = 0;
    clearAllTimeouts()
  }

  function clearAllTimeouts() {
    for (var i=0; i<timeoutList.length; i++) {
      clearTimeout(timeoutList[i]);
    }
  }


 
  useEffect(() => {
    const queryAsync = async () => {
      componentWillUnmount()
    };
     
    queryAsync();
  }, []);

 

  useEffect(() => {
    const queryAsync = async () => {
      if(clockTime > 0) {
          min = clockTime >= 60 ? (parseInt(clockTime / 60)) + "" : min = "0"
          sec = clockTime % 60 < 10 ? "0" + (clockTime % 60) : (clockTime % 60) + "";
      } else {
        min = "0"
        sec = "00"
      }
  //    console.log(clockTime)
      setClockText(min + ":" + sec)
    };
     
    queryAsync();
  }, [clockTime]);



  // return a Spinner when loading is true
  if(loading) return (
    <React.Fragment>
    <CircularLoader />
    </React.Fragment>
  );

  // data will be null when fetch call fails
  if (error) return (
    <React.Fragment>
          
    </React.Fragment>
  );


  return (
    <React.Fragment>
      <Grid container spacing={0} width="22vh">
        <Grid item xs={12} sx={{backgroundColor:"rgba(217, 172, 224,0.3)", borderRadius:"15px", padding:"12px", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column"}}>
          <Typography sx={{fontSize:29, fontWeight:600}}>
          {clockText}
            
          </Typography>
          <Box width="100%" >
              {!timerGo && <LinearProgress color="secondary" variant="determinate" value={0} sx={{opacity:"0.9", borderRadius:"10px"}}/> }
              {timerGo && <LinearProgress color="secondary" sx={{opacity:"0.9", borderRadius:"10px"}} /> }
            </Box>
          
        </Grid>
        <Grid item xs={12} sx={{display:"flex", flexDirection:"row",  alignItems:"center", justifyContent:"center"}}>
          <Button size="small" sx={{borderRadius:"10px", fontSize:"0.7rem", fontWeight:500}} fullWidth onClick = {resetHandler}> Reset</Button>
          <Button size="small" disabled={startDisabled} sx={{borderRadius:"10px", fontSize:"0.7rem", fontWeight:600}} fullWidth onClick = {startHandler}> {timerGo ? "Stop" : "Start"} </Button>
          
        </Grid>
      </Grid>
    </React.Fragment>
    )
}
