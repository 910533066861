import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../Title";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import "../styles/dialogs.css";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import TextField from "@mui/material/TextField";

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  updateMetadata,
  listAll,
  getMetadata,
  getDownloadURL,
} from "firebase/storage";

import { useHistory, useParams, useNavigate } from "react-router-dom";

import CircularLoader from "../CircularLoader";

import RoastProfileFileParser from "../helpers/RoastProfileFileParser";
import { RoastProfileJsonParser } from "../Roasting/helpers/RoastProfileJsonParser";

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

const functions = getFunctions();
const UploadBatchProfileToStorage = httpsCallable(
  functions,
  "UploadBatchProfileToStorage"
);
const CreateNewBatch = httpsCallable(functions, "CreateNewBatch");
const DeleteBatchById = httpsCallable(functions, "DeleteBatchById");
const QueueNewBatch = httpsCallable(functions, "QueueNewBatch");

const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const storage = getStorage(app);

var roastBatch;

const ActionButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "20px",
  fontSize: "13px",
  height: "35px",
  backgroundColor: "#9c27b0",
  "&:hover": {
    backgroundColor: "rgba(9, 132, 227,1)",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(165, 177, 194,0.3)",
  },
}));

const UploadButton = styled(ActionButton)(({ theme }) => ({
  backgroundColor: "rgba(104,10,239,0.7)",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,0.8)",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(165, 177, 194,0.3)",
  },
}));

export default function UploadProfile(props) {
  const { onClose, open, batchOrRoast, userName, roaster } = props;
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [dialogError, showDialogError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const theme = useTheme();

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleClose = (newBatch) => {
    //  console.log(profile)
    showDialogError(false);
    setSelectedFile(null);
    setIsFilePicked(false);
    setSnackOpen(true);
    //  onClose(newBatch);
  };

  const handleFileInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
    //   console.log(selectedFile)
    //   console.log(isValidFile(selectedFile))
    //handleInputChange(event);
    setIsFilePicked(true);
  };

  async function addNewBatch(batchObj) {
    return;
  }

  const getExtension = (filename) => {
    return filename.split(".").pop();
  };

  const isValidFile = (file) => {
    //    console.log("validate", file)
    if (!file) return false;

    var ext = getExtension(file.name);

    if (ext != "alog") {
      return false;
    }

    return true;
  };

  const handleRoast = () => {
    console.log("Roast!");
    console.log({ roaster: roaster, user: userName, roast: batchOrRoast });
    return QueueNewBatch({
      roaster: roaster.id,
      user: userName,
      roast: batchOrRoast,
    }).then((resp) => {
      roastBatch = resp.data;
      return roastBatch;
    });
  };

  const handleRoastOrBatch = () => {
    if (batchOrRoast.type == "Roast") {
      handleRoast().then((data) => handleAddNewRoast(data));
    } else {
      handleAddNewRoast(batchOrRoast);
    }
  };

  const handleAddNewRoast = (batch) => {
    const fileRef = ref(
      storage,
      "roasts/" + userName + "/" + batch.green_id + "/" + batch.id + ".json"
    );

    // 'file' comes from the Blob or File API
    if (selectedFile && isValidFile(selectedFile)) {
      uploadBytes(fileRef, selectedFile).then((snapshot) => {
        //  console.log('Uploaded a blob or file!', selectedFile);
        var parser = new RoastProfileFileParser(selectedFile);
        parser.getJSON().then((res) => {
          var fileJson = res.json;
          var jsonParser = new RoastProfileJsonParser(fileJson);
          getDownloadURL(fileRef).then((url) => {
            var newBatch = {
              batch_num: Math.floor(Math.random() * 999999999),
              date_roasted: new Date(),
              green_id: batch.green_id,
              profile_id: batch.id,
              profile_url: url,
              roast_id: batch.roast_id,
              mode: jsonParser.getMode(),
              stats: {
                charge_temp: jsonParser.getChargeBT(),
                tp_temp: jsonParser.getTPBT(),
                drop_temp: jsonParser.getDropBT(),
                fc_temp: jsonParser.getFCBT(),
                sc_temp: jsonParser.getSCBT(),
              },
              status: "Done",
              user_id: userName,
              consumer_likes: 0,
              roaster_id: roaster.id,
            };
            CreateNewBatch(newBatch);
            DeleteBatchById(batch.id);
            handleClose();
          });
        });
      });
    } else {
      showDialogError(true);
    }
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);
        //const data = await fetchData();
        //    const data = await getUserSettings(userName);
        //   console.log(data);
        // switch loading to false after fetch is complete
        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        width: "80vh",
        border: "2px dashed rgba(104,10,239,1)",
        borderRadius: "10px",
        backgroundColor: "rgba(104,10,239,0.05)",
        p: 5,
      }}
    >
      <ActionButton variant="contained" component="label">
        Select Profile
        <input
          type="file"
          id="file"
          name="file"
          hidden
          onChange={handleFileInputChange}
        />
      </ActionButton>
      <Typography variant="subtitle2" sx={{ mt: 2, opacity: "0.7" }}>
        Only Artisan files(.alog) are supported for file upload.
      </Typography>

      {selectedFile && (
        <Typography variant="body2" sx={{ mt: 1, justifyContent: "center" }}>
          File Selected: {selectedFile.name}
        </Typography>
      )}
      {dialogError && (
        <Alert severity="error" sx={{ mt: 2, mb: 0 }}>
          Invalid or no profile selected. Please make sure you are trying to
          upload an .alog file.
        </Alert>
      )}
      <UploadButton
        onClick={handleRoastOrBatch}
        variant={selectedFile ? "contained" : "outlined"}
        disabled={!selectedFile}
        size="medium"
        sx={{ mt: 4 }}
      >
        Upload
      </UploadButton>

      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Batch saved!
        </Alert>
      </Snackbar>
    </Box>
  );
}
