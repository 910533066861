export class RoastProfile { // assumes artisan profile file

	constructor(user, name, desc, greenID, batchSize, date, roastID, downloadURL, metadata) {
	 	this.roast_friendly_name = name;
	 	this.description = desc;
        this.user_id = user;
        this.green_id = greenID;
        this.batch_size = batchSize;
        this.date_added = date;	
        this.roast_id = roastID;
        this.download_roast_file_url = downloadURL;
        this.metadata = metadata;

	}

	getTimeSeries() {
//		return this.json['timex'];

	}

}