import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import CircularLoader from "../CircularLoader";

import { getApp } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

const functions = getFunctions();
//connectFunctionsEmulator(functions, "localhost", 5001);

//const functions = getFunctions();

export default function Feedback({}) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState([]);
  const [thumbsCount, setThumbsCount] = useState({});

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);

        const getData = httpsCallable(functions, "AdminGetRecentFeedback");
        getData().then((response) => {
          setData(response.data.results);
          console.log(response.data.results);
        });

        const getThumbs = httpsCallable(functions, "AdminGetThumbsUp");
        getThumbs().then((response) => {
          setThumbsCount(response.data.results);
          console.log(response.data.results);
        });
        //   console.log(data);
        // switch loading to false after fetch is complete
        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        setError(true);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  // return a Spinner when loading is true
  if (loading)
    return (
      <React.Fragment>
        <CircularLoader />
      </React.Fragment>
    );

  // data will be null when fetch call fails
  if (error)
    return (
      <React.Fragment>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={7}>
            <Typography sx={{ fontWeight: 600, color: "#5f27cd" }}>
              Whoops, looks like something went wrong. Sorry!
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <Typography
        variant="subititle1"
        sx={{ fontSize: "18px", color: "#5f27cd", fontWeight: 500 }}
      >
        Feedback
      </Typography>

      <Paper
        sx={{
          p: 2,
        }}
      >
        Past 30 Days
        {data.length > 0 && (
          <Grid container spacing={1} sx={{ mt: 0 }}>
            {data.map((newItem, idx) => (
              <Grid key={idx} item xs={12} sx={{ ml: 2 }}>
                <Typography sx={{ fontSize: 12 }} color="text.primary">
                  <strong>{newItem.user.name}</strong>
                </Typography>
                <Typography sx={{ fontSize: 11 }} color="text.primary">
                  {newItem.feedback}
                </Typography>
                <Typography sx={{ fontSize: 11 }} color="text.secondary">
                  {new Date(
                    newItem.date_added._seconds * 1000
                  ).toLocaleString()}
                </Typography>

                <Divider sx={{ width: "100%", mt: 1 }} />
              </Grid>
            ))}
          </Grid>
        )}
        {data.length <= 0 && (
          <Typography sx={{ fontSize: 12, mt: 2 }} color="text.secondary">
            No feedback to show
          </Typography>
        )}
      </Paper>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: 120,
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          mt: 2,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography component="p" variant="h4">
              {thumbsCount.count}
            </Typography>
            <Typography color="text.secondary" sx={{ flex: 1 }}>
              Total Thumbs Up
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
