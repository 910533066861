import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import PageviewIcon from "@mui/icons-material/Pageview";
import { initializeApp } from "firebase/app";
import HomeIcon from "@mui/icons-material/Home";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/material/styles";

import {
  getFirestore,
  collection,
  getDoc,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  onSnapshot,
  orderBy,
  limit,
  updateDoc,
  FieldValue,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();
const incrementLikes = httpsCallable(functions, "IncrementLikes");
const saveFeedback = httpsCallable(functions, "SaveFeedback");

function preventDefault(event) {
  event.preventDefault();
}
const ActionButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "20px",
  fontSize: "13px",
  backgroundColor: "rgba(104,10,239,0.8)",
  "&:hover": {
    backgroundColor: "rgba(136, 84, 208,1.0)",
  },
}));

const FEEDBACK_MAX_LENGTH = 300;

export default function Feedback({ userData }) {
  const [loading, setLoading] = useState(true);
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [liked, setLiked] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);

  var state = {
    feedback: feedback,
    date_added: new Date(),
    user: userData,
  };

  async function handleSubmit() {
    saveFeedback({ feedback, userData });

    setFeedback("");
    setFeedbackSent(true);
    thankYouSnack();
  }

  const handleInputChange = (event) => {
    setFeedback(event.target.value);
  };

  const popopen = Boolean(anchorEl);
  const popid = popopen ? "simple-popover" : undefined;

  function likeHandler() {
    setLiked(!liked);
  }

  function thankYouSnack() {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (liked) {
      thankYouSnack();
      incrementLikes();
    }
  }, [liked]);

  return (
    <React.Fragment>
      <Container maxWidth="sm" sx={{ mt: 0, mb: 0 }}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "90vh" }}
        >
          <Grid item xs={2}>
            <Paper
              sx={{
                p: 5,
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
              elevation={3}
            >
              <Title>
                Give Feedback
                <IconButton
                  size=""
                  sx={{ float: "right", color: "rgba(95, 39, 205,1.0)" }}
                  onClick={() => likeHandler()}
                >
                  {liked ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}
                </IconButton>
              </Title>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>
                    We appreciate any and all feedback. Whether it's good or an
                    area to improve - we want to know. Or just give us a thumbs
                    up to let us know you are having a good time!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="feedback_details"
                    label="Enter your feedback here"
                    multiline
                    fullWidth
                    rows={5}
                    onChange={handleInputChange}
                    value={feedback}
                    inputProps={{
                      maxLength: FEEDBACK_MAX_LENGTH,
                      className: "scroller",
                    }}
                    helperText={`${
                      FEEDBACK_MAX_LENGTH - feedback.length
                    } characters remaining`}
                  />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1 }}>
                  <ActionButton
                    variant="contained"
                    sx={{ float: "right" }}
                    onClick={() => handleSubmit()}
                    endIcon={<SendIcon />}
                    disabled={feedback.length < 1}
                  >
                    Send
                  </ActionButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={open}
          onClose={handleClose}
          message="Thank you for the feedback!"
          key={"bottom" + "center"}
          autoHideDuration={2000}
          sx={{}}
          ContentProps={{
            sx: {
              display: "block",
              textAlign: "center",
              backgroundColor: "rgba(95, 39, 205,0.8)",
              fontSize: "17px",
            },
          }}
        />
      </Container>
    </React.Fragment>
  );
}
