export class RoastProfileJsonParser {
  // assumes artisan profile file

  constructor(json) {
    this.json = json;
  }

  getMode() {
    return this.json["mode"] ? this.json["mode"] : "F";
  }

  getTimeSeries() {
    return this.json["timex"];
  }

  getETSeries() {
    return this.json["temp1"];
  }

  getBTSeries() {
    return this.json["temp2"];
  }

  getChargeBT() {
    if (this.json.hasOwnProperty("computed"))
      return this.json["computed"]["CHARGE_BT"];
    return 0;
  }

  getTPBT() {
    if (this.json.hasOwnProperty("computed") && this.json["computed"]["TP_BT"])
      return {
        temp: this.json["computed"]["TP_BT"],
        time: this.json["computed"]["TP_time"],
      };
    return {};
  }

  getDropBT() {
    if (
      this.json.hasOwnProperty("computed") &&
      this.json["computed"]["DROP_BT"]
    )
      return {
        temp: this.json["computed"]["DROP_BT"],
        time: this.json["computed"]["DROP_time"],
      };
    return {};
  }

  getFCBT() {
    if (this.json.hasOwnProperty("computed") && this.json["computed"]["FCs_BT"])
      return {
        temp: this.json["computed"]["FCs_BT"],
        time: this.json["computed"]["FCs_time"],
      };
    return {};
  }
  getSCBT() {
    if (this.json.hasOwnProperty("computed") && this.json["computed"]["SCs_BT"])
      return {
        temp: this.json["computed"]["SCs_BT"],
        time: this.json["computed"]["SCs_time"],
      };
    return {};
  }

  getReadingsInAMinute() {
    var timeArray = this.getTimeSeries();
    var i = 0;
    while (timeArray[i] && timeArray[i] < 60) {
      i += 1;
    }

    return i;
  }

  getRORSeries() {
    var bt_series = this.getBTSeries();
    var scalar = this.getReadingsInAMinute();
    var ror_series = [];
    var last_temp = 0;
    var computedVal = 0;
    var diff = 0;
    var last_ror = 0;

    bt_series.forEach((bt_temp) => {
      if (last_temp == 0) {
        last_temp = bt_temp;
      } else {
        diff = bt_temp - last_temp;
        computedVal = diff * scalar; // pointer is last. so current - last
        if (typeof computedVal == "number") ror_series.push(computedVal);
        last_temp = bt_temp;
        last_ror = computedVal;
      }
    });

    return ror_series;
  }

  getDerivative(series) {
    var scalar = this.getReadingsInAMinute();
    var ror_series = [];
    var last_temp = 0;
    var computedVal = 0;
    var diff = 0;
    var last_ror = 0;

    series.forEach((bt_temp) => {
      if (last_temp == 0) {
        last_temp = bt_temp;
      } else {
        diff = bt_temp - last_temp;
        computedVal = diff * scalar; // pointer is last. so current - last
        if (typeof computedVal == "number") ror_series.push(computedVal);
        last_temp = bt_temp;
        last_ror = computedVal;
      }
    });

    return ror_series;
  }

  getForC() {
    return this.json["mode"];
  }
}
