import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import Title from "../Title";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
} from "firebase/firestore";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import QueueBatchButton from "../components/QueueBatchButton";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";

import {
  mapRoastNotesToColors,
  mapDegreeToColor,
} from "../helpers/TasteNoteMapper";

const JumpChip = styled(Chip)(({ theme }) => ({
  fontSize: 10,
  display: "flex",
  fontWeight: 500,
  height: 20,
  backgroundColor: "rgba(95, 39, 205, 1.0)",
  color: "white",
}));

export default function ShopItem({ item }) {
  const LINES_TO_SHOW = 2;
  // console.log("shopitem", item)

  const multiLineEllipsis = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical",
  };

  const handleInvClick = (item) => {
    // console.log(item);
    window.open(item.main_url, "_blank", "noopener,noreferrer");
  };

  // {item.taste_notes.map((note, i) => (
  //                   <Chip key={"Chip"+i} label={note} size="small" color="primary" sx={{opacity:"0.7", backgroundColor:"#3498db"}} />
  //                 ))}

  var chiplabel = "$" + item.cost.toFixed(2);
  if (item.taste_notes && item.taste_notes[0] == "") item.taste_notes = [];

  var copyItem = item;
  var noteLength = copyItem.taste_notes.length;
  var remaining = 0;
  var cutoff = 3;
  if (noteLength > cutoff) {
    remaining = noteLength - cutoff;
    copyItem.taste_notes.length = cutoff + 1;
    copyItem.taste_notes[cutoff] = remaining + " more..";
  }

  /*
<Stack
              direction="row"
              sx={{ pt: 0.5, pb: 0.5, flexWrap: "wrap", gap: 0.25 }}
            >
              {copyItem.taste_notes.map((note, i) => (
                <NotesChip
                  key={"Chip" + i}
                  label={note}
                  size="small"
                  color="primary"
                  sx={{
                    backgroundColor: "#6c5ce7",
                  }}
                  style={{ backgroundColor: mapRoastNotesToColors(note) }}
                />
              ))}
            </Stack>
*/

  return (
    <React.Fragment>
      <Card
        key={item.id}
        sx={{
          width: 230,
          borderRadius: "8px !important",
          cursor: "pointer",
          border: "solid 1px rgba(108, 92, 231,0.1)",
          height: 290,
        }}
        onClick={() => handleInvClick(item)}
        elevation={0}
      >
        <CardActionArea
          sx={{ display: "relative", height: "100%" }}
          className="showHiddenHovers"
        >
          <JumpChip
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1000,
              cursor: "pointer",
            }}
            label={"Go to " + item.vendor}
            className="hideUntilHover"
          />
          <CardMedia
            component="img"
            height="170"
            image={item.image_url}
            alt=""
            sx={{ opacity: ".9", mt: 0 }}
          />
          <CardContent sx={{ p: 2, pb: 1, overflow: "hidden" }}>
            <Stack sx={{ justifyContent: "space-between" }}>
              <Typography
                sx={{
                  fontSize: 13,
                  mt: 0.2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
                color="text.primary"
              >
                {item.name}
              </Typography>

              <Typography sx={{ fontSize: 13, mt: 0.5 }} color="text.secondary">
                {item.vendor}
              </Typography>

              <Typography
                sx={{ fontSize: 17, mt: 0.5, fontWeight: 600 }}
                color="text.secondary"
              >
                {chiplabel}
              </Typography>
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </React.Fragment>
  );
}
