import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import HomeIcon from "@mui/icons-material/Home";
import InventoryIcon from "@mui/icons-material/Inventory";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import PageviewIcon from "@mui/icons-material/Pageview";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import Zoom from "@mui/material/Zoom";
import Typography from "@mui/material/Typography";
import QuestionAnswerSharpIcon from "@mui/icons-material/QuestionAnswerSharp";
import BoltIcon from "@mui/icons-material/Bolt";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import GroupIcon from "@mui/icons-material/Group";
import PetsIcon from "@mui/icons-material/Pets";
import SvgIcon from "@mui/material/SvgIcon";
import StoreIcon from "@mui/icons-material/Store";

import Divider from "@mui/material/Divider";
const linkStyle = {
  textDecoration: "none",
  color: "#222f3e",
};

const linkBtnStyle = {
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  backgroundColor: "inherit",
  borderRadius: "19px",
  margin: "2px",
  marginTop: 10,
};

const linkiconStyle = {
  alignItems: "center",
  justifyContent: "center",
  height: "25px",
};

const iconStyle = {
  color: "#5f27cd",
  fontSize: "1.3rem",
};

const textStyle = {
  fontSize: "0.6rem",
  color: "rgba(0,0,0,0.7)",
  letterSpacing: "0.3px",
};

function BeanIcon(props) {
  return (
    <SvgIcon viewBox="-50 -100 650 650" {...props}>
      <path
        class="cls-1"
        d="M20.25,404.81c-.87,.05-1.73,.15-2.58,.28-48.99-101.79,6.54-246.18,133.99-335.48C277.85-18.82,430.75-22.54,510.39,55.86c-60.45,19.68-115.14,49.54-166.75,91-48.17,38.7-87.71,82.75-122.59,121.61-65.96,73.49-118.06,131.54-200.79,136.35ZM544.04,102.79c-1.1,.49-2.23,.91-3.41,1.26-59.31,17.44-112.48,45.46-162.55,85.68-44.68,35.89-80.99,76.35-116.1,115.47-61.37,68.38-119.7,133.36-206.97,150.53,80.56,74.89,230.85,70.01,355.21-17.14,127.58-89.4,183.09-233.99,133.82-335.81Z"
      />
    </SvgIcon>
  );
}

function RoasterIcon(props) {
  return (
    <SvgIcon viewBox="-2 -80 850 770" {...props}>
      <g id="Layer_10" data-name="Layer 10">
        <path
          class="cls-1"
          d="M471.06,93.08h-114.67c-1.51,0-2.94-.71-3.84-1.93-16.07-21.54-32.14-43.07-48.22-64.61-8.18-10.96-.36-26.55,13.31-26.55h192.18c13.66,0,21.48,15.58,13.31,26.54-16.08,21.54-32.15,43.08-48.23,64.62-.9,1.21-2.33,1.93-3.84,1.93Z"
        />
        <g>
          <rect
            class="cls-1"
            x="581.37"
            y="511.15"
            width="244.63"
            height="130.23"
            rx="52.99"
            ry="52.99"
          />
          <path
            class="cls-1"
            d="M779.31,537.65c8.74,0,15.85,6.11,15.85,13.62v49.99c0,7.51-7.11,13.62-15.85,13.62h-151.25c-8.74,0-15.85-6.11-15.85-13.62v-49.99c0-7.51,7.11-13.62,15.85-13.62h151.25m0-26.5h-151.25c-25.79,0-46.69,17.96-46.69,40.12v49.99c0,22.16,20.9,40.12,46.69,40.12h151.25c25.79,0,46.69-17.96,46.69-40.12v-49.99c0-22.16-20.9-40.12-46.69-40.12h0Z"
          />
        </g>
        <path
          class="cls-1"
          d="M118.01,305.43h254.58c63.35,0,114.79,51.43,114.79,114.79v208.77c0,5.57-4.52,10.09-10.09,10.09H10.09c-5.57,0-10.09-4.52-10.09-10.09v-205.55c0-65.13,52.88-118.01,118.01-118.01Z"
        />
        <path
          class="cls-1"
          d="M445.93,226.92c9.61,4.06,18.76,8.94,27.41,14.58V92.44h-119.56v120.73h24.11c23.57,0,46.46,4.62,68.04,13.74Z"
        />
      </g>
    </SvgIcon>
  );
}

export const mainListItems = (
  <React.Fragment>
    <NavLink
      to="/roastery/"
      style={linkStyle}
      className={({ isActive }) => (isActive ? "is-active-nav" : "inactive")}
    >
      <ListItemButton style={linkBtnStyle}>
        <ListItemIcon style={linkiconStyle}>
          <HomeIcon style={iconStyle} />
        </ListItemIcon>
        <Typography style={textStyle}> Home </Typography>
      </ListItemButton>
    </NavLink>
    <NavLink
      to="/roastery/roasts"
      style={linkStyle}
      className={({ isActive }) => (isActive ? "is-active-nav" : "inactive")}
    >
      <ListItemButton style={linkBtnStyle}>
        <ListItemIcon style={linkiconStyle}>
          <BeanIcon style={iconStyle} />
        </ListItemIcon>
        <Typography style={textStyle}> Roasts </Typography>
      </ListItemButton>
    </NavLink>
    <NavLink
      to="/roastery/copilot"
      style={linkStyle}
      className={({ isActive }) => (isActive ? "is-active-nav" : "inactive")}
    >
      <ListItemButton style={linkBtnStyle}>
        <ListItemIcon style={linkiconStyle}>
          <WhatshotIcon style={iconStyle} />
        </ListItemIcon>
        <Typography style={textStyle}> Copilot </Typography>
      </ListItemButton>
    </NavLink>
    <NavLink
      to="/roastery/reach"
      style={linkStyle}
      className={({ isActive }) => (isActive ? "is-active-nav" : "inactive")}
    >
      <ListItemButton style={linkBtnStyle}>
        <ListItemIcon style={linkiconStyle}>
          <RoasterIcon style={iconStyle} />
        </ListItemIcon>
        <Typography style={textStyle}> Roastery </Typography>
      </ListItemButton>
    </NavLink>
    <NavLink
      to="/roastery/finder"
      style={linkStyle}
      className={({ isActive }) => (isActive ? "is-active-nav" : "inactive")}
    >
      <ListItemButton style={linkBtnStyle}>
        <ListItemIcon style={linkiconStyle}>
          <StoreIcon style={iconStyle} />
        </ListItemIcon>
        <Typography style={textStyle}> Market </Typography>
      </ListItemButton>
    </NavLink>
  </React.Fragment>
);
/*
 <Link to="/roastery/admin/shop" style={linkStyle}>
    <ListItemButton sx={{
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection:'column'}} >
      <ListItemIcon sx={{
              alignItems: 'center',
              justifyContent: 'center',
              height:"25px"}}>
        <PageviewIcon sx={{ color: '#5f27cd', fontSize:"1.3rem"}}/>
      </ListItemIcon>
       <Typography sx={{fontSize:"0.6rem", color:"rgba(0,0,0,0.6)"}}> Admin Shop </Typography>
    </ListItemButton>
    </Link>
*/
export const secondaryListItems = (user_id) => {
  return (
    <React.Fragment>
      <ListSubheader component="div" inset></ListSubheader>
      <NavLink
        to="/roastery/feedback"
        style={linkStyle}
        className={({ isActive }) => (isActive ? "is-active-nav" : "inactive")}
      >
        <ListItemButton style={linkBtnStyle}>
          <ListItemIcon style={linkiconStyle}>
            <QuestionAnswerSharpIcon style={iconStyle} />
          </ListItemIcon>
          <Typography style={textStyle}> Feedback </Typography>
        </ListItemButton>
      </NavLink>
      {user_id == "oPVZlYcn00TJOjjUopQMeZjvHIy1" && (
        <NavLink
          to="/roastery/admin"
          style={linkStyle}
          className={({ isActive }) =>
            isActive ? "is-active-nav" : "inactive"
          }
        >
          <ListItemButton style={linkBtnStyle}>
            <ListItemIcon style={linkiconStyle}>
              <PetsIcon style={iconStyle} />
            </ListItemIcon>
            <Typography style={textStyle}> Admin </Typography>
          </ListItemButton>
        </NavLink>
      )}
    </React.Fragment>
  );
};
