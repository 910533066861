import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import { Link, NavLink } from "react-router-dom";

import CircularLoader from "../CircularLoader";
import { sortArrayByDate } from "../helpers/utility";
import CircularProgress from "@mui/material/CircularProgress";
import ReplyIcon from "@mui/icons-material/Reply";

import { getApp } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

const functions = getFunctions();
//connectFunctionsEmulator(functions, "localhost", 5001);

//const functions = getFunctions();

const textStyle = {
  fontSize: "0.8rem",
  color: "rgb(95, 39, 205)",
  letterSpacing: "0.3px",
};
const linkStyle = {
  textDecoration: "none",
  color: "#222f3e",
  display: "inline-block",
  float: "right",
  marginTop: "5px",
};

const jumpIconStyle = {
  transform: "scale(0.6) scaleX(-1) rotate(20deg)",
  verticalAlign: "middle",
  marginLeft: "-7px",
  marginTop: "-5px",
  opacity: "0.7",
};

export default function CosumerFeedback({ roaster_id }) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);

        const getData = httpsCallable(functions, "GetRoasterFeedback");
        getData({ roaster_id }).then((response) => {
          setData(sortArrayByDate(response.data.results, "date_added", false));
          //console.log(data);
          //  //console.log(response.data.results);
          setLoading(false);
        });

        //   //console.log(data);
        // switch loading to false after fetch is complete
      } catch (error) {
        // add error handling here
        setLoading(false);
        setError(true);
        //console.log(error);
      }
    };

    queryAsync();
  }, []);

  // return a Spinner when loading is true
  if (loading)
    return (
      <React.Fragment>
        <Paper
          sx={{
            p: 2,
          }}
        >
          <Typography>Your feedback</Typography>
          <Typography color="text.secondary" sx={{ fontSize: 12 }}>
            Past 30 days
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "150px",
            }}
          >
            <CircularProgress />
          </Box>
        </Paper>
      </React.Fragment>
    );

  // data will be null when fetch call fails
  if (error)
    return (
      <React.Fragment>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={7}>
            <Typography sx={{ fontWeight: 600, color: "#5f27cd" }}>
              Whoops, looks like something went wrong. Sorry!
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <Paper
        sx={{
          p: 2,
        }}
      >
        <Typography>
          Your feedback
          <NavLink to="/roastery/reach" style={linkStyle}>
            <Typography style={textStyle}>
              {" "}
              View your engagement <ReplyIcon style={jumpIconStyle} />{" "}
            </Typography>
          </NavLink>
        </Typography>
        <Typography color="text.secondary" sx={{ fontSize: 12 }}>
          Past 30 days
        </Typography>
        {data.length > 0 && (
          <Grid
            container
            className="scroller"
            spacing={1}
            sx={{ mt: 0, maxHeight: "200px" }}
          >
            {data.map((newItem, idx) => (
              <Grid key={idx} item xs={12} sx={{ ml: 2 }}>
                <Typography sx={{ fontSize: 11 }} color="text.secondary">
                  {new Date(
                    newItem.date_added._seconds * 1000
                  ).toLocaleString()}
                </Typography>
                <Typography sx={{ fontSize: 11 }} color="text.primary">
                  {newItem.feedback}
                </Typography>
                <Typography sx={{ fontSize: 11 }} color="text.secondary">
                  {newItem.roast_name ? newItem.roast_name + " | " : ""}
                  {newItem.batch_timestamp
                    ? "Roasted on: " +
                      new Date(
                        newItem.batch_timestamp._seconds * 1000
                      ).toLocaleString() +
                      ""
                    : ""}
                </Typography>

                <Divider sx={{ width: "100%", mt: 1 }} />
              </Grid>
            ))}
          </Grid>
        )}
        {data.length <= 0 && (
          <Typography sx={{ fontSize: 12, mt: 2 }} color="text.secondary">
            No feedback to show
          </Typography>
        )}
      </Paper>
    </React.Fragment>
  );
}
