import React, { PureComponent, Component } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  ReferenceDot,
} from "recharts";
import { isLikelyForC, isEmptyObj } from "../helpers/utility";
import CircularLoader from "../CircularLoader";
import { sortArrayBy } from "../helpers/utility";
import { default as MUITooltip } from "@mui/material/Tooltip";
import { DuplexStreamStreamer } from "papaparse";
import { Box } from "@mui/material";

const defaultMinTemp = 150;
const defaultMaxTemp = 450;
const defaultMaxTime = 960; // 12 mins

export default class CopilotLineGraph extends Component {
  //RoastProfileJsonParser param

  state = {};

  getTimelessDots(minTemp, maxTemp, maxTime) {
    // {time:x, bt:y}
    var data = [];
    for (var i = 0; i <= maxTime; i += 15) {
      for (var j = minTemp; j <= maxTemp; j += 10) {
        // break;
        data.push({ time: i, bt: j });
      }
    }
    return data;
  }

  onMouseDotEnter = (data, index) => {
    console.log(data, index);
    this.setState({ activeIndex: index, activeDot: data });
    this.props.handleDotHover(data);
  };

  render() {
    var data = this.props.data;
    var eventLines = this.props.eventLines;
    var timelessMode = this.props.timelessMode;
    var dotHandler = this.props.dotHandler;
    var handleDotHover = this.props.handleDotHover;
    var referenceDotHover = this.props.referenceDotHover;
    var handleDotHoverLeave = this.props.handleDotHoverLeave;
    var handleEventMouse = this.props.handleEventMouse;

    var timelessDots = [];

    var { activeDot } = this.state;

    // console.log(data)
    if (isEmptyObj(data))
      return (
        <React.Fragment>
          <CircularLoader height="50vh" />
        </React.Fragment>
      );

    var rorArray = data.map((obj) => {
      if (obj.ror) return obj.ror;
      else return -10;
    });

    const maxRoR = Math.max(...rorArray);
    data = sortArrayBy(data, ["time"]);

    var timeArray = data.map((obj) => {
      if (obj.time) return obj.time;
      else return -10;
    });

    const maxTime = Math.max(...timeArray);
    const timeBoundMax =
      maxTime > defaultMaxTime - 120 ? maxTime + 120 : defaultMaxTime;

    var tempArray = data.map((obj) => {
      if (obj.bt) return obj.bt;
      else return -10;
    });

    if (timelessMode) {
      timelessDots = this.getTimelessDots(
        defaultMinTemp,
        defaultMaxTemp,
        timeBoundMax
      );
    }

    return (
      <React.Fragment>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={700}
            height={400}
            data={data}
            margin={{
              top: 15,
              right: 5,
              left: 10,
              bottom: 5,
            }}
            key={Math.random()}
            onMouseOut={handleDotHoverLeave}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              xAxisId="0"
              dataKey="time"
              type="number"
              domain={[0, timeBoundMax]}
              label={{
                value: "Roast Time",
                position: "insideBottom",
                fontSize: "0.7rem",
              }}
              dy={-5}
              axisLine={false}
              unit="s"
              tickLine={false}
              tick={true}
              allowDecimals={false}
              style={{ fontSize: "0.7rem" }}
            />
            <YAxis
              yAxisId="left"
              type="number"
              domain={[defaultMinTemp, defaultMaxTemp]}
              unit={" °" + "F"}
              allowDecimals={false}
              width={39}
              tick={{ fill: "#8884d8" }}
              style={{ fontSize: "0.7rem" }}
            />
            <Line
              yAxisId="left"
              type="monotoneX"
              name="Bean Temp."
              dataKey="bt"
              isAnimationActive={false}
              stroke="#8884d8"
              strokeWidth={3}
              dot={true}
            />
            {eventLines.map((line, i) => (
              <ReferenceLine
                key={"rl" + i}
                x={line.time}
                label={{
                  position: "top",
                  value: line.label,
                  fill: line.color,
                  fontSize: 12,
                  fontWeight: 600,
                }}
                stroke={line.color}
                strokeDasharray="3 4"
                yAxisId="left"
                onMouseOver={handleEventMouse}
                isFront
              />
            ))}
            {timelessDots.map((dots, i) => (
              <ReferenceDot
                key={"rd" + i}
                x={dots.time}
                y={dots.bt}
                cursor="pointer"
                r={
                  activeDot &&
                  activeDot.x == dots.time &&
                  activeDot.y == dots.bt
                    ? 8
                    : 4.5
                }
                fill="rgba(162, 155, 254,0.4)"
                strokeWidth="1"
                stroke="rgba(162, 155, 254,0.8)"
                onMouseDown={(dots) => dotHandler(dots)}
                onMouseEnter={this.onMouseDotEnter}
                yAxisId="left"
                opacity={
                  activeDot &&
                  activeDot.x == dots.time &&
                  activeDot.y == dots.bt
                    ? "1"
                    : "0.4"
                }
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </React.Fragment>
    );
  }
}
