import React, { useState, useRef } from "react";
import { useQRCode } from "next-qrcode";
import logo from "./whitebg.png";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function RoastQRCode({
  url,
  color = "#341f97",
  showLogo = false,
}) {
  const { Canvas } = useQRCode();
  const [correction, setCorrection] = useState("M");

  const qrRef = useRef(null);

  const width = 260;
  const logoWidth = width * 0.25;

  const TestQRCode = ({ value, moduleShape }) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = 200;
    canvas.height = 200;

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "black";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = "20px sans-serif";

    ctx.fillRect(20, 10, 10, 10);

    return <img src={canvas.toDataURL()} alt={value} />;
  };

  return (
    <React.Fragment>
      <Canvas
        text={url}
        logo={{
          src: showLogo ? logo : null,
          options: {
            width: logoWidth,
          },
        }}
        options={{
          level: correction,
          margin: 2,
          scale: 4,
          width: width,
          color: {
            dark: color,
            light: "#FFFFFF",
          },
        }}
      />
    </React.Fragment>
  );
}
