import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link, NavLink } from "react-router-dom";

import CircularLoader from "../CircularLoader";
import ReplyIcon from "@mui/icons-material/Reply";

import ShopItemMini from "./ShopItemMini";

import { getApp } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

const functions = getFunctions();

export default function RelevantShopComponent({}) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState([]);

  const textStyle = {
    fontSize: "0.8rem",
    color: "rgb(95, 39, 205)",
    letterSpacing: "0.3px",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#222f3e",
    display: "inline-block",
    float: "right",
    marginTop: "5px",
  };

  const jumpIconStyle = {
    transform: "scale(0.6) scaleX(-1) rotate(20deg)",
    verticalAlign: "middle",
    marginLeft: "-7px",
    marginTop: "-5px",
    opacity: "0.7",
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);

        const getData = httpsCallable(functions, "GetRecentPopularShopItems");
        getData().then((response) => {
          setData(response.data.results);
        });
        //   console.log(data);
        // switch loading to false after fetch is complete
        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        setError(true);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  // return a Spinner when loading is true
  if (loading)
    return (
      <React.Fragment>
        <CircularLoader />
      </React.Fragment>
    );

  // data will be null when fetch call fails
  if (error)
    return (
      <React.Fragment>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={7}>
            <Typography sx={{ fontWeight: 600, color: "#5f27cd" }}>
              Whoops, looks like something went wrong. Sorry!
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <Paper sx={{ p: 2 }} elevation={2}>
        <Typography variant="subtitle1">
          What's Roasting in the Community
          <NavLink to="/roastery/finder" style={linkStyle}>
            <Typography style={textStyle}>
              {" "}
              Explore more greens <ReplyIcon style={jumpIconStyle} />{" "}
            </Typography>
          </NavLink>
        </Typography>
        <Grid container spacing={2} sx={{ mt: 0.1 }}>
          {data.map((item, i) => (
            <Grid item key={i} md={12} lg={6}>
              <ShopItemMini item={item} />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
