import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import SortIcon from "@mui/icons-material/Sort";
import CircularProgress from "@mui/material/CircularProgress";
import Papa from "papaparse";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Feedback from "./components/Feedback";

const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

function preventDefault(event) {
  event.preventDefault();
}

export default function Admin({ userData }) {
  const [loading, setLoading] = useState(true);

  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [uploadWorking, setUploadWorking] = useState(false);

  const handleFileInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
    console.log(selectedFile);
    //handleInputChange(event);
    setIsFilePicked(true);
  };

  async function addNewShopItem(shopItem) {
    const ref = doc(collection(db, "Greens Shop"));
    // console.log(roastProfile);
    await setDoc(ref, shopItem);
    return ref;
  }

  const handleAddNewShopItems = () => {
    setUploadWorking(true);

    Papa.parse(selectedFile, {
      complete: function (results) {
        var notesToAdd = [];
        console.log(results.data.length);
        var data = results.data;
        var item = {};
        var notes = [];
        var cleanCost = "";
        for (var i = 1; i < data.length - 1; i++) {
          if (data[i][2]) cleanCost = data[i][2].replace("$", "");
          if (data[i][4]) notes = data[i][4].split("|");
          notes.map((val) => {
            notesToAdd.push(val);
          });
          item = {
            main_url: data[i][0],
            name: data[i][1],
            cost: parseFloat(parseFloat(cleanCost).toFixed(2)),
            image_url: data[i][3],
            taste_notes: notes,
            vendor: data[i][5],
          };
          addNewShopItem(item);
          console.log(item);
        }
        setUploadWorking(false);
      },
    });
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);
        //const data = await fetchData();
        //  const data = await getGreensShopData();
        //  console.log(data)

        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ mt: 5, mb: 4, height: "85%" }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Typography
              variant="subititle1"
              sx={{ fontSize: "18px", color: "#5f27cd", fontWeight: 500 }}
            >
              Shop
            </Typography>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "row",
                height: 120,
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              Update Shop Items
              <Button variant="contained" component="label" sx={{ ml: 4 }}>
                Select File
                <input
                  type="file"
                  id="file"
                  name="file"
                  hidden
                  onChange={handleFileInputChange}
                />
              </Button>
              <Button
                variant="outlined"
                sx={{ ml: 2 }}
                onClick={() => handleAddNewShopItems()}
              >
                Go!
              </Button>
              {uploadWorking && <CircularProgress size="1rem" sx={{ ml: 2 }} />}
            </Paper>
          </Grid>
          <Grid item xs={7}>
            <Feedback />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
