import ReactDOM from "react-dom/client";
import React, { useState, useEffect, useRef } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import ProfileLineGraph from "./pages/main/Roasting/ProfileLineGraph";
import LoadingLogo from "./pages/main/components/LoadingLogo";

import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Snackbar from "@mui/material/Snackbar";
import Popover from "@mui/material/Popover";
import SvgIcon from "@mui/material/SvgIcon";

import WhatshotIcon from "@mui/icons-material/Whatshot";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  getDoc,
  updateDoc,
} from "firebase/firestore";

import { initializeApp } from "firebase/app";
import {
  mapRoastNotesToColors,
  mapDegreeToColor,
} from "./pages/main/helpers/TasteNoteMapper";
import { isEmptyObj } from "./pages/main/helpers/utility";

import "./ConsumerApp.css";

const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const functions = getFunctions();
//connectFunctionsEmulator(functions, "localhost", 5001);

const getProfileData = httpsCallable(functions, "GetBatchProfileData");
var currentBatchID = "";

function preventDefault(event) {
  event.preventDefault();
}

async function getData(batchId) {
  var roast = {};
  var batch = {};
  var green = {};

  // console.log(batchId)

  const batchSnapshot = await getDoc(doc(db, "Batches", batchId));
  if (batchSnapshot.exists()) {
    batch = batchSnapshot.data();
  } else return {};
  //  console.log(batch)

  const roastSnapshot = await getDoc(doc(db, "Roasts", batch.roast_id));
  if (roastSnapshot.exists()) {
    roast = roastSnapshot.data();
  }

  const greenSnapshot = await getDoc(doc(db, "Greens", batch.green_id));
  if (greenSnapshot.exists()) {
    green = greenSnapshot.data();
  }

  return { batch: batch, roast: roast, green: green };
}

function GreenMap(props) {
  const ref = useRef();
  const center = props.center;
  const zoom = props.zoom;

  useEffect(() => {
    new window.google.maps.Map(ref.current, {
      center,
      zoom,
      disableDefaultUI: true,
      zoomControl: true,
    });
  });

  return (
    <div
      ref={ref}
      id="map"
      style={{
        width: "100%",
        height: "90%",
        borderRadius: "8px",
        marginTop: "10px",
      }}
    />
  );
}

export default function ConsumerSummary() {
  const [data, setData] = useState({
    green: {},
    roast: { taste_notes: [] },
    batch: { date_roasted: new Date() },
  });
  const [loading, setLoading] = useState(true);
  const [currentProfile, setProfile] = useState(null);
  const [liked, setLiked] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [bestMinDate, setBestMinDate] = useState(new Date());
  const [bestMaxDate, setBestMaxDate] = useState(new Date());
  const [lineData, setLineData] = useState({});
  const [isManual, setIsManual] = useState(false);

  const [eventLines, setEventLines] = useState([{}]); //{time:1, label:"test", color:"rgba(95, 39, 205,1)"}

  const { batchID } = useParams();
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const zoom = 9;

  currentBatchID = batchID;

  const [anchorEl, setAnchorEl] = React.useState(null);

  function getProfileCurveData() {
    //  connectFunctionsEmulator(functions, "localhost", 5001);

    getProfileData({ batch_id: currentBatchID, smooth: false }).then(
      (response) => {
        console.log(response);
        setIsManual(response.data.isManual);
        setLineData(response.data.profile_data);
        setEventLines(response.data.event_data);
      }
    );
  }

  const infoHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const infoClose = () => {
    setAnchorEl(null);
  };

  const popopen = Boolean(anchorEl);
  const popid = popopen ? "simple-popover" : undefined;

  function likeHandler() {
    setLiked(!liked);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const dateOffsetCalc = (date, offset) => {
    // console.log(offset)
    if (isNaN(offset)) offset = 0;

    date.setDate(date.getDate() + offset);

    return date;
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        window.location.replace(
          "https://haste-roastery.web.app/happy/" + currentBatchID
        );

        // set loading to true before calling API
        setLoading(true);

        document.title = "haste Coffee Roastery";

        const data = await getData(currentBatchID);
        if (isEmptyObj(data)) throw new Error();

        setData(data);
        //  console.log(data)
        setCenter({
          lat: data.green.origin.location.latitude,
          lng: data.green.origin.location.longitude,
        });
        var dateoffsetMin = dateOffsetCalc(
          new Date(data.batch.date_roasted.seconds * 1000),
          data.roast.min_suggested_date_offset
        );
        var dateoffsetMax = dateOffsetCalc(
          new Date(data.batch.date_roasted.seconds * 1000),
          data.roast.max_suggested_date_offset
        );
        setBestMinDate(dateoffsetMin);
        setBestMaxDate(dateoffsetMax);

        // switch loading to false after fetch is complete
        const roastref = doc(db, "Roasts", data.batch.roast_id);
        updateDoc(roastref, {
          total_consumer_views: data.roast.total_consumer_views + 1,
        });

        const viewsref = doc(
          db,
          "Roasters/" + data.batch.roaster_id + "/Stats",
          "Consumer"
        );

        getDoc(viewsref).then((e) => {
          if (e.exists()) {
            var stats = e.data();

            var objIdx = stats.views_ot.findIndex((obj) => {
              return (
                new Date(obj.date.seconds * 1000).toLocaleDateString() ==
                new Date().toLocaleDateString()
              );
            });
            //    console.log("found idx", objIdx)
            if (objIdx >= 0) {
              stats.views_ot[objIdx].views += 1;
            } else {
              stats.views_ot.push({ date: new Date(), views: 1 });
            }

            updateDoc(viewsref, {
              views_ot: stats.views_ot,
            });
          }
        });

        getProfileCurveData();

        setLoading(false);
      } catch (error) {
        // add error handling here
        setError(true);
        setLoading(false);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  useEffect(() => {
    if (liked) {
      setOpen(true);
      const batchref = doc(db, "Batches", currentBatchID);
      // Set the "capital" field of the city 'DC'
      updateDoc(batchref, {
        consumer_likes: data.batch.consumer_likes + 1,
      });

      const roastref = doc(db, "Roasts", data.batch.roast_id);
      updateDoc(roastref, {
        total_consumer_likes: data.roast.total_consumer_likes + 1,
      });

      const likesref = doc(
        db,
        "Roasters/" + data.batch.roaster_id + "/Stats",
        "Consumer"
      );

      getDoc(likesref).then((e) => {
        if (e.exists()) {
          var stats = e.data();

          var objIdx = stats.likes_ot.findIndex((obj) => {
            return (
              new Date(obj.date.seconds * 1000).toLocaleDateString() ==
              new Date().toLocaleDateString()
            );
          });
          //    console.log("found idx", objIdx)
          if (objIdx >= 0) {
            stats.likes_ot[objIdx].likes += 1;
          } else {
            stats.likes_ot.push({ date: new Date(), likes: 1 });
          }

          updateDoc(likesref, {
            likes_ot: stats.likes_ot,
          });
        }
      });
    }
  }, [liked]);

  // return a Spinner when loading is true
  if (loading)
    return (
      <React.Fragment>
        <LoadingLogo message="Loading roast..." />
      </React.Fragment>
    );

  // data will be null when fetch call fails
  if (error)
    return (
      <React.Fragment>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={7}>
            <Typography sx={{ fontWeight: 600, color: "#5f27cd" }}>
              Whoops, looks like your batch can't be found. Sorry!
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );

  const maprender = (status) => {
    if (status === Status.FAILURE)
      return (
        <React.Fragment>
          <span>ERROR LOADING MAP</span>
        </React.Fragment>
      );
    return <LoadingLogo message="Loading map..." />;
  };

  function LogoIcon(props) {
    return (
      <SvgIcon viewBox="0 0 980 980" {...props}>
        {/*  <path  d="M172.98,0H40.7C18.22,0,0,18.22,0,40.7V172.98c0,22.48,18.22,40.7,40.7,40.7H172.98c22.48,0,40.7-18.22,40.7-40.7V40.7c0-22.48-18.22-40.7-40.7-40.7Zm9.72,182.97c-1.56,1.24-3.43,1.85-5.28,1.85-2.5,0-4.98-1.1-6.66-3.21L101.82,94.88l-.77-.79c-1.18-1.32-2.69-1.37-3.72-.98-1.9,.72-3.04,3.23-3.04,6.7v60.29c0,9.41-4.03,17.14-11.06,21.19-6.26,3.61-14.03,3.61-20.29,0-7.03-4.06-11.06-11.78-11.06-21.19V45c0-4.69,3.81-8.5,8.5-8.5s8.5,3.81,8.5,8.5v115.11c0,3.17,.93,5.53,2.56,6.47,1.02,.59,2.28,.59,3.29,0,1.63-.94,2.56-3.3,2.56-6.47v-60.29c0-10.65,5.36-19.3,13.99-22.59,7.86-2.99,16.58-.89,22.28,5.36l.97,.97,69.54,87.47c2.92,3.67,2.31,9.02-1.37,11.94Z"/> */}
        {/*   <path  d="M164.11,0H50.56C22.63,0,0,22.63,0,50.56v113.55c0,27.92,22.63,50.55,50.56,50.55h113.55c27.92,0,50.56-22.63,50.56-50.55V50.56c0-27.92-22.63-50.56-50.56-50.56Zm17.03,177.59c-6.41,4.32-15.12,2.63-19.45-3.79l-51.84-76.94-1.04-.83c-1.65-1.12-4.37-1.26-6.29-.3-.73,.37-2.26,1.14-2.26,4.74v52.34h-.14c-1.54,17.38-15.22,30.1-33.02,30.1s-31.48-12.73-33.02-30.1h-.14V50.43c0-7.74,6.27-14.01,14.01-14.01s14.01,6.27,14.01,14.01v99.09c0,1.33,0,5.38,5.15,5.38s5.15-4.05,5.15-5.38v-49.04c0-13.06,6.61-24.19,17.67-29.77,11.5-5.79,25.51-4.65,35.7,2.9l.39,.3,4.67,3.72,54.25,80.51c4.32,6.42,2.63,15.12-3.79,19.45Z" /> */}
        <path d="M942.85,253.66c-10.73-135.31-109.73-233.31-244.68-244.68-136.94-12.8-286.65-11.1-444.63,0C116.11,19.35,24.11,115.35,8.85,253.66c-11.95,146.2-11.64,294.46,0,444.63,11.27,127.05,113.27,230.05,244.68,244.68,149.42,12.07,297.65,12.27,444.63,0,136.95-10.63,224.95-111.63,244.68-244.68,19.1-148.21,19.1-296.42,0-444.63Zm-176.44,491.72c-10.37,8.97-23.14,13.36-35.84,13.36-15.37,0-30.65-6.42-41.49-18.96l-243.6-281.59c-.55-.61-1.1-1.24-1.63-1.88l-.8-.93c-1.16-1.34-2.24-2.72-3.25-4.14-1.78-1.69-5.02-3.82-10.09-3.82-7.14,0-13.16,4.75-13.16,10.36,0,1.94-.1,3.86-.3,5.75,0,.4,.01,.81,.01,1.21v167.24c.27,2.21,.41,4.46,.41,6.75,0,66.18-55.09,120.01-122.81,120.01s-122.81-53.84-122.81-120.01c0-1.34,.05-2.68,.14-4-.09-1.32-.14-2.65-.14-4V247.5c0-30.28,24.55-54.83,54.83-54.83s54.83,24.55,54.83,54.83v383.24c0,1.34-.05,2.68-.14,4,.09,1.32,.14,2.65,.14,4,0,5.62,6.03,10.36,13.16,10.36,6.1,0,11.39-3.47,12.8-7.99-.03-.76-.05-1.52-.05-2.29v-174.07c0-1.94,.1-3.86,.3-5.75,0-.4-.01-.81-.01-1.21,0-66.18,55.09-120.01,122.81-120.01,39.32,0,76.18,18.38,99.27,49.34l243.03,280.94c19.81,22.9,17.3,57.52-5.59,77.33Z" />
      </SvgIcon>
    );
  }

  // <WhatshotIcon style={{marginTop:"-3px", verticalAlign:"middle", color: "#130f40", fontSize:"18px"}}/>

  return (
    <Container maxWidth="lg" sx={{ mt: 1, pl: 0, pr: 0 }}>
      <Grid
        container
        spacing={2}
        style={{ marginTop: "10px" }}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12}>
          <Paper
            elevation={4}
            style={{ padding: "15px 15px", borderRadius: "8px" }}
          >
            <Typography variant="h5" color="#341f97" sx={{ fontWeight: 600 }}>
              {data.roast.name}
              <IconButton
                size=""
                sx={{ float: "right", color: "rgba(95, 39, 205,1.0)" }}
                onClick={() => likeHandler()}
              >
                {liked ? <ThumbUpIcon /> : <ThumbUpOffAltIcon />}
              </IconButton>
            </Typography>

            <Typography
              variant="subtitle1"
              color="#130f40"
              style={{ marginTop: "5px", fontWeight: 500 }}
            >
              Roasted on{" "}
              {new Date(data.batch.date_roasted.seconds * 1000)
                .toDateString()
                .split(" ")
                .slice(1)
                .join(" ")}
            </Typography>
            <Typography
              variant="subtitle2"
              color="#130f40"
              style={{ marginTop: "0px", fontWeight: 300 }}
            >
              Best between{" "}
              {bestMinDate.toDateString().split(" ").slice(1, 3).join(" ")} and{" "}
              {bestMaxDate.toDateString().split(" ").slice(1, 3).join(" ")}
            </Typography>

            <Typography variant="subtitle2" color="#2c3e50" sx={{ mt: 2 }}>
              {data.roast.description}
            </Typography>
            <Box sx={{ mt: 1.5 }}>
              <Chip
                label={data.roast.roast_degree}
                size="small"
                color="primary"
                style={{
                  backgroundColor: mapDegreeToColor(data.roast.roast_degree),
                  marginTop: "5px",
                }}
              />
              {data.roast.taste_notes.map((note, idx) => (
                <Chip
                  key={note + idx}
                  label={note}
                  size="small"
                  color="primary"
                  style={{
                    marginLeft: "5px",
                    marginTop: "5px",
                    backgroundColor: mapRoastNotesToColors(note),
                  }}
                />
              ))}
            </Box>
          </Paper>
        </Grid>
        {data.roast.brew_tips && (
          <Grid item xs={12}>
            <Paper
              elevation={4}
              style={{ padding: "10px 15px 20px 15px", borderRadius: "8px" }}
            >
              <Typography
                variant="h6"
                color="#2c3e50"
                style={{ marginBottom: "5px" }}
              >
                Brew Tips
              </Typography>
              <Typography variant="subtitle2" color="#2c3e50" sx={{ mt: 0 }}>
                {data.roast.brew_tips}
              </Typography>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper
            elevation={4}
            style={{
              height: "250px",
              padding: "10px 15px 40px 15px",
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="h6"
              color="#2c3e50"
              style={{ marginBottom: "5px" }}
            >
              Roast Profile
              <IconButton
                size=""
                sx={{ float: "right", color: "rgba(0, 0, 0,0.3)", mt: -0.5 }}
                onClick={(e) => infoHandler(e)}
              >
                <InfoOutlinedIcon />
              </IconButton>
              <Popover
                id={popid}
                open={popopen}
                anchorEl={anchorEl}
                onClose={infoClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
                }}
              >
                <Typography sx={{ p: 2 }}>
                  When it comes to good coffee, the temperature throughout the
                  roast greatly impacts overall flavor. Check out{" "}
                  <Link href="https://www.scottrao.com/blog/2020/3/29/coffee-roasting-fundamentals">
                    this link to learn more
                  </Link>
                  .
                </Typography>
              </Popover>
            </Typography>
            {isManual && <ProfileLineGraph data={lineData.bt_trend} />}
            {!isManual && (
              <ProfileLineGraph
                smooth={true}
                data={lineData}
                eventLines={eventLines}
              />
            )}
          </Paper>
        </Grid>
        <Grid item alignContent xs={12}>
          <Paper
            elevation={4}
            style={{
              padding: "10px 15px",
              borderRadius: "8px",
              height: "400px",
            }}
          >
            <Typography variant="h6" color="#2c3e50">
              Origin{" "}
              {data.green.origin.name && (
                <span
                  style={{
                    fontSize: "13px",
                    paddingLeft: "5px",
                    fontWeight: 300,
                    color: "#130f40",
                  }}
                >
                  {data.green.origin.name}
                </span>
              )}
            </Typography>

            <Wrapper
              apiKey="AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc"
              render={maprender}
            >
              <GreenMap center={center} zoom={zoom} />
            </Wrapper>
          </Paper>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            mt: 2,
            opacity: "0.7",
          }}
        >
          <LogoIcon sx={{ color: "#5f27cd", transform: "scale(1.3)" }} />
          <Typography
            sx={{
              fontSize: "0.8rem",
              color: "#5f27cd",
              fontWeight: 600,
              mt: 0.5,
            }}
          >
            {" "}
            haste{" "}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            mt: 0,
            opacity: "0.7",
            fontFamily: "Roboto",
          }}
        >
          <Link
            sx={{
              fontSize: "0.8rem",
              color: "#5f27cd",
              fontWeight: 600,
              mt: 0,
              fontSize: "10px",
              fontWeight: "600",
              textTransform: "uppercase",
              letterSpacing: "1.3px",
            }}
            href={`mailto:brandon@haste.coffee`}
          >
            {" "}
            Email Feedback{" "}
          </Link>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
        message="Glad you like it!"
        key={"bottom" + "center"}
        autoHideDuration={2000}
        sx={{}}
        ContentProps={{
          sx: {
            display: "block",
            textAlign: "center",
            backgroundColor: "#4834d4",
            fontSize: "17px",
          },
        }}
      />
    </Container>
  );
}
