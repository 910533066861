import React, { useState, useRef } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ProfileLineGraph from "../Roasting/ProfileLineGraph";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircleIcon from "@mui/icons-material/Circle";
import RoastQRCode from "../Roasting/helpers/RoastQRCode";

const colorArray = ["#341f97", "#8e44ad", "#ed047a", "#16a085", "#2c3e50"];

export default function QRDialog(props) {
  const { onClose, open, qrRoastID } = props;
  const [snackOpen, setSnackOpen] = useState(false);
  const [qrColor, setQRColor] = useState(colorArray[0]);

  const url = "https://hasteroastery.io/discover/" + qrRoastID;

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handleClose = () => {
    onClose();
  };

  const handleColorChange = (color) => {
    setQRColor(color);
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm">
      <DialogTitle sx={{ fontWeight: 500 }}>Share Batch Info</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={1.5}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sx={{ transform: "scale(0.5)", mt: -7 }}>
            <RoastQRCode url={url} color={qrColor} />
          </Grid>
          <Grid item xs={12} sx={{ mt: -10 }}>
            <Stack direction="row" spacing={1}>
              {colorArray.map((val) => (
                <IconButton onClick={() => handleColorChange(val)}>
                  <CircleIcon style={{ color: val }} />
                </IconButton>
              ))}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ fontSize: 13 }}>
              {" "}
              Right-click to copy QR code{" "}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography> Or</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              sx={{ m: 1, width: "30ch" }}
              variant="outlined"
              size="small"
            >
              <InputLabel htmlFor="outlined-adornment-copy">Url</InputLabel>
              <OutlinedInput
                id="outlined-adornment-copy"
                type={"text"}
                value={url}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="copy"
                      edge="end"
                      onClick={() => {
                        navigator.clipboard.writeText(url);
                        setSnackOpen(true);
                      }}
                    >
                      <ContentCopyIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton
                      size="small"
                      aria-label="link"
                      edge="end"
                      onClick={() =>
                        window.open(url, "_blank", "noopener,noreferrer")
                      }
                      sx={{ ml: 0.5 }}
                    >
                      <OpenInNewIcon fontSize="inherit" />
                    </IconButton>
                  </InputAdornment>
                }
                label="Url"
              />
            </FormControl>
          </Grid>
        </Grid>

        <Snackbar
          open={snackOpen}
          autoHideDuration={3000}
          onClose={handleSnackClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Link copied to clipboard!
          </Alert>
        </Snackbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
