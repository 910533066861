import React, { useState, useEffect } from "react";
import logo from "../assets/loading6.gif";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export default function LoadingLogo({ message, color }) {
  var fontColor = color ? color : "#5f27cd";
  return (
    <React.Fragment>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        style={{ minHeight: "100%" }}
      >
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "rgba(255,255,255,1)",
            borderRadius: 3,
            p: 2,
          }}
        >
          <img src={logo} alt="loading..." style={{ width: "200px" }} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
