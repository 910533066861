export function mapRoastNotesToColors(note) {
  note = note.toLowerCase();
  switch (note) {
    case "floral":
      return "#B0C461";
    case "fruity":
      return "#DD374F";
    case "berry":
      return "#C32629";
    case "herby":
      return "#74A939";
    case "nutty":
      return "#AE6F3C";
    case "malty":
      return "#E5A673";
    case "spice":
      return "#A96A44";
    case "chocolate":
      return "#412423";
    case "carmelly":
      return "#B97730";
    case "earthy":
      return "#C6C8A6";
    case "vanilla":
      return "#BBE3FC";
    default:
      return "#0abde3";
  }
}

export function getContrastyTextColor(hexcolor) {
  var r = parseInt(hexcolor.substring(1, 3), 16);
  var g = parseInt(hexcolor.substring(3, 5), 16);
  var b = parseInt(hexcolor.substring(5, 7), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  // return (yiq >= 128) ? 'black' : 'white';
  var hsl = RGBToHSL(r, g, b);
  return yiq >= 128
    ? "hsl(" + hsl[0] + " " + hsl[1] + "% " + (hsl[2] - 30) + "%)"
    : "hsl(" + hsl[0] + " " + hsl[1] + "% " + (hsl[2] + 30) + "%)";
}

const RGBToHSL = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const l = Math.max(r, g, b);
  const s = l - Math.min(r, g, b);
  const h = s
    ? l === r
      ? (g - b) / s
      : l === g
      ? 2 + (b - r) / s
      : 4 + (r - g) / s
    : 0;
  return [
    60 * h < 0 ? 60 * h + 360 : 60 * h,
    100 * (s ? (l <= 0.5 ? s / (2 * l - s) : s / (2 - (2 * l - s))) : 0),
    (100 * (2 * l - s)) / 2,
  ];
};

export function mapDegreeToColor(degree) {
  switch (degree) {
    case "Cinnamon":
      return "#c98d69";
    case "Light":
      return "#c68a65";
    case "Light-Medium":
      return "#ad6648";
    case "Medium":
      return "#8f4b39";
    case "Medium-Dark":
      return "#75372c";
    case "Dark":
      return "#401b11";
    case "French":
      return "#241f21";
    default:
      return "black";
  }
}

export function getAllNotes() {
  return [
    "floral",
    "fruity",
    "berry",
    "cocoa",
    "nutty",
    "malty",
    "spice",
    "earthy",
    "vanilla",
    "cherry",
    "cinnamon",
    "citrus",
    "chocolate",
    "caramel",
    "herbal",
    "caramelized sugar",
    "tobacco",
    "winey",
    "acidic",
    "smoky",
    "syrupy",
  ];
  //	return ['creamy', 'sweet', 'brown sugar', 'chocolate', 'berry', 'grape','pome fruit','stone fruit','melon', 'tropical','citrus','fruity', 'fruit', 'flowers', 'tea', 'floral', 'roasty','woody','nutty','cereal','earthy','spice','spicey','savory','herb', 'herby','herbal','vegetal'];
}

export function getNotesColorPairs() {
  var list = [];
  getAllNotes().forEach((note) => {
    list.push({ note: note, color: mapRoastNotesToColors(note) });
  });
  return list;
}
