import React, { useState, useEffect } from "react";
import Title from "./Title";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import SvgIcon from "@mui/material/SvgIcon";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import PageviewIcon from "@mui/icons-material/Pageview";
import HomeIcon from "@mui/icons-material/Home";
import QuestionAnswerSharpIcon from "@mui/icons-material/QuestionAnswerSharp";
import News from "./components/News";
import ConsumerFeedback from "./components/ConsumerFeedback";
import RelevantShopComponent from "./Shop/RelevantShopComponent";
import StoreIcon from "@mui/icons-material/Store";
import SchoolIcon from "@mui/icons-material/School";

import { getFunctions, httpsCallable } from "firebase/functions";

const functions = getFunctions();

function BeanIcon(props) {
  return (
    <SvgIcon viewBox="-50 -100 650 650" {...props}>
      <path
        class="cls-1"
        d="M20.25,404.81c-.87,.05-1.73,.15-2.58,.28-48.99-101.79,6.54-246.18,133.99-335.48C277.85-18.82,430.75-22.54,510.39,55.86c-60.45,19.68-115.14,49.54-166.75,91-48.17,38.7-87.71,82.75-122.59,121.61-65.96,73.49-118.06,131.54-200.79,136.35ZM544.04,102.79c-1.1,.49-2.23,.91-3.41,1.26-59.31,17.44-112.48,45.46-162.55,85.68-44.68,35.89-80.99,76.35-116.1,115.47-61.37,68.38-119.7,133.36-206.97,150.53,80.56,74.89,230.85,70.01,355.21-17.14,127.58-89.4,183.09-233.99,133.82-335.81Z"
      />
    </SvgIcon>
  );
}

function RoasterIcon(props) {
  return (
    <SvgIcon viewBox="-2 -80 890 700" {...props}>
      <g id="Layer_10" data-name="Layer 10">
        <path
          class="cls-1"
          d="M471.06,93.08h-114.67c-1.51,0-2.94-.71-3.84-1.93-16.07-21.54-32.14-43.07-48.22-64.61-8.18-10.96-.36-26.55,13.31-26.55h192.18c13.66,0,21.48,15.58,13.31,26.54-16.08,21.54-32.15,43.08-48.23,64.62-.9,1.21-2.33,1.93-3.84,1.93Z"
        />
        <g>
          <rect
            class="cls-1"
            x="581.37"
            y="511.15"
            width="244.63"
            height="130.23"
            rx="52.99"
            ry="52.99"
          />
          <path
            class="cls-1"
            d="M779.31,537.65c8.74,0,15.85,6.11,15.85,13.62v49.99c0,7.51-7.11,13.62-15.85,13.62h-151.25c-8.74,0-15.85-6.11-15.85-13.62v-49.99c0-7.51,7.11-13.62,15.85-13.62h151.25m0-26.5h-151.25c-25.79,0-46.69,17.96-46.69,40.12v49.99c0,22.16,20.9,40.12,46.69,40.12h151.25c25.79,0,46.69-17.96,46.69-40.12v-49.99c0-22.16-20.9-40.12-46.69-40.12h0Z"
          />
        </g>
        <path
          class="cls-1"
          d="M118.01,305.43h254.58c63.35,0,114.79,51.43,114.79,114.79v208.77c0,5.57-4.52,10.09-10.09,10.09H10.09c-5.57,0-10.09-4.52-10.09-10.09v-205.55c0-65.13,52.88-118.01,118.01-118.01Z"
        />
        <path
          class="cls-1"
          d="M445.93,226.92c9.61,4.06,18.76,8.94,27.41,14.58V92.44h-119.56v120.73h24.11c23.57,0,46.46,4.62,68.04,13.74Z"
        />
      </g>
    </SvgIcon>
  );
}

function preventDefault(event) {
  event.preventDefault();
}

export default function Home({ roaster }) {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  //console.log(roaster);

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);
        //const data = await fetchData();
        //  getNewsData();
        const getNews = httpsCallable(functions, "GetNewsAll");
        getNews().then((response) => {
          setNews(response.data.news);
        });
        //   console.log(data);
        // switch loading to false after fetch is complete
        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography sx={{ fontSize: 18 }}>Welcome to Roastery</Typography>
        <Typography sx={{ fontSize: 14, mt: 1, mb: 2 }} color="text.secondary">
          Let's jump right in! In Roastery, you can manage your roasts and track
          all of your new batches. Additionally, provide detailed roast
          information directly to your consumers and track overall engagement
          with your product through dashboards.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Stack spacing={2}>
              <Grid item xs={12}>
                <News />
              </Grid>
              <Paper
                sx={{ p: 2, display: "flex", flexDirection: "column" }}
                elevation={2}
              >
                <Typography variant="subtitle1">Getting Started</Typography>
                <Typography variant="subtitle2">
                  Here's some info to help you get around a bit:
                </Typography>

                <Grid container spacing={1} sx={{ mt: 1 }}>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <HomeIcon
                      fontSize="small"
                      sx={{ color: "rgba(95, 39, 205,0.7)" }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography sx={{ fontSize: 12 }} color="text.primary">
                      <strong style={{ color: "rgba(95, 39, 205,1)" }}>
                        Home
                      </strong>{" "}
                      - Stay in touch with the latest and greatest with
                      Roastery.
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <BeanIcon
                      fontSize="small"
                      sx={{ color: "rgba(95, 39, 205,0.7)" }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography sx={{ fontSize: 12 }} color="text.primary">
                      <strong style={{ color: "rgba(95, 39, 205,1)" }}>
                        Roasts
                      </strong>{" "}
                      - Keep track of your greens, roasts, and batches day to
                      day. Here you can see high level information about a
                      roast, queue up batches, dive into roast profiles, and
                      generate consumer facing QR codes to engage with your
                      customers.
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <WhatshotIcon
                      fontSize="small"
                      sx={{ color: "rgba(95, 39, 205,0.7)" }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography sx={{ fontSize: 12 }} color="text.primary">
                      <strong style={{ color: "rgba(95, 39, 205,1)" }}>
                        Copilot
                      </strong>{" "}
                      - Let's get roasting. Once you queue up your next batches
                      from Roasts you are ready to go.
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}></Grid>
                  <Grid item xs={11}>
                    <Typography sx={{ fontSize: 12 }} color="text.primary">
                      <strong style={{ color: "rgba(95, 39, 205,1)" }}></strong>{" "}
                      Copilot keeps track of your inflight batches you've queued
                      up. Upload profiles and complete batches as you roast.
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <RoasterIcon
                      fontSize="small"
                      sx={{ color: "rgba(95, 39, 205,0.7)" }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography sx={{ fontSize: 12 }} color="text.primary">
                      <strong style={{ color: "rgba(95, 39, 205,1)" }}>
                        Roastery
                      </strong>{" "}
                      - See how your consumers are engaging with your coffee.
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <StoreIcon
                      fontSize="small"
                      sx={{ color: "rgba(95, 39, 205,0.7)" }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography sx={{ fontSize: 12 }} color="text.primary">
                      <strong style={{ color: "rgba(95, 39, 205,1)" }}>
                        Market
                      </strong>{" "}
                      - Dive into greens across multiple sources to find what
                      you need.
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <QuestionAnswerSharpIcon
                      fontSize="small"
                      sx={{ color: "rgba(95, 39, 205,0.7)" }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography sx={{ fontSize: 12 }} color="text.primary">
                      <strong style={{ color: "rgba(95, 39, 205,1)" }}>
                        Give Feedback
                      </strong>{" "}
                      - We are always looking for ways to improve the platform
                      so feel free to provide feedback.
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ textAlign: "center" }}>
                    <SchoolIcon
                      fontSize="small"
                      sx={{ color: "rgba(95, 39, 205,0.7)" }}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography sx={{ fontSize: 12 }} color="text.primary">
                      <strong style={{ color: "rgba(95, 39, 205,1)" }}>
                        Guide
                      </strong>{" "}
                      - Get a step-by-step walkthrough for getting started with
                      Haste Roastery.
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ConsumerFeedback roaster_id={roaster.id} />
              </Grid>
              <Grid item xs={12}>
                <RelevantShopComponent />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
