export function sortArrayBy(arrayToSort, fieldToSort, asc = true) {
  if (asc)
    return []
      .concat(arrayToSort)
      .sort((a, b) => (a[fieldToSort] > b[fieldToSort] ? 1 : -1));

  return []
    .concat(arrayToSort)
    .sort((a, b) => (a[fieldToSort] < b[fieldToSort] ? 1 : -1));
}

export function sortArrayByDate(arrayToSort, fieldToSort, asc = true) {
  if (asc)
    return []
      .concat(arrayToSort)
      .sort((a, b) =>
        new Date(a[fieldToSort]) > new Date(b[fieldToSort]) ? 1 : -1
      );

  return []
    .concat(arrayToSort)
    .sort((a, b) =>
      new Date(a[fieldToSort]) < new Date(b[fieldToSort]) ? 1 : -1
    );
}

export function isLikelyForC(number) {
  return "F";
  if (number > 300) {
    return "F";
  } else {
    return "C";
  }
}

export function isEmptyObj(obj) {
  return obj && Object.keys(obj).length === 0;
}

export function convertSecondsToMinutesLabel(seconds) {
  if (seconds && !isNaN(seconds)) {
    var minutes = Math.floor(seconds / 60);
    var leftover = seconds - minutes * 60;

    return str_pad_left(minutes, "0", 2) + ":" + str_pad_left(leftover, "0", 2);
  }
  return "";
}
export function convertCtoF(val) {
  return (val * 9) / 5 + 32;
}

export function convertFtoC(val) {
  return roundNumberTo(((val - 32) * 5) / 9, 1);
}

export function convertArrayFromCtoF(arr) {
  return arr.map((val) => convertCtoF(val));
}

export function convertArrayFromFtoC(arr) {
  return arr.map((val) => convertFtoC(val));
}

function str_pad_left(string, pad, length) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}

export function roundNumberTo(num, decimals) {
  return parseFloat((Math.round(num * 100) / 100).toFixed(decimals));
}
