import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import Title from "../Title";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
} from "firebase/firestore";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { sortArrayBy, convertSecondsToMinutesLabel } from "../helpers/utility";
import QueueBatchButton from "../components/QueueBatchButton";

import NotesChip from "../../consumer/components/NotesChip";
import Badge from "../components/Badge.js";
import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();

export default function RoastCardList({
  roasts,
  roastClick,
  userData,
  roaster,
}) {
  const [idxItemClicked, setIdxItemClicked] = React.useState("");

  //console.log(roasts)
  const handleClick = (roast, idx) => {
    setIdxItemClicked(roast.id + idx);
    console.log(roast);
    roastClick(roast);
  };

  console.log(roasts);

  const handleQueueBatchClick = (roast) => {};
  /*

            <Button key={row.name + idx} onClick={() => handleClick(row, idx)} sx={{cursor:"pointer", mt:1, width:"100%", p:1, flexDirection:"column", alignItems:"flex-start",textAlign:"left", textTransform:"inherit"}}
          style={idxItemClicked === (row.id + idx) ? {backgroundColor: 'rgba(116, 185, 255,0.2)', borderRadius:"14px"} : {}}
          >
            <Typography sx={{ fontSize: 12 }} color="text.primary" >
            {row.name}
            </Typography>
            <Typography sx={{ fontSize: 10 }} color="text.secondary" >
            {row.roast_degree}
            </Typography>
          </Button>
*/

  /*
                {
                  row.reference_batch && (
                    <React.Fragment>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle1"
                              color="text.secondary"
                            >
                              Reference
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Badge
                              small
                              data={convertSecondsToMinutesLabel(
                                row.reference_batch.stats.drop_temp.time
                              )}
                              label="Roast&nbsp;Time"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Badge
                              small
                              data={
                                row.reference_batch.stats.charge_temp + " °F"
                              }
                              label="Charge&nbsp;Temp"
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <Badge
                              small
                              data={
                                row.reference_batch.stats.drop_temp.temp + " °F"
                              }
                              label="Drop&nbsp;Temp"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                }
                */

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        {sortArrayBy(roasts, "name", true).map((row, idx) => (
          <Grid item key={idx} xs={4}>
            <Box
              sx={{
                borderRadius: "24px",
                backgroundColor: "white",
                padding: 3,
                boxShadow: "0px 3px 6px rgba(0,0,0,.2)",
                cursor: "pointer",
              }}
              onClick={() => handleClick(row, idx)}
            >
              <Grid container spacing={1.5}>
                <Grid item xs={12}>
                  <Typography variant="h4" display="inline">
                    {row.name}
                  </Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography variant="subtitle1" color="text.secondary">
                    Degree
                  </Typography>
                </Grid>
                <Grid item xs={8.5}>
                  <Typography variant="subtitle1">
                    {row.roast_degree}
                  </Typography>
                </Grid>
                <Grid item xs={3.5}>
                  <Typography variant="subtitle1" color="text.secondary">
                    Flavors
                  </Typography>
                </Grid>
                <Grid item xs={8.5}>
                  {row.taste_notes &&
                    row.taste_notes.map((note, idx) => (
                      <NotesChip
                        key={idx}
                        label={note}
                        sx={{ m: 0.25, fontSize: 10, height: "23px" }}
                      />
                    ))}
                </Grid>
                {row.notes && (
                  <React.Fragment>
                    <Grid item xs={3.5}>
                      <Typography variant="subtitle1" color="text.secondary">
                        My Notes
                      </Typography>
                    </Grid>
                    <Grid item xs={8.5}>
                      <Typography variant="subtitle1">{row.notes}</Typography>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Box>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
