import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";

import Button from "@mui/material/Button";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import WarningIcon from "@mui/icons-material/Warning";

import TextField from "@mui/material/TextField";

import { initializeApp } from "firebase/app";
import Firestore, {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  GeoPoint,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  updateMetadata,
  listAll,
  getMetadata,
  getDownloadURL,
} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const storage = getStorage(app);

export default function DeleteGreenDialog(props) {
  const { onClose, open, userName, roaster, green } = props;
  const [dialogError, showDialogError] = useState(false);
  const [name, setName] = useState("");
  const theme = useTheme();
  //console.log(green);

  var state = {
    name: name,
    id: green,
  };

  const handleClose = (deleted) => {
    //  //console.log(profile)
    showDialogError(false);
    setName("");
    onClose(deleted);
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    // //console.log(event)

    state[name] = value;
    //console.log(state);
  };

  const handleNameInputChange = (event) => {
    setName(event.target.value);
    ////console.log(event.target)
    //   handleInputChange(event)
  };

  function validateDeleteGreenThenSave() {
    if (name !== green) {
      showDialogError(true);
    } else {
      handleDeleteGreen();
    }
  }

  async function handleDeleteGreen() {
    // const ref = doc(collection(db, "Greens"));
    state.name = name;
    //console.log("delete", state);

    var q = query(
      collection(db, "Batches"),
      where("roaster_id", "==", roaster.id),
      where("green_id", "==", green)
    );

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc_to_delete) => {
      deleteDoc(doc(db, "Batches", doc_to_delete.id));
    });

    var q2 = query(
      collection(db, "Roasts"),
      where("roaster_id", "==", roaster.id),
      where("green_id", "==", green)
    );

    const query2Snapshot = await getDocs(q2);
    query2Snapshot.forEach((doc_to_delete) => {
      deleteDoc(doc(db, "Roasts", doc_to_delete.id));
    });

    deleteDoc(doc(db, "Greens", green));

    //   await setDoc(ref, state);
    handleClose({ deleted: true, id: green });
  }

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle
        sx={{
          backgroundColor: "rgba(255, 118, 117,0.4)",
          fontWeight: 600,
          color: "rgba(214, 48, 49,1.0)",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <WarningIcon sx={{ mr: 1 }} />
          <Typography sx={{ fontWeight: 600, fontSize: 21, ml: 0.5, mt: 0.5 }}>
            Delete this green?{" "}
          </Typography>
        </Box>
        Deleting this green will permanently remove it and all associated roasts
        and batches.
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <br />
          Are you sure?
          <br />
          <Typography sx={{ mt: 2 }}>
            Confirm you want to delete this green by entering the ID and
            clicking Delete below: <strong>{green}</strong>
          </Typography>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label=""
          placeholder={green}
          type="text"
          fullWidth
          required
          error={dialogError && name !== green}
          helperText={dialogError && name !== green ? "ID must match" : " "}
          variant="outlined"
          onChange={handleNameInputChange}
          sx={{ mt: 3 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>Cancel</Button>
        <Button onClick={validateDeleteGreenThenSave}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}
