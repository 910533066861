export default class RoastProfileFileParser { // assumes artisan profile File

	constructor(file) {
	 	this.file = file // of type File

	}

	async getJSON() {
		var file = this.file;
		console.log(file)
		var data = {json: "", error:""};
		var loadedData = "";
		if(file) {
			var text = await file.text();
		//	console.log(text)
			loadedData = text.replace(/'/g, '"').replace(/False/g, '"False"').replace(/True/g, '"True"');
		    data.json = JSON.parse(loadedData);
		}
		return data;
	}

}