import React, { useState, useEffect} from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

import { useTheme } from '@mui/material/styles';

import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import WarningIcon from '@mui/icons-material/Warning';

import TextField from '@mui/material/TextField';

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import { initializeApp } from "firebase/app";
import Firestore, { getFirestore, collection, getDocs, where, doc, setDoc, query, deleteDoc, GeoPoint } from "firebase/firestore";
import { getStorage, ref, uploadBytes, updateMetadata, listAll, getMetadata, getDownloadURL } from "firebase/storage";

import { useHistory, useParams, useNavigate } from 'react-router-dom'


import CircularLoader from '../CircularLoader'

import RoastProfileFileParser from '../helpers/RoastProfileFileParser'
import { RoastProfileJsonParser }  from '../Roasting/helpers/RoastProfileJsonParser'

const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const storage = getStorage(app);

export default function DeleteRoastDialog(props) {


  const { onClose, open, userName, roaster, roast } = props;
  const [dialogError, showDialogError] = useState(false);
  const [name, setName] = useState("");
  const theme = useTheme();
 // console.log(roast)

  var state = {
      name: name,
      id: roast

    };
  
  const handleClose = (deleted) => {
    console.log(deleted)
    showDialogError(false);
    setName("")
    onClose(deleted)
  };


  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

   // console.log(event)
   
      state[name] = value;
      console.log(state);
  }


const handleNameInputChange = (event) => {
    setName(event.target.value)
    //console.log(event.target)
 //   handleInputChange(event)
  }


  function validateDeleteRoastThenSave() {
        if(name !== roast )  { 
      showDialogError(true) 
    } else {
      handleDeleteRoast(); 
    }
  }

  async function handleDeleteRoast() {
   // const ref = doc(collection(db, "Greens"));
    state.name = name;
    console.log("delete",state);

    var q = query(collection(db, "Batches"), where("roaster_id", "==", roaster.id), where("roast_id", "==", roast));

    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc_to_delete) => {
        deleteDoc(doc(db, "Batches", doc_to_delete.id));
        });

    deleteDoc(doc(db, "Roasts", roast));


 //   await setDoc(ref, state);
    handleClose(true)
  };


   



  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle sx={{backgroundColor:"rgba(255, 118, 117,0.4)", fontWeight: 600,color:"rgba(214, 48, 49,1.0)"}}>
        <Box sx={{display:"flex", alignItems: 'center', flexWrap: 'wrap'}}>
        <WarningIcon sx={{mr:1}}/><Typography sx={{fontWeight:600, fontSize:21, ml:0.5, mt:0.5}}>Delete this roast? </Typography>
        </Box>
        Deleting this roast will permanently remove it and all associated batches.
          </DialogTitle>
      <DialogContent>
          <DialogContentText>
          <br/>Are you sure?<br/>
          <Typography sx={{mt:2}}>Confirm you want to delete this roast by entering the ID and clicking Delete below:  <strong>{roast}</strong></Typography>
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label=""
            placeholder={roast}
            type="text"
            fullWidth
            required
            error={dialogError && name !== roast}
            helperText={dialogError && name !== roast ? 'ID must match' : ' '}
            variant="outlined"
            onChange={handleNameInputChange}
            sx={{mt:3}}
          />
          
        </DialogContent>
      <DialogActions>
          <Button onClick={()=> handleClose(false)}>Cancel</Button>
          <Button onClick={validateDeleteRoastThenSave}>Delete</Button>
        </DialogActions>
    </Dialog>
    )
  
}
