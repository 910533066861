import React, { useState, useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { Slider } from "@mui/material";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import TextField from "@mui/material/TextField";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Avatar, Typography } from "@mui/material";
import BackgroundLetterAvatars from "./BackgroundLetterAvatars";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import {
  CollectionsBookmarkOutlined,
  ConstructionOutlined,
  Update,
} from "@mui/icons-material";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

const functions = getFunctions();
const UpdateUserInfo = httpsCallable(functions, "UpdateUserInfo");

const UploadImageButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  backgroundColor: "white",
  boxShadow: 2,
  "&:hover": {
    backgroundColor: "white",
  },
}));

const ChangePasswordButton = styled(Button)(({ theme }) => ({
  color: "rgba(104,10,239,0.8)",
  border: "1px solid rgba(104,10,239,0.4)",
  fontWeight: 800,
  borderRadius: "30px",
  fontSize: "11px",
  width: "50%",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,1)",
    border: "1px solid rgba(104,10,239,0.8)",
    color: "white",
  },
}));

const SubTitleTypo = styled(Typography)(({ theme }) => ({
  color: "rgba(95,39,205,1)",
  fontWeight: 300,
  fontSize: 14,
}));

export default function UserSettingsDialog({ user, roastery, onClose, open }) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState([]);

  const [nickname, setNickname] = React.useState(user.nickname);
  const [picture, setPicture] = React.useState(user.picture);
  const [roasteryName, setRoasteryName] = React.useState(roastery.name);
  const [roasteryWebsite, setRoasteryWebsite] = React.useState(
    roastery.website
  );
  const [roasteryInstagram, setRoasteryInstagram] = React.useState(
    roastery.instagram_url
  );
  const [temp, setTemp] = React.useState(user.default_temp);
  const [dBTOffset, setdBTOffset] = useState(user.default_deltaBT_offset);
  const [smoothSlideVal, setSmoothSlideVal] = useState(user.default_smooth);

  const handleClose = (event, saved = false) => {
    console.log(saved);
    if (!saved) {
      setNickname(user.nickname);
      setPicture(user.picture);
      setRoasteryName(roastery.name);
      setRoasteryWebsite(roastery.website);
      setRoasteryInstagram(roastery.instagram_url);
      setTemp(user.temp);
      setSmoothSlideVal(user.default_smooth);
      setdBTOffset(user.default_deltaBT_offset);
    } else {
      user.default_temp = temp;
      user.default_smooth = smoothSlideVal;
      user.default_deltaBT_offset = dBTOffset;
    }
    onClose({ roastery_name: roasteryName, user: user });
  };

  const handleUpdate = (e) => {
    var state = {
      user_id: user.sub,
      roastery_id: roastery.id,
      roastery_name: roasteryName,
      default_temp: temp,
      roastery_website: roasteryWebsite,
      roastery_instagram: roasteryInstagram,
      default_smooth: smoothSlideVal,
      default_deltaBT_offset: dBTOffset,
    };
    console.log(state);
    UpdateUserInfo(state);

    handleClose(e, true);
  };

  const onNicknameChange = (event) => {
    setNickname(event.target.value);
  };

  const onPictureChange = (event) => {
    setPicture(event.target.value);
  };

  const onRoasteryNameChange = (event) => {
    setRoasteryName(event.target.value);
  };

  const onRoasteryWebsiteChange = (event) => {
    setRoasteryWebsite(event.target.value);
  };

  const onRoasteryInstagramChange = (event) => {
    setRoasteryInstagram(event.target.value);
  };

  const onTempChange = (event) => {
    setTemp(event.target.value);
  };

  const handleFileInputChange = (event) => {
    console.log(event.target.files[0]);
    console.log(picture);
  };

  const dBTOffsetChange = (e) => {
    var val = parseInt(e.target.value);
    if (val < 1) return;
    setdBTOffset(val);
  };

  const handleSmoothSlideChange = (e) => {
    var val = parseInt(e.target.value);
    setSmoothSlideVal(val);
  };

  const StackHeader = (props) => {
    return (
      <Divider
        textAlign="left"
        role="presentation"
        sx={{
          color: "rgba(95,39,205,1)",
          fontWeight: 600,
          borderColor: "rgba(95,39,205,0.5)",
          pb: 2,
          pt: 1,
        }}
      >
        {props.text}
      </Divider>
    );
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);
        setLoading(false);
        //       const getData = httpsCallable(functions, "api_name");
        //       getData().then((response) => {
        //         setData(response.data);
        //       });
        //   console.log(data);
        // switch loading to false after fetch is complete
      } catch (error) {
        // add error handling here
        setLoading(false);
        setError(true);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  /*
              <StackHeader text={"Password"} />
              <Stack sx={{ pb: 3, pt: 1 }}>
                <ChangePasswordButton>Reset Password</ChangePasswordButton>
              </Stack>
*/

  return (
    <React.Fragment>
      <Dialog
        onClose={(event) => handleClose(event, false)}
        open={open}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Manage Your Account</DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Stack sx={{ height: "65vh", pt: 3 }}>
            <Stack
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ position: "relative" }}>
                <BackgroundLetterAvatars
                  name={nickname}
                  sx={{
                    fontSize: 30,
                    width: 100,
                    height: 100,
                    border: "1.5px white solid",
                  }}
                />
              </Box>
              <TextField
                id="nickname"
                label="Nickname"
                variant="outlined"
                disabled
                value={nickname}
                sx={{ mt: 3 }}
                onChange={onNicknameChange}
              />
            </Stack>
            <Box sx={{ p: 3, pt: 2 }}>
              {" "}
              <StackHeader text={"Roastery"} />
              <Stack sx={{ pb: 3, pt: 1 }} spacing={2}>
                <TextField
                  id="roastery_name"
                  label="Name"
                  variant="outlined"
                  value={roasteryName}
                  onChange={onRoasteryNameChange}
                />
                <TextField
                  id="roastery_website"
                  label="Website"
                  variant="outlined"
                  value={roasteryWebsite}
                  onChange={onRoasteryWebsiteChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">https://</InputAdornment>
                    ),
                  }}
                />
                <TextField
                  id="roastery_instagram"
                  label="Instagram"
                  variant="outlined"
                  value={roasteryInstagram}
                  onChange={onRoasteryInstagramChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">https://</InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <StackHeader text={"Personalization"} />
              <Stack sx={{ pb: 3 }}>
                <Box sx={{ display: "none" }}>
                  <SubTitleTypo>Temperature</SubTitleTypo>
                  <ToggleButtonGroup
                    color="primary"
                    id="temp"
                    value={temp}
                    exclusive
                    onChange={onTempChange}
                    aria-label="Temperature"
                    sx={{ mt: 1 }}
                  >
                    <ToggleButton value="F" sx={{ pl: 2, pr: 2 }}>
                      °F
                    </ToggleButton>
                    <ToggleButton value="C" sx={{ pl: 2, pr: 2 }}>
                      °C
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
                <SubTitleTypo sx={{ mt: 3 }}>Profile Smoothing</SubTitleTypo>
                <Typography id="smooth-slider" gutterBottom>
                  {smoothSlideVal}
                </Typography>
                <Slider
                  defaultValue={smoothSlideVal}
                  valueLabelDisplay="auto"
                  onChange={handleSmoothSlideChange}
                  step={1}
                  marks
                  min={0}
                  max={10}
                  aria-labelledby="smooth-slider"
                />
                <SubTitleTypo sx={{ mt: 3 }}>Delta BT Lookback</SubTitleTypo>
                <TextField
                  margin="dense"
                  id="DeltaOffset"
                  name="DeltaOffset"
                  label="Offset"
                  type="number"
                  value={dBTOffset}
                  InputLabelProps={{ shrink: true }}
                  onChange={dBTOffsetChange}
                  variant="standard"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">s</InputAdornment>
                    ),
                  }}
                  sx={{ width: 50 }}
                />
              </Stack>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={(e) => handleClose(e, false)}>Cancel</Button>
          <Button onClick={handleUpdate}>Update</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
