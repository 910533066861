import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

import {
  Button,
  Slider,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Drawer,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from "@mui/material";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ProfileLineGraph from "../Roasting/ProfileLineGraph";
import Box from "@mui/material/Box";

import CircularLoader from "../CircularLoader";

import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

import ManualBatchScatter from "./ManualBatchScatter";
import { isEmptyObj } from "../helpers/utility";

// REMOVING THIS BREAKS?!?1
const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const functions = getFunctions();
const getProfileData = httpsCallable(functions, "GetBatchProfileData");
const SetReferenceBatch = httpsCallable(functions, "SetReferenceBatch");

export default function ProfileDialog(props) {
  const { onClose, open, batch_id, user } = props;
  //console.log(user);
  const [lineData, setLineData] = React.useState({});
  const [referenceLineData, setReferenceLineData] = useState({});
  const [roast_datetime, setRoast_datetime] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [isManual, setIsManual] = React.useState(false);
  const [batchData, setBatchData] = React.useState({});
  const [isReference, setIsReference] = React.useState(false);
  const [reference_batch_id, setReference_batch_id] = useState("");
  const [eventLines, setEventLines] = useState([{}]); //{time:1, label:"test", color:"rgba(95, 39, 205,1)"}
  const [refToggled, setRefToggled] = React.useState(false);
  const [mode, setMode] = useState("F");

  const [dBTOffset, setdBTOffset] = useState(
    user.default_deltaBT_offset ? user.default_deltaBT_offset : 5
  );
  const [smoothSlideVal, setSmoothSlideVal] = useState(
    user.default_smooth ? user.default_smooth : 0
  );
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showReferenceVal, setShowReferenceVal] = useState(false);

  const convertToC = user.default_temp == "C";

  function getDataFromFunc() {
    //  connectFunctionsEmulator(functions, "localhost", 5001);

    getProfileData({
      batch_id: batch_id,
      smooth: false,
      dBTOffset: dBTOffset,
    }).then((response) => {
      // console.log(response);
      setIsManual(response.data.isManual);
      setLineData(response.data.profile_data);
      setIsReference(response.data.isReference);
      setEventLines(response.data.event_data);
      setShowReferenceVal(false);
      setRoast_datetime(response.data.date_time);
      setReference_batch_id(response.data.roast_reference_batch_id);
      setMode(response.data.mode);
    });
  }

  const merge = (...arrays) => {
    const merged = {};

    arrays.forEach((data) =>
      data.forEach((o) => Object.assign((merged[o.time] ??= {}), o))
    );

    return Object.values(merged);
  };

  function getReferenceData() {
    getProfileData({
      batch_id: reference_batch_id,
      smooth: false,
      dBTOffset: dBTOffset,
    }).then((response) => {
      // console.log("ref data got", response);
      var ref_profile_data = response.data.profile_data;
      ref_profile_data.forEach((dataPointObj) => {
        for (const [key, value] of Object.entries(dataPointObj)) {
          //   console.log(`${key}: ${value}`);
          if (key == "time") continue;
          dataPointObj["ref" + "_" + key] = dataPointObj[key];
          delete dataPointObj[key];
        }
      });
      //   console.log("ref_profile_data", ref_profile_data);
      setReferenceLineData({ id: reference_batch_id, data: ref_profile_data });
      var mergedData = merge(lineData, ref_profile_data);
      mergedData = sortByKey(mergedData, "time");

      console.log("merged", ref_profile_data, lineData, mergedData);
      setLineData(mergedData);
    });
  }

  function sortByKey(array, key) {
    return array.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  const handleClose = () => {
    var wasRef = isReference;
    var wasToggled = refToggled;
    setLineData({});
    setEventLines({});
    // setReferenceLineData({});
    setRoast_datetime("");
    setIsReference(false);
    setRefToggled(false);
    setdBTOffset(5);
    setSmoothSlideVal(0);
    onClose(wasToggled, wasRef);
  };

  const handleReferenceClick = () => {
    setRefToggled(true);
    SetReferenceBatch({ batch_id: batch_id, isReference: !isReference });
    setIsReference(!isReference);
  };

  const dBTOffsetChange = (e) => {
    var val = parseInt(e.target.value);
    if (val < 1) return;
    setdBTOffset(val);
  };

  const handleSmoothSlideChange = (e) => {
    var val = parseInt(e.target.value);
    setSmoothSlideVal(val);
  };

  const handleShowReferenceChange = (e) => {
    setShowReferenceVal(e.target.checked);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);
        //const data = await fetchData();
        if (open && batch_id) getDataFromFunc();
        if (!open) setLineData({});
        // switch loading to false after fetch is complete
        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        console.log(error);
      }
    };

    queryAsync();
  }, [open, dBTOffset]);

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        // setLoading(true);
        //const data = await fetchData();
        //  console.log("ref toggled", showReferenceVal, reference_batch_id);
        if (showReferenceVal && reference_batch_id) {
          //   console.log("showing ref");
          if (
            isEmptyObj(referenceLineData) ||
            referenceLineData.id !== reference_batch_id
          ) {
            //   console.log("collecting ref");
            //    setLineData({ ...lineData, ...referenceLineData });
            getReferenceData();
            //       setLineData({
            //        ...lineData,
            //        reference_data: { ...referenceLineData },
            //      });
          } else {
            //   console.log("already have ref data");
            if (referenceLineData.id == reference_batch_id) {
              var mergedData = merge(lineData, referenceLineData.data);
              mergedData = sortByKey(mergedData, "time");

              setLineData(mergedData);
            }
          }
        }
        // switch loading to false after fetch is complete
        // setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        console.log(error);
      }
    };

    queryAsync();
  }, [showReferenceVal]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="lg">
      <DialogTitle>
        Profile
        <Box
          sx={{
            display: "inline",
            float: "right",
            color: "rgba(253, 203, 110,1.0)",
            borderRadius: "20px",
            display: "flex",
            p: 1,
          }}
          onClick={handleReferenceClick}
          className="clickable"
        >
          {loading ? (
            <React.Fragment></React.Fragment>
          ) : isReference ? (
            <StarIcon />
          ) : (
            <StarBorderIcon />
          )}
        </Box>
        {roast_datetime && (
          <React.Fragment>
            <Typography color="text.secondary">{roast_datetime}</Typography>
          </React.Fragment>
        )}
      </DialogTitle>
      <DialogContent style={{ height: "550px", width: "1200px" }} key={"right"}>
        {loading && <CircularLoader height="10vh" />}

        {!isManual && !loading && (
          <React.Fragment>
            <ProfileLineGraph
              eventLines={eventLines}
              smooth={true}
              smoothSize={smoothSlideVal}
              data={lineData}
              showReference={showReferenceVal}
            />
          </React.Fragment>
        )}

        {isManual && !loading && (
          <ProfileLineGraph
            isManual
            data={lineData.bt_trend}
            eventLines={eventLines}
          />
        )}
      </DialogContent>
      <Box sx={{}}>
        {drawerOpen && (
          <Grid container sx={{ justifyContent: "center" }}>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography id="smooth-slider" gutterBottom>
                  Smoothing: {smoothSlideVal}
                </Typography>
                <Slider
                  defaultValue={smoothSlideVal}
                  valueLabelDisplay="auto"
                  onChange={handleSmoothSlideChange}
                  step={1}
                  marks
                  min={0}
                  max={10}
                  aria-labelledby="smooth-slider"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TextField
                margin="dense"
                id="DeltaOffset"
                name="DeltaOffset"
                label="Offset"
                type="number"
                value={dBTOffset}
                InputLabelProps={{ shrink: true }}
                onChange={dBTOffsetChange}
                variant="standard"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">s</InputAdornment>
                  ),
                }}
                sx={{ width: 50 }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={showReferenceVal}
                      onChange={handleShowReferenceChange}
                      disabled={reference_batch_id == null}
                    />
                  }
                  label="Show Reference"
                />
              </FormGroup>
            </Grid>
          </Grid>
        )}
      </Box>
      <DialogActions>
        <Button onClick={toggleDrawer(!drawerOpen)}>{"Options"}</Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
