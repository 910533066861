import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import Title from "../Title";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
} from "firebase/firestore";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import QueueBatchButton from "../components/QueueBatchButton";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PersonIcon from "@mui/icons-material/Person";

export default function FavoriteRoast({ roast }) {
  const LINES_TO_SHOW = 2;

  const multiLineEllipsis = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical",
  };

  return (
    <React.Fragment>
      <Card
        key={roast.id}
        sx={{
          display: "flex",
          p: 0.5,
          height: "100%",
          backgroundColor: "rgba(0, 184, 148,0.1)",
        }}
        elevation={0}
      >
        <Box
          sx={{
            p: 1,
            pb: 0,
            display: "flex",
            flexDirection: "column",
            width: "60%",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flex: "1 0 auto",
              p: 0,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: 12,
                mt: 0.2,
                pl: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
              color="text.primary"
            >
              {roast.name}
            </Typography>
          </CardContent>
        </Box>
        <Box
          sx={{
            width: 60,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ alignItems: "center" }}>
            <Typography
              title="Views"
              sx={{
                fontSize: 11,
                mt: 0.5,
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
              color="text.secondary"
            >
              {roast.total_consumer_views}
              <PersonIcon
                sx={{ ml: 1, transform: "scale(1.0)" }}
                fontSize="small"
              />
            </Typography>
            <Typography
              title="Likes"
              sx={{
                fontSize: 11,
                mt: 0.5,
                fontWeight: 500,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
              color="text.secondary"
            >
              {roast.total_consumer_likes}
              <ThumbUpIcon
                sx={{ ml: 1, transform: "scale(0.8)" }}
                fontSize="small"
              />
            </Typography>
          </Box>
        </Box>
      </Card>
    </React.Fragment>
  );
}
