import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../Title";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  getDoc,
  query,
  deleteDoc,
  limit,
  orderBy,
  startAfter,
} from "firebase/firestore";
import { initializeApp } from "firebase/app";
import SortIcon from "@mui/icons-material/Sort";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import ShopItem from "./ShopItem";
import FilterCheckBoxList from "./FilterCheckBoxList";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
  NumericMenu,
  ClearRefinements,
  CurrentRefinements,
  Pagination,
  connectHits,
  connectSearchBox,
  ScrollTo,
} from "react-instantsearch-dom";
import "../styles/algolia.css";

const searchClient = algoliasearch(
  "AIXY15GEYO",
  "e511a8f627a2c805607b621927180a14"
);

function preventDefault(event) {
  event.preventDefault();
}

export default function Shop({ userData }) {
  const [searched, setSearched] = useState("");
  const [displayInvItems, setDisplayInvItems] = useState([]);

  const [loading, setLoading] = useState(true);
  const [lastDoc, setLastDoc] = useState();
  const [flavor, setFlavor] = useState({});
  const [origin, setOrigin] = useState({});
  const [vendor, setVendor] = useState({});
  const [price, setPrice] = useState({});

  const Hits = ({ hits }) => {
    useEffect(() => {
      scrollToTop();
    }, [hits]);
    return (
      <Grid
        container
        spacing={2}
        direction="row"
        justify="flex-start"
        sx={{ mt: 0, p: 0.5 }}
        className="scroller"
        id="scroller"
      >
        {hits.map((hit) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={hits.indexOf(hit)}
            sx={{}}
          >
            <ShopItem item={hit} />
          </Grid>
        ))}
      </Grid>
    );
  };

  const CustomHits = connectHits(Hits);

  const scrollToTop = () => {
    document.getElementById("scroller").scroll(0, 0);
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);

        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  return (
    <React.Fragment>
      <InstantSearch
        searchClient={searchClient}
        indexName="haste_roastery_shop"
      >
        <Container
          maxWidth={false}
          height="100%"
          disableGutters
          sx={{ mt: 2, mb: 3, height: "97%" }}
        >
          <Grid container spacing={1} sx={{ height: "100%" }}>
            <Grid item xs={2}>
              <Paper
                className="scroller"
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  height: "93vh",
                }}
                elevation={1}
              >
                <Box>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ mb: 1 }}>
                      <Typography class="filterSectionHeader">
                        Filters
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography class="filterHeader">
                        Tasting Notes
                      </Typography>
                      <RefinementList
                        attribute="taste_notes"
                        operator="or"
                        limit={5}
                        showMore
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography class="filterHeader">Vendor</Typography>
                      <RefinementList
                        attribute="vendor"
                        operator="or"
                        limit={3}
                      />
                      {/*   <FilterCheckBoxList headerText="Origin" options={origin} onChangeHandler={handleOriginFilterChange} /> */}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography class="filterHeader">Cost</Typography>
                      <NumericMenu
                        attribute="cost"
                        items={[
                          { label: "<= $6", end: 6 },
                          { label: "$6-9", start: 6, end: 9 },
                          { label: ">= $9", start: 9 },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ClearRefinements />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={10} height="79vh">
              <Box>
                <SearchIcon
                  sx={{
                    position: "absolute",
                    marginLeft: "18px",
                    marginTop: "18px",
                    opacity: "0.7",
                  }}
                />
                <SearchBox
                  searchAsYouType={true}
                  autofocus
                  showLoadingIndicator={true}
                />
              </Box>
              <CustomHits />
              <Pagination />
            </Grid>
          </Grid>
        </Container>
      </InstantSearch>
    </React.Fragment>
  );
}
