import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularLoader(props) {
  const {height} = props;
  var height_val = '100vh'
  if(height) height_val = height
  return (
    <Box sx={{ display: 'flex',  justifyContent:'center', alignItems:'center', height: height_val }}>
      <CircularProgress />
    </Box>
  );
}
