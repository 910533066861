import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import Select from "@mui/material/Select";

import { useTheme, styled } from "@mui/material/styles";

import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SvgIcon from "@mui/material/SvgIcon";

import Divider from "@mui/material/Divider";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import AbcIcon from "@mui/icons-material/Abc";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";

import TextField from "@mui/material/TextField";

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

import { initializeApp } from "firebase/app";
import Firestore, {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  GeoPoint,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytes,
  updateMetadata,
  listAll,
  getMetadata,
  getDownloadURL,
} from "firebase/storage";

import { getFunctions, httpsCallable } from "firebase/functions";
import { useHistory, useParams, useNavigate } from "react-router-dom";

import CircularLoader from "../CircularLoader";

import RoastProfileFileParser from "../helpers/RoastProfileFileParser";
import { RoastProfileJsonParser } from "../Roasting/helpers/RoastProfileJsonParser";
import { isEmptyObj } from "../helpers/utility";
import GoogleMaps from "./GoogleMaps";
import { Typography } from "@mui/material";

const functions = getFunctions();
const AddNewGreen = httpsCallable(functions, "AddNewGreen");
const UpdateGreen = httpsCallable(functions, "UpdateGreen");
function BeanIcon(props) {
  return (
    <SvgIcon viewBox="-50 -100 650 650" {...props}>
      <path
        class="cls-1"
        d="M20.25,404.81c-.87,.05-1.73,.15-2.58,.28-48.99-101.79,6.54-246.18,133.99-335.48C277.85-18.82,430.75-22.54,510.39,55.86c-60.45,19.68-115.14,49.54-166.75,91-48.17,38.7-87.71,82.75-122.59,121.61-65.96,73.49-118.06,131.54-200.79,136.35ZM544.04,102.79c-1.1,.49-2.23,.91-3.41,1.26-59.31,17.44-112.48,45.46-162.55,85.68-44.68,35.89-80.99,76.35-116.1,115.47-61.37,68.38-119.7,133.36-206.97,150.53,80.56,74.89,230.85,70.01,355.21-17.14,127.58-89.4,183.09-233.99,133.82-335.81Z"
      />
    </SvgIcon>
  );
}

const ActionButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "20px",
  fontSize: "13px",
  height: "35px",
  backgroundColor: "rgba(104,10,239,0.7)",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,1)",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(165, 177, 194,0.3)",
  },
}));

export default function AddGreenDialog(props) {
  const { onClose, open, userName, roaster, greenSelected } = props;

  var initialName = "";
  var initialProcessing = "";
  var initialDate = new Date("1/1/1900");

  const [dialogError, showDialogError] = useState(false);
  const [processing, setProcessing] = useState(initialProcessing);
  const [name, setName] = useState(initialName);
  const [harvestDate, setHarvestDate] = useState(initialDate);
  const [origin, setOrigin] = useState("");

  const [snackOpen, setSnackOpen] = useState(false);

  const theme = useTheme();

  const [loading, setLoading] = useState(true);

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  var state = {
    name: name,
    processing: processing,
    harvest_date: harvestDate,
    in_stock_date: new Date(),
    origin: { location: new GeoPoint(0, 0) },
    user_id: userName,
    roaster_id: roaster.id,
  };

  var geocoder;

  useEffect(() => {
    geocoder = new window.google.maps.Geocoder();
  });

  function geocode(request) {
    console.log("geocoding", request);

    return geocoder.geocode(request);
  }

  const handleClose = (newObj) => {
    //  console.log(profile)
    showDialogError(false);
    setProcessing("");
    setName("");
    setHarvestDate(new Date("1/1/1900"));
    setOrigin("");
    onClose(newObj);
  };

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    // console.log(event)

    state[name] = value;
    //console.log(state);
  };

  const handleSelectInputChange = (event) => {
    setProcessing(event.target.value);
    //console.log(event.target)
    //   handleInputChange(event)
  };
  const handleNameInputChange = (event) => {
    setName(event.target.value);
    //console.log(event.target)
    //   handleInputChange(event)
  };
  const handleDateInputChange = (event) => {
    setHarvestDate(event.target.value);
    //console.log(event.target)
    //   handleInputChange(event)
  };

  const handleOriginChange = (event) => {
    setOrigin(event.target.value);
    //console.log(event.target)
    //   handleInputChange(event)
  };

  function validateNewGreenThenAdd() {
    if (name === "") {
      showDialogError(true);
    } else {
      if (isEmptyObj(greenSelected)) {
        handleAddNewGreen();
      } else {
        handleUpdateGreen();
      }
    }
  }

  function unpackGeocoding(result) {
    var geometry = result[0].geometry;

    return {
      location: new GeoPoint(geometry.location.lat(), geometry.location.lng()),
      place: result[0].place_id,
      //    bounds: {Fa: geometry.bounds.Fa, ab: geometry.bounds.ab},
      name: result[0].formatted_address,
      address: result[0].address_components,
    };
  }

  async function handleAddNewGreen() {
    state.processing = processing;
    state.name = name;
    state.harvest_date = harvestDate;
    // console.log(origin);

    if (origin) {
      geocode({ address: origin })
        .then(async (result) => {
          const { results } = result;
          // console.log("geocoded results", results);
          state.origin = unpackGeocoding(results);

          // console.log("saving", state);
          var greenObj = state;
          var newGreen = await AddNewGreen({ greenObj });
          handleClose(newGreen.data);
        })
        .catch((error) => {
          setSnackOpen(true);
        });
    } else {
      //  console.log("saving", state);
      var greenObj = state;
      var newGreen = await AddNewGreen({ greenObj });
      handleClose(newGreen.data);
    }
  }

  async function handleUpdateGreen() {
    state.processing = processing;
    state.name = name;
    state.harvest_date = harvestDate;

    if (origin) {
      geocode({ address: origin })
        .then((result) => {
          const { results } = result;
          //     console.log("geocoded results", results);
          state.origin = unpackGeocoding(results);

          var greenObj = {
            name: state.name,
            processing: state.processing,
            harvest_date: state.harvest_date,
            origin: state.origin,
          };
          var id = greenSelected.id;

          // Set the "capital" field of the city 'DC'
          UpdateGreen({ id, greenObj });
          handleClose();
        })
        .catch((error) => {
          setSnackOpen(true);
        });
    } else {
      var greenObj = {
        name: state.name,
        processing: state.processing,
        harvest_date: state.harvest_date,
        origin: state.origin,
      };
      var id = greenSelected.id;

      UpdateGreen({ id, greenObj });
      handleClose();
    }
  }

  const onLocationChange = (val) => {
    // console.log(val);
    setOrigin(val ? val.description : "");
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);
        // console.log(greenSelected);

        setName(isEmptyObj(greenSelected) ? initialName : greenSelected.name);
        setProcessing(
          isEmptyObj(greenSelected)
            ? initialProcessing
            : greenSelected.processing
        );
        setHarvestDate(
          isEmptyObj(greenSelected) ? initialDate : greenSelected.harvest_date
        );
        setOrigin(isEmptyObj(greenSelected) ? "" : greenSelected.origin.name);

        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        console.log(error);
      }
    };

    queryAsync();
  }, [greenSelected]);

  return (
    <React.Fragment>
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
        <DialogTitle sx={{ fontWeight: 500, color: "secondary.main" }}>
          {isEmptyObj(greenSelected) ? "New Green" : "Edit Green"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {" "}
            Add your green details - at the heart of any good coffee, are the
            greens you start with.
          </DialogContentText>
          <Stack
            direction="row"
            spacing={2}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <Box sx={{ width: "50%", p: 5 }}>
              <Typography sx={{ mb: 3 }} color="secondary.main">
                Required
              </Typography>
              <TextField
                autoFocus
                id="name"
                name="name"
                label="Name"
                value={name}
                type="text"
                fullWidth
                required
                error={dialogError && name === ""}
                helperText={
                  dialogError && name === "" ? "Name is required" : " "
                }
                variant="filled"
                onChange={handleNameInputChange}
              />
            </Box>
            <Box sx={{ p: 5 }}>
              <Typography sx={{ mb: 3, color: "secondary.main" }}>
                Additional Info
              </Typography>
              <FormControl fullWidth sx={{ mt: 0 }} variant="outlined">
                <InputLabel id="processingLabel">Processing</InputLabel>
                <Select
                  labelId="processingLabel"
                  id="processing"
                  name="processing"
                  value={processing}
                  label="Processing"
                  onChange={handleSelectInputChange}
                >
                  <MenuItem value={"Washed"}>Washed</MenuItem>
                  <MenuItem value={"Semi Washed"}>Semi Washed</MenuItem>
                  <MenuItem value={"Natural"}>Natural</MenuItem>
                  <MenuItem value={"Honey"}>Honey</MenuItem>
                  <MenuItem value={"Pulped Natural"}>Pulped Natural</MenuItem>
                  <MenuItem value={"Decaf"}>Decaf</MenuItem>
                </Select>
              </FormControl>

              <Box sx={{ mt: 3 }}>
                <GoogleMaps
                  onChange={onLocationChange}
                  default_value={origin}
                />
              </Box>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ActionButton onClick={validateNewGreenThenAdd} disabled={name == ""}>
            {isEmptyObj(greenSelected) ? "Add" : "Update"}
          </ActionButton>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackClose}
          severity="warning"
          sx={{ width: "100%" }}
        >
          Could not find origin specified
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
