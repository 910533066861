import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";

import { useTheme, styled } from "@mui/material/styles";

import FormControlLabel from "@mui/material/FormControlLabel";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";

import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import TextField from "@mui/material/TextField";

import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { getFunctions, httpsCallable } from "firebase/functions";

import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";

import { isEmptyObj } from "../helpers/utility";

import { getAllNotes } from "../helpers/TasteNoteMapper";
import { Typography } from "@mui/material";

const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const functions = getFunctions();
const AddNewRoast = httpsCallable(functions, "AddNewRoast");

const ActionButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "20px",
  fontSize: "13px",
  height: "35px",
  backgroundColor: "rgba(104,10,239,0.7)",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,1)",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(165, 177, 194,0.3)",
  },
}));

export default function AddRoastDialog(props) {
  const { onClose, open, userName, greenId, roaster, roastSelected } = props;
  const [dialogError, showDialogError] = useState(false);

  var initialDegree = "";
  var initialName = "";
  var initialDesc = "";
  var initialTips = "";
  var initialNotes = [];
  var initialMinOffset = 0;
  var initialMaxOffset = 0;

  const [degree, setDegree] = useState(initialDegree);
  const [name, setName] = useState(initialName);
  const [desc, setDesc] = useState(initialDesc);
  const [tips, setTips] = useState(initialTips);
  const [notes, setNotes] = useState(initialNotes);

  const [minDateOffset, setMinDateOffset] = useState(initialMinOffset);
  const [maxDateOffset, setMaxDateOffset] = useState(initialMaxOffset);
  const [isRoastSelected, setIsRoastSelected] = useState(false);
  const [loading, setLoading] = useState(true);

  const theme = useTheme();

  var state = {
    name: name,
    roast_degree: degree,
    description: desc,
    brew_tips: tips,
    taste_notes: notes,
    green_id: greenId,
    user_id: userName,
    total_consumer_likes: 0,
    total_consumer_views: 0,
    roaster_id: roaster.id,
    min_suggested_date_offset: minDateOffset,
    max_suggested_date_offset: maxDateOffset,
  };

  const handleClose = (roast) => {
    //console.log(roast);
    showDialogError(false);
    setDegree("");
    setNotes([]);
    setName("");
    setDesc("");
    setTips("");
    setMinDateOffset(0);
    setMaxDateOffset(0);
    onClose(roast);
  };

  const handleSelectInputChange = (event) => {
    setDegree(event.target.value);
  };

  const handleNameInputChange = (event) => {
    setName(event.target.value);
  };

  const handleDescInputChange = (event) => {
    setDesc(event.target.value);
  };

  const handleBrewTipsInputChange = (event) => {
    setTips(event.target.value);
  };

  const handleNotesSelectInputChange = (event) => {
    //console.log(event.target.value);
    //setNotes(event.target.value);
  };

  const minOffsetChange = (event) => {
    var val = parseInt(event.target.value);
    if (val < 0) return;
    setMinDateOffset(val);
    if (val > parseInt(maxDateOffset)) {
      setMaxDateOffset(val);
    }
  };

  const maxOffsetChange = (event) => {
    var val = parseInt(event.target.value);
    if (val < 0) return;
    setMaxDateOffset(val);
    if (val < parseInt(minDateOffset)) setMinDateOffset(val);
  };

  function validateNewRoastThenAdd() {
    if (name === "" || degree === "" || notes.length < 1) {
      showDialogError(true);
    } else {
      if (isRoastSelected) {
        handleUpdateRoast();
      } else {
        handleAddNewRoast();
      }
    }
  }

  async function handleUpdateRoast() {
    const ref = doc(db, "Roasts", roastSelected.id);
    state.name = name;
    state.description = desc;
    state.brew_tips = tips;
    state.roast_degree = degree;
    state.taste_notes = notes;
    state.min_suggested_date_offset = parseInt(minDateOffset);
    state.max_suggested_date_offset = parseInt(maxDateOffset);
    //console.log("Update", state, roastSelected);

    await updateDoc(ref, {
      ...state,
    });

    state.id = roastSelected.id;
    handleClose(state);
  }

  async function handleAddNewRoast() {
    const ref = doc(collection(db, "Roasts"));
    state.name = name;
    state.description = desc;
    state.brew_tips = tips;
    state.roast_degree = degree;
    state.taste_notes = notes;
    state.min_suggested_date_offset = parseInt(minDateOffset);
    state.max_suggested_date_offset = parseInt(maxDateOffset);
    //console.log("saving", state);
    AddNewRoast(state).then((resp) => {
      var newObjId = resp.data;
      state.id = newObjId;
      handleClose(state);
    });
  }

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);
        //console.log(roastSelected);
        setIsRoastSelected(!isEmptyObj(roastSelected));

        setName(isEmptyObj(roastSelected) ? initialName : roastSelected.name);
        setDesc(
          isEmptyObj(roastSelected) ? initialDesc : roastSelected.description
        );
        setTips(
          isEmptyObj(roastSelected) ? initialTips : roastSelected.brew_tips
        );
        setDegree(
          isEmptyObj(roastSelected) ? initialDegree : roastSelected.roast_degree
        );
        setNotes(
          isEmptyObj(roastSelected) ? initialNotes : roastSelected.taste_notes
        );
        setMinDateOffset(
          isEmptyObj(roastSelected)
            ? initialMinOffset
            : roastSelected.min_suggested_date_offset
        );
        setMaxDateOffset(
          isEmptyObj(roastSelected)
            ? initialMaxOffset
            : roastSelected.max_suggested_date_offset
        );

        //console.log("notes obj", notes.length);

        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        //console.log(error);
      }
    };

    queryAsync();
  }, [roastSelected]);

  return (
    <Dialog onClose={() => handleClose()} open={open} fullWidth maxWidth="md">
      <DialogTitle sx={{ fontWeight: 500, color: "secondary.main" }}>
        {isRoastSelected ? "Edit Roast" : "New Roast"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {" "}
          Add your roast details - track what's roasting and get your customers
          the data they need.
        </DialogContentText>
        <Stack
          direction="row"
          spacing={3}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <Box width="50%" sx={{ p: 5 }}>
            <Stack spacing={3}>
              <Typography color="secondary.main">Required</Typography>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                value={name}
                error={dialogError && name === ""}
                fullWidth
                variant="filled"
                onChange={handleNameInputChange}
              />
              <FormControl fullWidth sx={{ mt: 0 }} variant="outlined">
                <InputLabel id="degreeLabel">Degree</InputLabel>
                <Select
                  labelId="degreeLabel"
                  id="degree"
                  name="degree"
                  value={degree}
                  label="Degree"
                  onChange={handleSelectInputChange}
                  error={dialogError && degree === ""}
                  required
                >
                  <MenuItem value={"Not Specified"}>Not Specified</MenuItem>
                  <MenuItem value={"Light"}>Light</MenuItem>
                  <MenuItem value={"Light-Medium"}>Light-Medium</MenuItem>
                  <MenuItem value={"Medium"}>Medium</MenuItem>
                  <MenuItem value={"Medium-Dark"}>Medium-Dark</MenuItem>
                  <MenuItem value={"Dark"}>Dark</MenuItem>
                </Select>
              </FormControl>
              <Autocomplete
                multiple
                id="tags-outlined"
                options={getAllNotes()}
                value={notes}
                onChange={(event, newValue) => {
                  setNotes(newValue);
                }}
                getOptionLabel={(option) => option}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Taste Notes"
                    placeholder="Notes"
                    error={dialogError && notes.length < 1}
                  />
                )}
              />
            </Stack>
          </Box>
          <Box width="50%" sx={{ p: 5 }}>
            <Stack spacing={3}>
              <Typography color="secondary.main">Additional Info</Typography>
              <TextField
                margin="dense"
                id="description"
                name="description"
                label="Description"
                type="text"
                value={desc}
                fullWidth
                variant="filled"
                onChange={handleDescInputChange}
                multiline
                rows={4}
              />
              <TextField
                margin="dense"
                id="tips"
                name="tips"
                label="Brew Tips"
                type="text"
                value={tips}
                fullWidth
                variant="filled"
                onChange={handleBrewTipsInputChange}
                multiline
                rows={4}
              />
              <Box>
                <Typography color="text.secondary">
                  Best between days after roast{" "}
                </Typography>
                <Stack direction="row" spacing={0}>
                  <TextField
                    margin="dense"
                    id="minDateOffset"
                    name="minDateOffset"
                    label="From"
                    type="number"
                    value={minDateOffset}
                    InputLabelProps={{ shrink: true }}
                    onChange={minOffsetChange}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">days</InputAdornment>
                      ),
                    }}
                    sx={{ width: "35%" }}
                  />
                  <TextField
                    margin="dense"
                    id="maxDateOffset"
                    name="maxDateOffset"
                    label="To"
                    type="number"
                    value={maxDateOffset}
                    InputLabelProps={{ shrink: true }}
                    onChange={maxOffsetChange}
                    variant="standard"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">days</InputAdornment>
                      ),
                    }}
                    sx={{ ml: 2, width: "35%" }}
                  />
                </Stack>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()}>Cancel</Button>
        <ActionButton
          onClick={validateNewRoastThenAdd}
          disabled={name == "" || degree == "" || notes.length < 1}
        >
          {isRoastSelected ? "Update" : "Add"}
        </ActionButton>
      </DialogActions>
    </Dialog>
  );
}
