import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { sortArrayBy } from "../helpers/utility";
import { Divider, IconButton } from "@mui/material";
import { PinIcon, UnPinIcon } from "../assets/PinIcon";

export default function GreensSidebar({ greens, greenClick, pinCallback }) {
  const [idxItemClicked, setIdxItemClicked] = React.useState({ val: -1 }); // needs to be an obj so we can rerender when same idx is set on pin

  var pinnedGreens = [];
  var notPinned = [];

  const handleClick = (green, idx) => {
    //  console.log(idx);
    // console.log(green);
    setIdxItemClicked({ val: idx });
    greenClick(green);
  };

  const handlePinClicked = (green, idx) => {
    var newPinned = [];
    var newNotPinned = [];

    // figure out what green was selected
    var selGreen;
    if (idxItemClicked.val > pinnedGreens.length - 1) {
      console.log(notPinned);
      selGreen = notPinned[idxItemClicked.val - pinnedGreens.length];
    } else {
      selGreen = pinnedGreens[idxItemClicked.val];
    }

    // create new pinned and not pinned lists
    greens.forEach((obj) => {
      if (obj.id == green.id || obj.pinned == true) {
        obj.pinned = true;
        newPinned.push(obj);
      } else {
        newNotPinned.push(obj);
      }
    });

    pinnedGreens = sortArrayBy(newPinned, "name", true);
    notPinned = sortArrayBy(newNotPinned, "name", true);

    //figure out what should be selected. if nothing was before, keep nothing. else find which list it is in and reselect it.
    if (idxItemClicked.val >= 0) {
      var isInPin = pinnedGreens.findIndex((obj) => obj.id == selGreen.id);
      var isInNotPin = notPinned.findIndex((obj) => obj.id == selGreen.id);

      if (isInPin >= 0) setIdxItemClicked({ val: isInPin });
      if (isInNotPin >= 0)
        setIdxItemClicked({ val: isInNotPin + pinnedGreens.length });
    } else {
      setIdxItemClicked({ val: idxItemClicked.val });
    }

    pinCallback(green.id);
  };

  const handleUnPinClicked = (green, idx) => {
    var newPinned = [];
    var newNotPinned = [];

    // figure out what green was selected
    var selGreen;
    if (idxItemClicked.val > pinnedGreens.length - 1) {
      // console.log(notPinned);
      selGreen = notPinned[idxItemClicked.val - pinnedGreens.length];
    } else {
      selGreen = pinnedGreens[idxItemClicked.val];
    }

    // create new pinned and not pinned lists
    greens.forEach((obj) => {
      if (obj.id == green.id || !obj.pinned) {
        obj.pinned = false;
        newNotPinned.push(obj);
      } else {
        newPinned.push(obj);
      }
    });

    pinnedGreens = sortArrayBy(newPinned, "name", true);
    notPinned = sortArrayBy(newNotPinned, "name", true);

    //figure out what should be selected. if nothing was before, keep nothing. else find which list it is in and reselect it.
    if (idxItemClicked.val >= 0) {
      var isInPin = pinnedGreens.findIndex((obj) => obj.id == selGreen.id);
      var isInNotPin = notPinned.findIndex((obj) => obj.id == selGreen.id);

      if (isInPin >= 0) setIdxItemClicked({ val: isInPin });
      if (isInNotPin >= 0)
        setIdxItemClicked({ val: isInNotPin + pinnedGreens.length });
    } else {
      setIdxItemClicked({ val: idxItemClicked.val });
    }

    pinCallback(green.id);
  };

  greens.forEach((green) => {
    if (green.pinned == true) pinnedGreens.push(green);
    else notPinned.push(green);
  });

  pinnedGreens = sortArrayBy(pinnedGreens, "name", true);
  notPinned = sortArrayBy(notPinned, "name", true);

  //<Card key={row.id} sx={{ p: 1, mt:1, ml:0, display: 'flex', flexDirection: 'column', width:"100%", cursor:"pointer"}} onClick={() => handleClick(row)} elevation={1}>
  //      <CardContent sx={{p:1}}>
  //      </CardContent>
  //    </Card>

  return (
    <React.Fragment>
      {pinnedGreens.length > 0 && (
        <Typography variant="body2" sx={{ mt: 2 }}>
          <PinIcon
            sx={{
              fontSize: "19px",
              display: "inline",
            }}
            viewBox="-100 -250 650 650"
          />
          Pinned
        </Typography>
      )}
      {sortArrayBy(pinnedGreens, "name", true).map((row, idx) => (
        <React.Fragment key={"pinned" + idx}>
          <Box className="showHiddenHovers" sx={{ position: "relative" }}>
            <Button
              key={row.name + idx}
              onClick={() => handleClick(row, idx)}
              sx={{
                cursor: "pointer",
                mt: 0.75,
                width: "100%",
                pt: 1,
                flexDirection: "column",
                alignItems: "flex-start",
                textAlign: "left",
                textTransform: "inherit",
              }}
              style={
                idxItemClicked.val === idx
                  ? {
                      backgroundColor: "rgba(116, 185, 255,0.2)",
                      borderRadius: "14px",
                    }
                  : {}
              }
            >
              <Typography variant="subtitle1" color="text.primary">
                {row.name}
              </Typography>

              <Typography variant="subtitle2" color="text.secondary">
                {new Date(row.in_stock_date).toDateString()}
              </Typography>
            </Button>
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                handleUnPinClicked(row, idx + pinnedGreens.length);
              }}
              className="hideUntilHover"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <UnPinIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          </Box>
        </React.Fragment>
      ))}
      {pinnedGreens.length > 0 && <Divider sx={{ m: 2 }} />}
      {sortArrayBy(notPinned, "name", true).map((row, idx) => (
        <React.Fragment key={"unpinned" + idx}>
          <Box className="showHiddenHovers" sx={{ position: "relative" }}>
            <Button
              key={row.name + idx + pinnedGreens.length}
              onClick={() => handleClick(row, idx + pinnedGreens.length)}
              sx={{
                cursor: "pointer",
                mt: 0.75,
                width: "100%",
                pt: 1,
                flexDirection: "column",
                alignItems: "flex-start",
                textAlign: "left",
                textTransform: "inherit",
              }}
              style={
                idxItemClicked.val === idx + pinnedGreens.length
                  ? {
                      backgroundColor: "rgba(116, 185, 255,0.2)",
                      borderRadius: "14px",
                    }
                  : {}
              }
            >
              <Typography variant="subtitle1" color="text.primary">
                {row.name}
              </Typography>

              <Typography variant="subtitle2" color="text.secondary">
                {new Date(row.in_stock_date).toDateString()}
              </Typography>
            </Button>
            <IconButton
              onClick={(event) => {
                event.preventDefault();
                handlePinClicked(row, idx + pinnedGreens.length);
              }}
              className="hideUntilHover"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <PinIcon sx={{ fontSize: "18px" }} />
            </IconButton>
          </Box>
        </React.Fragment>
      ))}
    </React.Fragment>
  );
}
