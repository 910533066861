import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import CircularLoader from "../CircularLoader";
import LoadingLogo from "./LoadingLogo";

import { getFunctions, httpsCallable } from "firebase/functions";
const functions = getFunctions();
const getData = httpsCallable(functions, "GetNewsAll");

export default function News({}) {
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);

        getData().then((response) => {
          setData(response.data.news);
        });
        //   console.log(data);
        // switch loading to false after fetch is complete
        setLoading(false);
      } catch (error) {
        // add error handling here
        setLoading(false);
        setError(true);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  // return a Spinner when loading is true
  if (loading)
    return (
      <React.Fragment>
        <LoadingLogo message="Loading News..." />
      </React.Fragment>
    );

  // data will be null when fetch call fails
  if (error)
    return (
      <React.Fragment>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={7}>
            <Typography sx={{ fontWeight: 600, color: "#5f27cd" }}>
              Whoops, looks like something went wrong. Sorry!
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <Paper
        sx={{ p: 2, display: "flex", flexDirection: "column" }}
        elevation={2}
      >
        <Typography variant="subtitle1">News</Typography>
        <Grid container spacing={1} sx={{ mt: 0 }}>
          {data.map((newItem, idx) => (
            <Grid key={idx} item xs={12} sx={{ ml: 2 }}>
              <Typography sx={{ fontSize: 12 }} color="text.primary">
                <strong>{newItem.title}</strong>
              </Typography>
              <Typography sx={{ fontSize: 11 }} color="text.primary">
                {newItem.description}
              </Typography>
              <Typography sx={{ fontSize: 11 }} color="text.secondary">
                {new Date(newItem.date_added._seconds * 1000).toLocaleString()}{" "}
                - {newItem.author}
              </Typography>

              <Divider sx={{ width: "100%", mt: 1 }} />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
