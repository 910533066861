import React, { useState, useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";

import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";

import { initializeApp } from "firebase/app";
import {
  getFunctions,
  httpsCallable,
  connectFunctionsEmulator,
} from "firebase/functions";

import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import LoadingLogo from "./components/LoadingLogo";

import { sortArrayBy, isEmptyObj } from "./helpers/utility";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Timer from "./components/Timer";
import SpeedField from "./components/SpeedField";
import CopilotLineGraph from "./components/CopilotLineGraph";

import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import UploadProfile from "./components/UploadProfile";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { Upload } from "@mui/icons-material";

const firebaseConfig = {
  apiKey: "AIzaSyBIb-iJqfRp9LUWNxb2vMqzwTPX-5Tw3Nc",
  authDomain: "haste-roastery.firebaseapp.com",
  databaseURL: "https://haste-roastery-default-rtdb.firebaseio.com",
  projectId: "haste-roastery",
  storageBucket: "haste-roastery.appspot.com",
  messagingSenderId: "106586592618",
  appId: "1:106586592618:web:125d4acaf8b6827af68113",
  measurementId: "G-0VWZ2CV5W6",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const functions = getFunctions();
const GetCopilotRoastOptions = httpsCallable(
  functions,
  "GetCopilotRoastOptions"
);
const QueueNewBatch = httpsCallable(functions, "QueueNewBatch");
const GetBatchById = httpsCallable(functions, "GetBatchById");
const GetGreenById = httpsCallable(functions, "GetGreenById");
const CreateNewBatch = httpsCallable(functions, "CreateNewBatch");
const DeleteBatchById = httpsCallable(functions, "DeleteBatchById");

//const functions = getFunctions(getApp());
//connectFunctionsEmulator(functions, "localhost", 5001);

const ManualModeSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 28,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="-200 -200 1200 1200"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M151.43,120c2.71,0,5.5,.35,8.31,1.11l415.98,111.46c23.47,6.29,31.33,35.62,14.15,52.8l-106.54,106.54,176.9,176.46c25.3,25.24,25.35,66.21,.11,91.52-12.64,12.67-29.23,19.01-45.82,19.01s-33.07-6.3-45.7-18.9l-177.02-176.58-106.46,106.46c-6.33,6.33-14.32,9.26-22.19,9.26-13.48,0-26.64-8.6-30.61-23.41L121.09,159.76c-5.53-20.65,10.43-39.76,30.34-39.76m0-120c-23.72,0-47.44,5.7-68.6,16.49-20.02,10.21-37.85,25.04-51.55,42.9C17.61,77.2,7.93,98.19,3.27,120.08c-4.93,23.22-4.28,47.68,1.9,70.74l111.46,415.98c8.92,33.3,29.1,62.8,56.81,83.07,13,9.5,27.36,16.85,42.7,21.84,15.21,4.94,31.03,7.45,47,7.45,19.52,0,38.57-3.7,56.63-11,18.93-7.65,35.89-18.89,50.41-33.41l21.72-21.72,92.16,91.94c17.59,17.54,38.15,31.15,61.11,40.45,22.12,8.95,45.45,13.49,69.34,13.49s47.37-4.57,69.55-13.58c23.03-9.35,43.62-23.04,61.23-40.69,17.57-17.61,31.19-38.21,40.49-61.21,8.95-22.16,13.48-45.53,13.45-69.46-.03-23.94-4.61-47.3-13.62-69.43-9.35-22.98-23.03-43.54-40.64-61.11l-91.84-91.61,21.58-21.58c19.49-19.49,33.29-43.84,39.92-70.44,6.2-24.86,5.96-51.13-.69-75.96-6.65-24.83-19.58-47.69-37.38-66.13-19.04-19.72-43.17-33.91-69.79-41.04L190.8,5.2c-12.87-3.45-26.12-5.2-39.37-5.2h0Z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "rgba(156, 39, 176, 0.2)",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#9c27b0",
    width: 25,
    height: 25,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="-175 -175 1200 1200"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M433.5,867c-58.5,0-115.27-11.47-168.76-34.09-51.63-21.84-97.98-53.09-137.77-92.88-39.79-39.79-71.04-86.15-92.88-137.77C11.47,548.77,0,492,0,433.5s11.47-115.27,34.09-168.76c21.84-51.63,53.09-97.98,92.88-137.77,39.79-39.79,86.15-71.04,137.77-92.88C318.23,11.47,375,0,433.5,0s115.27,11.47,168.76,34.09c51.63,21.84,97.98,53.09,137.77,92.88,39.79,39.79,71.04,86.15,92.88,137.77,22.62,53.48,34.09,110.26,34.09,168.76s-11.47,115.27-34.09,168.76c-21.84,51.63-53.09,97.98-92.88,137.77-39.79,39.79-86.15,71.04-137.77,92.88-53.48,22.62-110.26,34.09-168.76,34.09Zm0-747c-83.74,0-162.47,32.61-221.68,91.82-59.21,59.21-91.82,137.94-91.82,221.68s32.61,162.47,91.82,221.68c59.21,59.21,137.94,91.82,221.68,91.82s162.47-32.61,221.68-91.82c59.21-59.21,91.82-137.94,91.82-221.68s-32.61-162.47-91.82-221.68c-59.21-59.21-137.94-91.82-221.68-91.82Z"/><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M586.62,631.12c-11.52,0-23.03-4.39-31.82-13.18l-147.23-147.23c-11.67-8.13-19.31-21.65-19.31-36.95V217.75c0-24.85,20.15-45,45-45s45,20.15,45,45v196.36l140.19,140.19c17.57,17.57,17.57,46.07,0,63.64-8.79,8.79-20.3,13.18-31.82,13.18Z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "rgba(156, 39, 176, 0.2)",
    borderRadius: 20 / 2,
  },
}));

const ActionButton = styled(Button)(({ theme }) => ({
  color: "white",
  borderRadius: "20px",
  fontSize: "13px",
  height: "35px",
  backgroundColor: "rgba(104,10,239,0.7)",
  "&:hover": {
    backgroundColor: "rgba(104,10,239,1)",
  },
  "&.Mui-disabled": {
    backgroundColor: "rgba(165, 177, 194,0.3)",
  },
}));

function preventDefault(event) {
  event.preventDefault();
}

var lastTrend = { time: 0, bt: 0, ror: 0 };
var newTrend = [];
const trendDefault = [{}];
const defaultMinTemp = 150;
const defaultMaxTemp = 450;
const defaultMaxTime = 960; // 12 mins
var currentUser = "";

export default function Copilot({ roaster, userData }) {
  var roaster_id = roaster.id;

  const btnDefault = [
    { label: "Charge", event_id: 0, isDisabled: false },
    { label: "Dry End", event_id: 1, isDisabled: false },
    { label: "First Crack", event_id: 2, isDisabled: false },
    { label: "FC End", event_id: 3, isDisabled: false },
    { label: "Second Crack", event_id: 4, isDisabled: false },
    { label: "Drop", event_id: 5, isDisabled: false },
  ];

  const { batchID } = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [timerStatus, setTimerStatus] = useState({});
  const [speedVal, setSpeedVal] = useState("");
  const [trendData, setTrendData] = useState([{}]);
  const [manualMode, setManualMode] = useState(false);
  const [timelessMode, setTimelessMode] = useState(false);
  const [graphKey, setGraphKey] = useState(
    trendData.length + getRandomInt(1, 99999999)
  );
  const [reset, setReset] = useState(false);
  const [speedDisabled, setSpeedDisabled] = useState(true);
  const [eventLines, setEventLines] = useState([{}]); //{time:1, label:"test", color:"rgba(95, 39, 205,1)"}
  const [eventButtons, setEventButtons] = useState(btnDefault);
  const [inQueue, setInQueue] = useState([]);
  const [roastList, setRoastList] = useState([]);
  const [currentRoast, setCurrentRoast] = useState({});
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [startDisabled, setStartDisabled] = useState(false);
  const [manualButtonValue, setManualButtonValue] = useState("upload");
  const [dotHovered, setDotHovered] = useState({});
  const [timelessEventActive, setTimelessEventActive] = useState({});

  const [snackOpen, setSnackOpen] = React.useState(false);

  const inputRef = React.createRef();

  const { sub } = userData;
  currentUser = sub;

  var newTrendVal = {};

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
  }

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const dotHandler = (dot) => {
    var newTrend = [];
    //console.log("click");
    newTrend.push(...trendData);

    var dotIndex = newTrend.findIndex((obj) => obj.time == dot.x);
    var dotObj = {
      time: dot.x,
      bt: dot.y,
      ror: parseInt(lastTrend.y - parseInt(dot.y)) || 0, // || 0 to catch NaN case
    };

    if (!isEmptyObj(timelessEventActive)) {
      eventLines.push({
        time: dotObj.time,
        label: timelessEventActive.label,
        color: "rgba(95, 39, 205,1)",
        event_id: timelessEventActive.event_id,
      });

      var copybtn = eventButtons;
      var idx = copybtn.findIndex(
        (x) => x.event_id === timelessEventActive.event_id
      );
      copybtn[idx].isDisabled = true;

      setEventButtons(copybtn);
      setTimelessEventActive({});
    } else {
      if (dotIndex > 0) {
        if (newTrend[dotIndex].bt == dotObj.bt) {
          // remove
          newTrend.splice(dotIndex, 1);
        } else {
          // update
          newTrend[dotIndex] = dotObj;
        }
      } else {
        newTrend.push(dotObj); //add
      }
      setTrendData(newTrend);
      lastTrend = dot;
    }

    setSaveEnabled(true);
  };

  const handleDotHover = (dot) => {
    //  console.log(dot);
    setDotHovered(dot);
  };

  const handleDotHoverLeave = () => {
    //  console.log("out");
    setDotHovered({});
  };

  const mouseHoverHandler = (e) => {
    // console.log(e);
  };

  const handleEventMouse = (e) => {
    //  console.log(e);
  };

  const toggleManual = (event, mode) => {
    if (!mode) return; // same mode was clicked

    const newMode = !manualMode;

    if (newMode) {
      // manual mode
      setManualButtonValue("manual");
    } else {
      setManualButtonValue("upload");
    }

    setManualMode(newMode);
  };

  const toggleManualTimeless = () => {
    setTimelessMode(!timelessMode);
  };

  const handleTimerReset = () => {
    setTrendData([{}]);
    setSpeedVal("");
    setEventLines([]);
    setEventButtons(btnDefault);
    setSaveEnabled(false);
    setStartDisabled(false);
  };

  const toggleSpeed = () => {
    setSpeedDisabled(!speedDisabled);
  };

  const handleTimerStop = () => {
    toggleSpeed();
    setSaveEnabled(true);
  };

  const handleTimerStart = () => {
    toggleSpeed();
    setSaveEnabled(false);
  };

  const handleEventButton = (event_btn) => {
    var copybtn = eventButtons;
    var idx = copybtn.findIndex((x) => x.event_id === event_btn.event_id);
    if (timelessMode) {
      if (timelessEventActive.event_id != event_btn.event_id)
        setTimelessEventActive(event_btn);
      else setTimelessEventActive({});
    } else {
      // timer mode
      for (var i = 0; i <= idx; i += 1) {
        copybtn[i].isDisabled = true;
      }
      setEventButtons(copybtn);
      eventLines.push({
        time: timerStatus.sec_elapsed,
        label: event_btn.label,
        color: "rgba(95, 39, 205,1)",
        event_id: event_btn.event_id,
      });
      speedFocus();
    }
  };

  const getTimer = (timer) => {
    //   console.log(timer)
    setTimerStatus(timer);
  };

  const onSpeedEnter = (val) => {
    setSpeedVal(val);
    newTrend = trendData;
    newTrendVal = {
      time: timerStatus.sec_elapsed,
      bt: parseInt(val),
      ror: parseInt(lastTrend.bt - parseInt(val)),
    };
    newTrend.push(newTrendVal);
    setTrendData(newTrend);

    lastTrend = newTrendVal;

    speedFocus();
  };

  const speedFocus = () => {
    inputRef.current.focus();
  };

  const handleRoastChange = (roast) => {
    // console.log(roast);
    setCurrentRoast(roast);
  };

  const saveManualHandler = () => {
    // console.log(currentRoast);
    var saveManualStats = packManualInputs();
    var newBatch = {
      batch_num: Math.floor(Math.random() * 999999999),
      date_roasted: new Date(),
      green_id: currentRoast.green_id,
      profile_url: "",
      roast_id: currentRoast.id,
      manual_mode: manualMode,
      stats: {
        ...saveManualStats,
      },
      status: "Done",
      user_id: currentUser,
      consumer_likes: 0,
      roaster_id: roaster.id,
    };
    console.log("saving", newBatch);

    CreateNewBatch(newBatch);
    DeleteBatchById(currentRoast.id);
    setSaveEnabled(false);
    setStartDisabled(true);
    setSnackOpen(true);
  };

  const packManualInputs = () => {
    /*
  var packed = {
    charge_temp: manualInputs.chargeTemp,
    tp_temp: {temp:null, time: null},
    dry_end: {temp:manualInputs.deTemp, time: manualInputs.deTimeM*60 + manualInputs.deTimeS},
    fc_temp: {temp:manualInputs.fcTemp, time: manualInputs.fcTimeM*60 + manualInputs.fcTimeS},
    sc_temp: {temp:manualInputs.scTemp, time: manualInputs.scTimeM*60 + manualInputs.scTimeS},
    drop_temp: {temp:manualInputs.dropTemp, time: manualInputs.dropTimeM*60 + manualInputs.dropTimeS}
  }
  */
    var charge_time = eventLines.find((x) => x.event_id === 0);
    var de_time = eventLines.find((x) => x.event_id === 1);
    var fc_time = eventLines.find((x) => x.event_id === 2);
    var fce_time = eventLines.find((x) => x.event_id === 3);
    var sc_time = eventLines.find((x) => x.event_id === 4);
    var drop_time = eventLines.find((x) => x.event_id === 5);

    var packed = {
      charge_temp: null,
      tp_temp: { temp: null, time: null },
      dry_end: { temp: null, time: de_time ? de_time.time : null },
      fc_temp: { temp: null, time: fc_time ? fc_time.time : null },
      fce_temp: { temp: null, time: fce_time ? fce_time.time : null },
      sc_temp: { temp: null, time: sc_time ? sc_time.time : null },
      drop_temp: { temp: null, time: drop_time ? drop_time.time : null },
      bt_trend: trendData,
    };

    return packed;
  };

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);

        var q = query(
          collection(db, "Batches"),
          where("roaster_id", "==", roaster.id),
          where("status", "in", [
            "Not Started",
            "In Progress",
            "In Progress - Roasted",
          ])
        );
        const unsubscribe = onSnapshot(
          q,
          (querySnapshot) => {
            var inQueue = [];
            if (querySnapshot.size == 0) {
              setInQueue(inQueue);
            } else {
              var data;
              querySnapshot.forEach((doc) => {
                data = doc.data();
                data["id"] = doc.id;
                data.datetime_added = new Date(data.datetime_added);
                data.type = "Batch";
                inQueue.push(data);
              });
              setInQueue(sortArrayBy(inQueue, "datetime_added", true));
              // console.log(inQueue);
            }
          },
          (error) => {
            console.log(error);
          }
        );

        // switch loading to false after fetch is complete
      } catch (error) {
        // add error handling here
        setLoading(false);
        setError(true);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  useEffect(() => {
    const queryAsync = async () => {
      try {
        // set loading to true before calling API
        setLoading(true);

        GetCopilotRoastOptions({ roaster_id }).then((resp) => {
          var queue = resp.data.inQueue;
          var queueArray = queue.map((obj) => {
            obj.datetime_added = new Date(obj.datetime_added);
            obj.type = "Batch";
            return obj;
          });

          var roasts = resp.data.roasts;
          var roastArray = roasts.map((obj) => {
            obj.type = "Roast";
            return obj;
          });

          setInQueue(sortArrayBy(queueArray, "datetime_added", true));
          setRoastList(sortArrayBy(roastArray, ["name"]));
          setLoading(false);
        });
        // switch loading to false after fetch is complete
      } catch (error) {
        // add error handling here
        setLoading(false);
        setError(true);
        console.log(error);
      }
    };

    queryAsync();
  }, []);

  {
    //#region data change handlers
  }
  useEffect(() => {
    const queryAsync = async () => {
      if (!speedDisabled) speedFocus();
    };

    queryAsync();
  }, [speedDisabled]);

  useEffect(() => {
    const queryAsync = async () => {
      handleTimerReset();
      if (!manualMode) setTimelessMode(false);
    };

    queryAsync();
  }, [manualMode]);

  useEffect(() => {
    const queryAsync = async () => {
      //  console.log("newData");
    };

    queryAsync();
  }, [trendData]);

  useEffect(() => {
    const queryAsync = async () => {
      // console.log(inQueue, roastList);
      var matchIdx;

      if (batchID) {
        matchIdx = inQueue.findIndex((x) => x.id === batchID);
        // GetBatchById(batchID).then((batchobj) => {
        //     var matchIdx = inQueue.findIndex(x => x.id === batchobj.);
        //    setCurrentRoast(batchobj.data)
        //  })

        //        setCurrentRoast()
      }

      setCurrentRoast(
        matchIdx
          ? inQueue[matchIdx]
          : inQueue.length > 0
          ? inQueue[0]
          : roastList.length > 0
          ? roastList[0]
          : {}
      );
    };

    queryAsync();
  }, [roastList, inQueue]);

  {
    //#endregion
  }

  // <Typography>{timerStatus.status} {timerStatus.sec_elapsed} {speedVal}</Typography>

  function RoastSelect({ inQueue, roasts, onChange }) {
    //  console.log("RoastSelect", currentRoast, inQueue, roasts);
    return (
      <div>
        <FormControl
          size="small"
          sx={{ m: 1, minWidth: 200 }}
          variant="standard"
        >
          <InputLabel htmlFor="grouped-select">Roast</InputLabel>
          <Select
            defaultValue={0}
            value={isEmptyObj(currentRoast) ? 0 : currentRoast}
            id="grouped-select"
            label="Grouping"
            onChange={(e) => onChange(e.target.value)}
          >
            <MenuItem
              value={0}
              disabled
              sx={{ fontSize: 12, color: "text.secondary" }}
            ></MenuItem>
            <ListSubheader sx={{ fontWeight: 600, color: "secondary.main" }}>
              In Queue
            </ListSubheader>
            {inQueue.length <= 0 && (
              <MenuItem key={"noQ"} disabled sx={{ fontSize: 12 }}>
                None in queue
              </MenuItem>
            )}
            {inQueue.map((row, idx) => (
              <MenuItem key={"inQ" + idx} value={row} sx={{ fontSize: 13 }}>
                {row.name}
              </MenuItem>
            ))}
            <ListSubheader sx={{ fontWeight: 600, color: "secondary.main" }}>
              Roasts
            </ListSubheader>
            {roasts.map((row, idx) => (
              <MenuItem key={"roasts" + idx} value={row} sx={{ fontSize: 13 }}>
                {row.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  // return a Spinner when loading is true
  if (loading)
    return (
      <React.Fragment>
        <LoadingLogo message="Loading roasts..." />
      </React.Fragment>
    );

  // console.log(roastList);

  if (roastList.length < 1) {
    return (
      <React.Fragment>
        <Container
          maxWidth={false}
          sx={{
            mt: 4,
            mb: 4,
            ml: 15,
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Box sx={{ width: "50%" }}>
            <Stack direction="column" spacing={1}>
              <Typography
                variant="h3"
                sx={{ color: "#4F21AB", fontWeight: 600 }}
              >
                Create a new roast
              </Typography>
              <Typography variant="h6" sx={{ pb: 2, color: "#243B53" }}>
                Before we can get going, let's get the building blocks in place.
                Head over to the Roasts activity to set up a roast.
              </Typography>
              <NavLink
                to={"/roastery/roasts"}
                style={{ textDecoration: "none", width: 200 }}
              >
                <ActionButton variant="contained" sx={{}} fullWidth>
                  Take me to Roasts
                </ActionButton>
              </NavLink>
            </Stack>
          </Box>
        </Container>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Container maxWidth={false} sx={{ mt: 4, mb: 4, height: "100%" }}>
        <Stack direction="row">
          <RoastSelect
            inQueue={inQueue}
            roasts={roastList}
            onChange={handleRoastChange}
          />
          <Box sx={{ width: "100%" }}>
            <Stack
              direction="row"
              sx={{ justifyContent: "flex-end", alignItems: "center" }}
            >
              {manualMode && (
                <React.Fragment>
                  {manualMode && (
                    <React.Fragment>
                      <ActionButton
                        disabled={!saveEnabled}
                        onClick={saveManualHandler}
                        sx={{ mr: 1 }}
                      >
                        Save
                      </ActionButton>
                    </React.Fragment>
                  )}
                  <FormControlLabel
                    control={
                      <ManualModeSwitch
                        sx={{ m: 0.25 }}
                        onChange={toggleManualTimeless}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: 12 }}>
                        {timelessMode ? "Click" : "Timer"}
                      </Typography>
                    }
                    labelPlacement="bottom"
                    sx={{ mr: 3 }}
                  />
                </React.Fragment>
              )}
              <ToggleButtonGroup
                value={manualButtonValue}
                exclusive
                onChange={toggleManual}
                aria-label="profile mode"
                sx={{ height: "50%" }}
                color="secondary"
              >
                <ToggleButton value="upload" aria-label="Profile Upload">
                  <UploadFileIcon />
                </ToggleButton>
                <ToggleButton value="manual" aria-label="Manual Mode">
                  <AssessmentIcon />
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </Box>
        </Stack>
        <Divider sx={{ mt: 1 }} />

        <Grid container height="100%">
          {!manualMode && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ height: "40vh", ml: -2 }}>
                <UploadProfile
                  roaster={roaster}
                  userName={currentUser}
                  batchOrRoast={currentRoast}
                />
              </Box>
            </Grid>
          )}

          {manualMode && (
            <React.Fragment>
              <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ height: "55vh", width: "100%", ml: -2 }}>
                  <CopilotLineGraph
                    data={trendData}
                    eventLines={eventLines}
                    timelessMode={timelessMode}
                    dotHandler={dotHandler}
                    handleDotHover={handleDotHover}
                    handleDotHoverLeave={handleDotHoverLeave}
                    mouseHoverHandler={mouseHoverHandler}
                    referenceDotHover={dotHovered}
                    handleEventMouse={handleEventMouse}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ mt: -5 }}>
                <Grid container>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {!timelessMode && (
                      <Timer
                        timeCallback={getTimer}
                        onReset={handleTimerReset}
                        onStart={handleTimerStart}
                        onStop={handleTimerStop}
                        startDisabled={startDisabled}
                      />
                    )}
                    {timelessMode && (
                      <Button onClick={handleTimerReset}>Reset</Button>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Stack>
                      {manualMode && timelessMode && (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            color: "text.secondary",
                          }}
                        >
                          <Stack>
                            <Typography color="rgba(104,10,239,1)">
                              {isEmptyObj(timelessEventActive)
                                ? ""
                                : "Adding: " + timelessEventActive.label}
                            </Typography>
                            <Typography sx={{}}>
                              Temp: {dotHovered.y ? dotHovered.y : "-"}
                              &nbsp;&nbsp; Time:{" "}
                              {dotHovered.x ? dotHovered.x : "-"}
                            </Typography>
                          </Stack>
                        </Box>
                      )}
                      {manualMode && !timelessMode && (
                        <Box
                          className="speedTextField"
                          sx={{
                            width: 300,
                            maxWidth: "100%",
                          }}
                        >
                          <SpeedField
                            onEnter={onSpeedEnter}
                            isDisabled={speedDisabled}
                            inputRef={inputRef}
                          />
                          <Typography
                            sx={{
                              fontSize: 13,
                              textAlign: "center",
                              opacity: "0.7",
                            }}
                          >
                            Bean Temp
                          </Typography>
                        </Box>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid
                      container
                      sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                      spacing={0.5}
                    >
                      {eventButtons.map((btn, i) => (
                        <Grid key={i} item xs={6}>
                          <ActionButton
                            fullWidth
                            size="small"
                            disabled={
                              (speedDisabled && !timelessMode) || btn.isDisabled
                            }
                            xs={{
                              height: "100%",
                              fontSize: 12,
                            }}
                            variant="contained"
                            onClick={() => handleEventButton(btn)}
                          >
                            {btn.label}
                          </ActionButton>
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        <Typography sx={{ fontSize: 13, opacity: "0.7" }}>
                          Events
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Grid>

        <Snackbar
          open={snackOpen}
          autoHideDuration={3000}
          onClose={handleSnackClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Batch saved!
          </Alert>
        </Snackbar>
      </Container>
    </React.Fragment>
  );
}
