import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import Stack from "@mui/material/Stack";
import SchoolIcon from "@mui/icons-material/School";

import TutorialStepper from "./TutorialStepper";

export default function TutorialButton({ sx }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const tutorialHoverShow = Boolean(anchorEl);

  const handlePopoverClose = (step) => {
    setAnchorEl(null);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const showTutorialStepper = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleStepChanged = (step) => {
    setActiveStep(step);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={tutorialHoverShow ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={showTutorialStepper}
        aria-label="guide"
        size="large"
        sx={{
          color: "#5f27cd",
          width: "100%",
          height: "60px",
          backgroundColor: tutorialHoverShow
            ? "rgba(9, 132, 227,0.15)"
            : "inherit",
        }}
      >
        <Stack
          sx={{
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <SchoolIcon />
          <Typography sx={{ fontSize: 10, color: "#222f3e" }}>Guide</Typography>
        </Stack>
      </IconButton>
      <Popover
        id="mouse-over-popover"
        open={tutorialHoverShow}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        sx={{ ml: 0.25 }}
      >
        <TutorialStepper step={activeStep} stepChanged={handleStepChanged} />
      </Popover>
    </React.Fragment>
  );
}
