import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fab from "@mui/material/Fab";
import Title from "../Title";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import {
  getFirestore,
  collection,
  getDocs,
  where,
  doc,
  setDoc,
  query,
  deleteDoc,
} from "firebase/firestore";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import QueueBatchButton from "../components/QueueBatchButton";
import CardMedia from "@mui/material/CardMedia";
import { CardActionArea } from "@mui/material";
import Box from "@mui/material/Box";

const JumpChip = styled(Chip)(({ theme }) => ({
  fontSize: 10,
  display: "flex",
  fontWeight: 500,
  height: 20,
  backgroundColor: "rgba(95, 39, 205, 1.0)",
  color: "white",
}));

export default function ShopItemMini({ item }) {
  const LINES_TO_SHOW = 2;
  // console.log("shopitem", item)

  const multiLineEllipsis = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": LINES_TO_SHOW,
    "-webkit-box-orient": "vertical",
  };

  const handleInvClick = (item) => {
    // console.log(item);
    window.open(item.main_url, "_blank", "noopener,noreferrer");
  };

  // {item.taste_notes.map((note, i) => (
  //                   <Chip key={"Chip"+i} label={note} size="small" color="primary" sx={{opacity:"0.7", backgroundColor:"#3498db"}} />
  //                 ))}

  var chiplabel = "$" + item.cost.toFixed(2);
  if (item.taste_notes && item.taste_notes[0] == "") item.taste_notes = [];

  var copyItem = item;
  var noteLength = copyItem.taste_notes.length;
  var remaining = 0;
  var cutoff = 3;
  if (noteLength > cutoff) {
    remaining = noteLength - cutoff;
    copyItem.taste_notes.length = cutoff + 1;
    copyItem.taste_notes[cutoff] = remaining + " more..";
  }

  return (
    <React.Fragment>
      <Card
        sx={{
          display: "flex",
          borderRadius: "4px !important",
          cursor: "pointer",
          border: "solid 1px rgba(108, 92, 231,0.1)",
          minHeight: "85px",
        }}
        onClick={() => handleInvClick(item)}
      >
        <CardMedia
          component="img"
          sx={{ width: 80 }}
          image={item.image_url}
          alt=""
        />
        <CardActionArea
          sx={{ display: "relative" }}
          className="showHiddenHovers"
        >
          <JumpChip
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 1000,
              cursor: "pointer",
            }}
            label={"Go to " + item.vendor}
            className="hideUntilHover"
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto", p: 1 }}>
              <Typography
                sx={{
                  minHeight: 32,
                  fontSize: 11,
                  mt: 0.2,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                }}
                color="text.primary"
              >
                {item.name}
              </Typography>
            </CardContent>
          </Box>
        </CardActionArea>
      </Card>
    </React.Fragment>
  );
}
